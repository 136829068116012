// packages
import React from 'react';
import { useLocation } from 'react-router-dom';

// style
import {
  NoMatchContainer,
  NoMatchLocation,
  GoBackHome,
  NoMatchExplanation,
} from './style';

function NoMatch() {
  const location = useLocation();

  return (
    <NoMatchContainer>
      <h1>Erreur 404</h1>
      <NoMatchExplanation>
        Oups... la page suivante n&apos;a pas été trouvé sur nos serveurs:
      </NoMatchExplanation>
      <NoMatchLocation>
        &quot;
        <code>
          {location.pathname}
        </code>
        &quot;
      </NoMatchLocation>
      <GoBackHome to="/">Retourner sur la page d&apos;accueil</GoBackHome>
    </NoMatchContainer>
  );
}

export default NoMatch;
