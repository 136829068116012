/**
 * Return all error messages from raw error object of server response:
 *  - graphQLErrors
 *  - networkError
 *
 * @param {object} raw error object
 * @return {Array.<string>} return array of errors messages
 */
const handleError = (errors) => {
  const messageErrors = [];
  if (errors.graphQLErrors && errors.graphQLErrors.length > 0) errors.graphQLErrors.map((err) => messageErrors.push(err.message || err));
  if (errors.networkError && errors.networkError.result && errors.networkError.result.errors) errors.networkError.result.errors.map((err) => messageErrors.push(err.message));
  return messageErrors;
};

export default handleError;
