export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABsAAAAVCAYAAAC33pUlAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFcSURB
VEiJvdQ/L2RhFMfxD5v419OgkZCVrYQVr2BCKESyb0Gp2EZls4lIaLwGtttttt5OLdFIaIh/kVFs
gkSIGQbFY+LOdWfcwfVLTnfO+T7nPOccktWKGfzDf5w+2gaW0Fclrm4N4wj3NewWv9D7FtA4Ll8A
Re0GP/EpLWAWv7GCYiRRCX/wDZ/RgxwWhdZGoWvoTgM7S3jxHr7WiGnFj9jjTjD4EizetkLaV2IA
B5HYC4zVCtjyvLLVlDDownYktojJas5/E2A7dcCgHbuR+GuMJjlOx0B3mKoTBv0q//8KI3GnDmFv
yk5zrwCVlROmuJwrL7S5QgvChfj+BlBZyyo7tY6md8ibqGZsxoDzWcFgSOXX3OBL6jNTp/Lo9LTk
jWjLiIUweOeeqjvMqjLCZboVJhRaMmQhTOG+x73LsjLCzhUwgXxDxjDCKuziOOvKCNWVUPoIGGHJ
7x4AHyWDkJDyCIoAAAAASUVORK5CYII=`;
