import logo from './logo.svg';
import OcareLogo from './OcareLogo.png';
import logoWhite from './logo_white.png';
import goBackArrow from './goBackArrow.svg';
import buttonLibrary from './Button_Library.svg';
import buttonCAD from './Button_CAD.svg';
import buttonLibraryColored from './Button_Library_colored.svg';
import buttonCADColored from './Button_CAD_colored.svg';
import buttonMenu from './Button_Menu.svg';
import ring from './ring.svg';
import profile from './Button_Profile.svg';
import fakePlan from './fake_plan.png';

export {
  logo,
  OcareLogo,
  logoWhite,
  goBackArrow,
  buttonLibrary,
  buttonLibraryColored,
  buttonMenu,
  buttonCAD,
  buttonCADColored,
  ring,
  profile,
  fakePlan,
};
