// packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';

// validation schema
import resetSchema from './validationSchema';

// services
import { RESET_PASSWORD } from '../../../services/userServices';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// style
import {
  ResetPasswordTitle,
  ResetPasswordTitleInfo,
  ResetPasswordContainer,
  ComeBackToLogin,
} from './style';

// shared
import { CustomInput, CustomButton } from '../../../shared/components';

function ResetPasswordPage() {
  const {
    StyledLabel,
    StyledInput,
    StyledErrorMessage,
    StyledFormControl,
  } = CustomInput;

  // navigation
  const history = useHistory();

  // hooks
  const dispatch = useDispatch();

  // useForm
  const {
    register,
    handleSubmit,
    errors,
  } = useForm({ resolver: yupResolver(resetSchema) });

  // localstate
  const [mail, setMail] = useState(null);

  const [
    resetPassword,
    { loading, error },
  ] = useMutation(
    RESET_PASSWORD, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data) {
          dispatch(requestActionCreators.success(['Mot de passe envoyé !']));
          const location = {
            pathname: '/confirmCodeValidation',
            state: { email: mail },
          };
          setTimeout(() => history.push(location), 1500);
        }
      },
    },
  );

  // onSubmit
  function onSubmit(request, evt) {
    evt.preventDefault();
    setMail(request.email);
    dispatch(requestActionCreators.clean());
    resetPassword({ variables: { ...request } });
  }

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading]);
  return (
    <ResetPasswordContainer className="auth_Child" onSubmit={handleSubmit((r, event) => onSubmit(r, event))}>
      <ResetPasswordTitle>
        Mot de passe oublié ?
      </ResetPasswordTitle>
      <ResetPasswordTitleInfo>
        Entrez votre adresse mail pour récupérer votre mot de passe
      </ResetPasswordTitleInfo>
      <StyledFormControl>
        <StyledLabel htmlFor="id">Email</StyledLabel>
        <StyledInput
          error={!!errors.email}
          inputRef={register}
          id="email"
          type="email"
          name="email"
          placeholder="Email"
          disableUnderline
          fullWidth
        />
        {errors?.email && <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>}
      </StyledFormControl>
      <ComeBackToLogin to="/login">Revenir à la connexion</ComeBackToLogin>
      <CustomButton
        type="submit"
      >
        Envoyer
      </CustomButton>
    </ResetPasswordContainer>
  );
}

export default ResetPasswordPage;
