/* eslint-disable react/no-array-index-key */
// packages
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core/styles';
// component
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';

import { colors } from '../../styles';

const StyledFormSelectControl = styled(FormControl)({
  margin: '5px 0',
  display: 'flex',
  border: 'none',
  width: '100%',
});

const CustomStyledSelect = styled(Select)({
  '&.MuiInputBase-root': {
    borderRadius: '13px',
  },
  '&.MuiOutlinedInput-root': {
    minWidth: '100%',
  },
});

const CustomMenuItem = styled(MenuItem)({
  '&.Mui-disabled': {
    backgroundColor: `${colors.LIGHTGRAY}`,
    color: `${colors.WHITE}`,
  },
});

function StyledSelect(
  {
    value = '',
    name,
    onChange,
    options = [],
    label,
    displayFrOption,
    isDisabled = false,
    availableOptions = [],
  },
) {
  const [data, setData] = useState('');
  const handleChange = (event) => {
    setData(event.target.value);
    onChange(event);
  };

  // At initialization or reset
  useEffect(() => {
    if (value === '') setData(value);
  }, [value]);

  return (
    <StyledFormSelectControl variant="outlined">
      <InputLabel id={label}>{label}</InputLabel>
      <CustomStyledSelect
        labelId={label}
        id="demo-simple-select-outlined"
        value={data}
        name={name}
        onChange={handleChange}
        label={label}
        disabled={isDisabled}
      >
        {options.map((option, index) => (
          <CustomMenuItem
            disabled={!availableOptions.includes(option)}
            key={`${option}_${index}`}
            value={option}
          >
            {displayFrOption ? displayFrOption(name, option) : option}
          </CustomMenuItem>
        ))}
      </CustomStyledSelect>
    </StyledFormSelectControl>
  );
}

StyledSelect.propTypes = {
  isDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  displayFrOption: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  availableOptions: PropTypes.array,
};

StyledSelect.defaultProps = {
  isDisabled: false,
  displayFrOption: () => null,
  label: null,
  options: [],
  availableOptions: [],
};

export default StyledSelect;
