/* eslint-disable max-len */
/* eslint-disable quotes */
// packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  AccordionActions,
} from '@material-ui/core';
import { MoreHoriz, ArrowForwardIos } from '@material-ui/icons';

// colors
import { colors } from '../../styles';

// CSS
const useStyles = makeStyles(() => ({
  accordion: {
    width: '100%',
    borderRadius: '16px',
    margin: '0',
    backgroundColor: `${colors.BUTTONBLUE}`,
    '&.MuiAccordion-rounded:first-child': {
      borderTopLeftRadius: 'inherit',
      borderTopRightRadius: 'inherit',
    },
    '&.MuiAccordion-rounded:last-child': {
      borderBottomLeftRadius: 'inherit',
      borderBottomRightRadius: 'inherit',
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  accordionsummary: {
    borderRadius: '16px',
    margin: '0',
    width: '100%',
    backgroundColor: `${colors.BUTTONBLUE}`,
    boxShadow: 'none',
    '&.MuiAccordionSummary-root': {
      flexDirection: 'row-reverse',
      paddingRight: (props) => props.isJustify && '2.5em',
      padding: (props) => props.details && '0px',
      color: `${colors.WHITE}`,
    },
    '&.MuiAccordionSummary-root .MuiAccordionSummary-content': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: (props) => props.isJustify && 'space-between',
    },
    '&.MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
  expandIcon: {
    color: `${colors.WHITE}`,
    height: '100%',
    width: '30px',
  },
  typography: {
    fontSize: '16px',
    lineHeight: (props) => (props.isHomeModule ? '20px' : '24px'),
    fontWeight: (props) => (props.isHomeModule ? '500' : '600'),
  },
  typographyTitle: {
    fontSize: '16px',
    maxWidth: '250px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    lineHeight: (props) => (props.isHomeModule ? '20px' : '24px'),
    fontWeight: (props) => (props.isHomeModule ? '500' : '600'),
  },
  accordionActions: {
    '&.MuiAccordionActions-root': {
      position: 'absolute',
      left: 'calc(100% - 55px)',
      top: '0px',
      display: 'flex',
      minHeight: '100%',
      backgroundColor: (props) => props.isActionsOpened && `${colors.WHITE}`,
      border: (props) => (props.isActionsOpened ? `.5px solid ${colors.BLACK}` : '.5px solid transparent'),
      borderRadius: (props) => props.isActionsOpened && '5px',
      padding: '0px',
      zIndex: (props) => (props.isActionsOpened ? '999999' : '99999'),
      alignItems: 'baseline',
    },
  },
  buttonAction: {
    '&.MuiButton-root': {
      padding: '0 27px 16px',
      textTransform: 'capitalize',
      fontSize: '20px',
      fontWeight: '400',
      lineHeight: '24px',
    },
  },
}));

const ItemContainer = styled.div`
  margin: 0 0 5px;
  width: 100%;
  background-color: ${colors.BUTTONBLUE};
  border-radius: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
`;
const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

function ItemBigSize({
  isHomeModule = false,
  isJustify = true,
  itemOne = null,
  itemTwo = null,
  itemThree = null,
  details = null,
  buttons = [],
}) {
  const [isActionsOpened, setActionsOpened] = useState(false);
  const classes = useStyles({
    itemOne,
    itemTwo,
    itemThree,
    details,
    buttons,
    isActionsOpened,
    isJustify,
    isHomeModule,
  });
  return (
    <ItemContainer>
      <Accordion
        className={classes.accordion}
      >
        <AccordionSummary
          className={classes.accordionsummary}
          expandIcon={details ? <ArrowForwardIos className={classes.expandIcon} /> : null}
        >
          {itemOne && <Typography className={classes.typographyTitle}>{itemOne}</Typography>}
          {itemTwo && <Typography className={classes.typography}>{itemTwo}</Typography>}
          {itemThree && <Typography className={classes.typography}>{itemThree}</Typography>}
          {buttons.length > 0 && (
          <AccordionActions disableSpacing={false} className={classes.accordionActions}>
            <Button
              className={classes.actionsTrigger}
              color="inherit"
              component="span"
              aria-label="actionsTrigger"
              onFocus={(e) => e.stopPropagation()}
              onClick={(e) => { e.stopPropagation(); setActionsOpened((prev) => !prev); }}
            >
              {isActionsOpened
                ? <MoreHoriz style={{ color: colors.BLACK, fontSize: 35 }} />
                : <MoreHoriz style={{ color: colors.WHITE, fontSize: 35 }} />}
            </Button>
            <ActionsContainer>
              {isActionsOpened && buttons.map(({ id, name, action }) => (
                <Button
                  className={classes.buttonAction}
                  size="small"
                  key={id}
                  aria-label={name}
                  onFocus={(e) => e.stopPropagation()}
                  onClick={(e) => { e.stopPropagation(); action(); }}
                >
                  {name}
                </Button>
              ))}
            </ActionsContainer>
          </AccordionActions>
          )}
        </AccordionSummary>
        {details && (
        <AccordionDetails>
          {details}
        </AccordionDetails>
        )}
      </Accordion>
    </ItemContainer>
  );
}

ItemBigSize.propTypes = {
  details: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  itemOne: PropTypes.node,
  isHomeModule: PropTypes.bool,
  isJustify: PropTypes.bool,
  itemTwo: PropTypes.node,
  itemThree: PropTypes.node,
  // eslint-disable-next-line react/forbid-prop-types
  buttons: PropTypes.PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
};

ItemBigSize.defaultProps = {
  itemOne: null,
  itemTwo: null,
  isHomeModule: false,
  isJustify: true,
  itemThree: null,
  details: null,
  buttons: [],
};

export default ItemBigSize;
