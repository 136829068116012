import background from './background.svg';
import backgroundLogin from './background_login.png';
import backgroundLoginMobile from './background_login_mobile.png';
import backgroundResetPassword from './background_resetpassword.png';
import backgroundResetPasswordMobile from './background_resetpassword_mobile.png';
import draw from './mainDraw.png';

export {
  background,
  draw,
  backgroundLogin,
  backgroundLoginMobile,
  backgroundResetPassword,
  backgroundResetPasswordMobile,
};
