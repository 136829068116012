import homeSelected from './homeSelected.svg';
import home from './home.svg';
import badgeSelected from './badgeSelected.svg';
import badge from './badge.svg';
import deviceSelected from './deviceSelected.svg';
import device from './device.svg';
import mapSelected from './mapSelected.svg';
import map from './map.svg';
import sanitizationSelected from './sanitizationSelected.svg';
import sanitization from './sanitization.svg';

export {
  homeSelected,
  home,
  badgeSelected,
  badge,
  deviceSelected,
  device,
  mapSelected,
  map,
  sanitizationSelected,
  sanitization,
};
