/* eslint-disable react/no-array-index-key */
// packages
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { styled } from '@material-ui/core/styles';

// shared
import { colors } from '../../styles';

const CustomAlert = styled(Alert)({
  '&.MuiAlert-filledError': {
    backgroundColor: `${colors.RED_TOASTER}`,
  },
});

function ErrorToaster() {
  // localstate
  const [show, setShow] = useState(false);
  // redux
  const errors = useSelector((state) => state.request.error);

  useEffect(() => {
    if (errors) {
      setShow(true);
    }
  }, [errors]);

  if (!errors) return null;
  return (
    <>
      {errors.map((error, index) => (
        <Snackbar
          open={show}
          key={`${index}_${error}`}
          autoHideDuration={10000}
          onClose={() => setShow(false)}
        >
          <CustomAlert
            elevation={6}
            variant="filled"
            onClose={() => setShow(false)}
            severity="error"
          >
            <AlertTitle>Une erreur est survenue</AlertTitle>
            {error}
          </CustomAlert>
        </Snackbar>
      ))}
    </>
  );
}

export default ErrorToaster;
