/**
 * Translate FootSide label from US to FR
 *
 * @param {string} option in English
 * @return {string}return translated footSide
 */
const displayFrFootSideOption = (opt) => ((opt === 'Left') ? 'droit' : 'gauche');

/**
 * Translate FootSize label from US to FR
 *
 * @param {number} option in English
 * @return {string}return translated footSize
 */
const displayFrFootSizeOption = (opt) => {
  const arrayConversion = {
    1: '20 - 21 - 22',
    2: '23 - 24 - 25',
    3: '26 - 27 - 28',
    4: '29 - 30 - 31',
    5: '31 - 32 - 33',
    6: '34 - 35 - 36',
    7: '37 - 38 - 39',
    8: '40 - 41 - 42',
    9: '43 - 44 - 45',
  };
  return arrayConversion[opt];
};

const displayFrTranslation = { displayFrFootSideOption, displayFrFootSizeOption };

export default displayFrTranslation;
