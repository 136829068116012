// packages
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../shared/styles';

const ConfirmResetPasswordContainer = styled.form`
  padding: 0 15px 42px;
  margin: auto 0px;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.WHITE};
  box-shadow: 2px 19px 45px #76767693;
  align-items: center;
  border-radius: 35px; 

  @media screen and (max-width: 900px) {
    width: 100vw;
    margin-bottom: 0;
    border-radius: 35px 35px 0 0;
    min-height: 605px;
  }
`;

const ConfirmResetPasswordTitle = styled.h1`
  color: ${colors.BLACKTITLE};
  font: normal normal bold 42px/55px Roboto;
  letter-spacing: 0px;
  text-align: center;
  max-width: 450px;
`;

const ConfirmResetPasswordTitleInfo = styled.span`
  text-align: center;
  max-width: 350px;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 200;
`;

const ComeBackToLogin = styled(Link)`
  margin-bottom: 60px;
  text-align: center;
  width: 100%;
  font: normal normal 300 17px/23px Roboto;
  color: ${colors.ORANGE};
  text-decoration: underline;
  align-self: flex-start;
  cursor: pointer;

  &:hover {
    color: ${colors.BLACK};
  }
`;

export {
  ConfirmResetPasswordContainer,
  ConfirmResetPasswordTitle,
  ConfirmResetPasswordTitleInfo,
  ComeBackToLogin,
};
