/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
// packages
import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// components
import { PlainInput } from '../../../../shared/components';

// style
import {
  ProfileChangePasswordWrapper,
  ProfileDetailLabel,
} from '../style';

function PasswordChangeForm({ errors, register }) {
  const {
    StyledInputPassword,
    StyledErrorMessage,
    StyledFormControl,
  } = PlainInput;

  const istabletscreen = useMediaQuery('(max-width: 1200px)');

  return (
    <>
      <ProfileChangePasswordWrapper>
        <ProfileDetailLabel>Ancien mot de passe :</ProfileDetailLabel>
        <StyledFormControl ismodal="true">
          <StyledInputPassword
            error={!!errors.currentPassword}
            istabletscreen={istabletscreen.toString()}
            inputRef={register}
            id="currentPassword"
            name="currentPassword"
            placeholder="Ancien mot de passe"
          />
          {errors?.currentPassword && <StyledErrorMessage>{errors.currentPassword.message}</StyledErrorMessage>}
        </StyledFormControl>
      </ProfileChangePasswordWrapper>
      <ProfileChangePasswordWrapper>
        <ProfileDetailLabel>Nouveau mot de passe :</ProfileDetailLabel>
        <StyledFormControl ismodal="true">
          <StyledInputPassword
            error={!!errors.passwordNew}
            istabletscreen={istabletscreen.toString()}
            inputRef={register}
            id="passwordNew"
            name="passwordNew"
            placeholder="Nouveau mot de passe"
          />
          {errors?.passwordNew && <StyledErrorMessage>{errors.passwordNew.message}</StyledErrorMessage>}
        </StyledFormControl>
      </ProfileChangePasswordWrapper>
      <ProfileChangePasswordWrapper>
        <ProfileDetailLabel>Confirmation mot de passe :</ProfileDetailLabel>
        <StyledFormControl ismodal="true">
          <StyledInputPassword
            error={!!errors.passwordConfirmation}
            istabletscreen={istabletscreen.toString()}
            inputRef={register}
            id="passwordConfirmation"
            name="passwordConfirmation"
            placeholder="Confirmation mot de passe"
          />
          {errors?.passwordConfirmation && <StyledErrorMessage>{errors.passwordConfirmation.message}</StyledErrorMessage>}
        </StyledFormControl>
      </ProfileChangePasswordWrapper>
    </>
  );
}

PasswordChangeForm.propTypes = {
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
};

PasswordChangeForm.defaultProps = {
  errors: null,
};

export default PasswordChangeForm;
