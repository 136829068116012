// packages
import React from 'react';
import PropTypes from 'prop-types';

// helpers
import displayFrTranslation from '../helpers/displayFrTranslation';

// style
import {
  PieceToPrintContainer,
  PieceDescriptionContainer,
  PieceOverview,
  PieceDescriptionTitle,
  PieceDescriptionWrapper,
  PieceDescription,
  // PieceNbCounter,
} from './style';

function PieceToPrint({ piece }) {
  const { displayFrFootSideOption, displayFrFootSizeOption } = displayFrTranslation;
  if (!piece) return null;
  return (
    <PieceToPrintContainer>
      <PieceOverview src={piece.pieceTypePreview} />
      <PieceDescriptionContainer>
        <PieceDescriptionTitle>{piece.pieceTypeName}</PieceDescriptionTitle>
        <PieceDescriptionWrapper>
          <PieceDescription>{`Pointure ${displayFrFootSizeOption(piece.footSize)},`}</PieceDescription>
          <PieceDescription>{`Sh ${piece.shore},`}</PieceDescription>
          <PieceDescription>{`Hauteur ${piece.thickness},`}</PieceDescription>
          <PieceDescription>{`Côté ${displayFrFootSideOption(piece.footSide)}`}</PieceDescription>
        </PieceDescriptionWrapper>
      </PieceDescriptionContainer>
      {/* <PieceNbCounter>1</PieceNbCounter> */}

    </PieceToPrintContainer>
  );
}

PieceToPrint.propTypes = {
  piece: PropTypes.shape({
    pieceTypeName: PropTypes.string,
    footSize: PropTypes.number,
    footSide: PropTypes.string,
    shore: PropTypes.number,
    thickness: PropTypes.number,
    pieceTypePreview: PropTypes.string,
  }),
};
PieceToPrint.defaultProps = {
  piece: {
    pieceTypeName: '',
    footSize: null,
    footSide: null,
    shore: null,
    thickness: null,
    pieceTypePreview: null,
  },
};

export default PieceToPrint;
