// packages
import React from 'react';

// assets
import { HomeAssets, sharedAssets } from '../../../assets';

// style
import {
  HomePageContainer,
  HomePageTitleBackground,
  HomePageTitleWrapper,
  MenuContainer,
  LibrarySection,
  // CadSection,
  SectionButton,
  TopicIcon,
  TopicIconWrapper,
} from './style';

function HomePage() {
  return (
    <HomePageContainer>
      <HomePageTitleBackground style={{ backgroundImage: `url(${HomeAssets.HomeBackground})` }}>
        <HomePageTitleWrapper>
          <img src={sharedAssets.OcareLogo} alt="logo ocare" style={{ height: '10rem', width: 'auto' }} />
        </HomePageTitleWrapper>
      </HomePageTitleBackground>
      <MenuContainer>
        <LibrarySection>
          <div className="section_wrapper_title">
            <h3 className="section_title">Bibliothèque</h3>
            <TopicIconWrapper><TopicIcon src={sharedAssets.buttonLibraryColored} alt="bibliothèque d'éléments" /></TopicIconWrapper>
          </div>
          <p className="section_info">
            Configurez ici tous les paramètres nécessaire à l’impression 3D des éléments stockés dans la bibliothèque
          </p>
          <SectionButton to="/bibliotheques" title="Bibliothèque d'éléments">Accéder</SectionButton>
        </LibrarySection>
        {/* <CadSection>
          <div className="section_wrapper_title">
            <h3 className="section_title">Modélisation</h3>
            <TopicIconWrapper><TopicIcon src={sharedAssets.buttonCADColored} alt="Modélisation" /></TopicIconWrapper>
          </div>
          <p className="section_info">
            Modélisez et personnalisez sur une paire de semelle vos éléments et leur emplacement
          </p>
          <SectionButton title="Modélisation" to="/modelisation">Accéder</SectionButton>
        </CadSection> */}
      </MenuContainer>
    </HomePageContainer>
  );
}

export default HomePage;
