// actionCreators
import { cadActionCreators } from '../reducers/cadReducer';

function updateComponents(components) {
  return cadActionCreators.UPDATE_COMPONENTS(components);
}

function resetCad() {
  return cadActionCreators.RESET();
}

function toggleModal(name) {
  return cadActionCreators.TOGGLE_MODAL(name);
}

function toggleModalTool(name) {
  return cadActionCreators.TOGGLE_MODAL_TOOL(name);
}

function updateSelectedComponent(selected) {
  return cadActionCreators.UPDATE_SELECTED_COMPONENT(selected);
}

function saveTemporarySoles(components) {
  return cadActionCreators.SAVE_TEMPORARY_SOLES(components);
}

export {
  updateComponents, toggleModal, toggleModalTool, updateSelectedComponent, saveTemporarySoles, resetCad,
};
