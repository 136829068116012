/* eslint-disable react/jsx-props-no-spreading */
// packages
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

// component
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

// styles
import { colors } from '../../styles';

const StyledFormCheckboxControl = styled(FormControl)({
  marginBottom: '10px',
  display: 'flex',
  border: 'none',
});

const StyledFormControlLabel = styled(
  ({ ischecked, ...other }) => <FormControlLabel {...other} />,
)({
  '&.MuiFormControlLabel-root .MuiTypography-body1': {
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '24px',
    opacity: (props) => (props.isdisabled === 'true' && '0.5'),
    color: (props) => (props.ischecked ? `${colors.BUTTONBLUE}` : `${colors.BLACK}`),
  },
  '&.MuiFormControlLabel-root': {
    marginLeft: '0px',
  },
});

const StyledFormGroup = styled(FormGroup)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
});

const CustomStyledCheckbox = styled(Checkbox)({
  '&.Mui-checked .MuiCheckbox-colorPrimary': {
    color: `${colors.BUTTONBLUE}`,
    opacity: (props) => (props.disabled && '0.5'),
  },
  '&.MuiCheckbox-colorPrimary': {
    color: `${colors.WHITE}`,
    opacity: (props) => (props.disabled && '0.5'),
  },
  '&.MuiCheckbox-colorSecondary': {
    color: `${colors.BUTTONBLUE}`,
    opacity: (props) => (props.disabled && '0.5'),
  },
});

function StyledCheckbox(
  {
    checked = false,
    onChange,
    label,
    name,
    isDisabled = false,
  },
) {
  return (
    <StyledFormControlLabel
      ischecked={checked}
      label={label}
      isdisabled={isDisabled.toString()}
      control={(
        <CustomStyledCheckbox
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={isDisabled}
        />
      )}
    />
  );
}

StyledCheckbox.propTypes = {
  isDisabled: PropTypes.bool,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  name: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};

StyledCheckbox.defaultProps = {
  isDisabled: false,
  name: null,
  label: null,
  checked: false,
  onChange: () => {},
};

export default {
  StyledFormCheckboxControl,
  StyledCheckbox,
  StyledFormGroup,
  StyledFormControlLabel,
};
