import edit from './edit.svg';
import profileCosmetic from './profile_cosmetic.svg';
import ProfileBackground from './Profile_background.png';
import avatarProfileDefault from './avatar_profile_default.png';

export {
  avatarProfileDefault,
  edit,
  profileCosmetic,
  ProfileBackground,
};
