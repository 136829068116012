// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';

const CaptchaContainer = styled.div`
display: flex;
justify-content: center;
width: 100%;
margin: 2% 0;
margin-botton: 5%
`;

const NoError = styled.div`
height: 23px;
width: 10px;
margin: 8px 0;
`;

export { CaptchaContainer, NoError };
