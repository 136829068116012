export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAACsAAAAlCAYAAADbVxCwAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAARYSURB
VFiF7ZZPbBRVHMc/bzvbstm2dBPaxK0irOku0ETTNE0IMXhQIyIkRmPkQMZgtMEDBxsRT8booZV4
MyRIDEI84bHioaaJB4hCxMuyXVi2O0XojjYLnZ21uzPpn/15kNYa291puwVj+CTvMjP7e5/9znu/
efCQJXnxQQt45TOgDLz+oEWq8RFwDZgEssCB1Rby1UhoOfxADHgVmAGeB55Z5znXTBswsdYi651s
TXkou17cF9mBgYHWnTt3aiKi1lJnTT9ejIi0AB1Ax+zs7DbbtruUUrHGxsbHXNcll8s1tLa2Xmtu
bn5bKfXjusuKSD3wKBABOovFYpfjOJ1+v39LMBhsMU2zNDIyMpdIJJpGR0e1ZDLJ1atXsW0bgP37
93Pq1KlSMBi81NTU9JZSaqxmsiLiAz4ulUrvlMtlX0NDQ2M2my2lUimJx+PBVCqlpdNpUqkUExPe
OlMgEODYsWMzR48enVFKfR4IBD5RShVXIl1JeLvrurOO48jJkyfnQqGQAGse7e3tcu7cOXdqaqro
OM4Pd+/evVgsFk+vVTZqmmYhHA7L8PCwFAoFOXLkSFnTtJpI9/T0SDweF8MwxLbtRCWXqmv2nuyV
9vb2JoCuri7OnDkjzc3N6vDhwwwNDXn604FAgGg0SkdHx8KIxWJEo1E0TcM0TSKRyJ0NGzY8q5SK
r1Y2Zprmz/Oy8+zbt48TJ06QzWbp7e0lkUigaRqbN28mEoksjM7OTnbs2EE4HMY0TQzDIJlMMjIy
gmEYGIbB2NgYe/bs4ezZsz+1tbXtWs5F8xTLX6/sH5w/f56hoSH6+vq4cOECruuyceNGxsfHmd90
6XSa4eFh0uk0t27dQuRfZRZQSi05z4oQkW3j4+MFqmyWUqkkfr9/1Wt37969ksvlLlZy8fQFk0qR
AJZlISLMzMx4KbckSqmKyYM32arr2stEtcBrsuvtAYBSqlzpvqdkq8nWItl7G6wi/7sjYtVka4GX
1lWTZP9LG+xhsqvBa5+9P72rCl77bMW+4qXtVKNmy6C+vt6naV7PPOuHD3gNeHeJexFA37p163uZ
TMafTCbl4MGD1NXVLVnofnwUAEygZ14O+AL49d71b4BeoFNEduXz+Uu3b98u6LperqurWzgxtbS0
iGVZqz5xbdq0Sfr6+uTy5ctjVHnbY8BvgAF8BbwBbFnuYRF5enJy8srNmzcLuq6Lz+fzJBsOh2X3
7t1y6NAh6e/vnx0cHLRTqVS+VCq5juNMTUxMjPb392eAb4HQcvP/AXyI94P4vPQLtm0nMpmMo+u6
WJYloVBIuru7Rdd1OX78+Ozg4ODU9evXbcdxpl3XLVqWdSOfz383Nzf3qYj0ishzIvLIorIaMADc
AJ5cdK17/oFtwPfAByuRXSR92rbt8vT0tBQKhTu5XO4Xy7K+FpH3ReQVEXlKRIIrLPsy8DvwJvAE
MLoat6VkHxeR7SLyUk0K/k0MSABfAg7g+xNJ72kOe7M5FgAAAABJRU5ErkJggg==`;
