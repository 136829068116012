/* eslint-disable react/jsx-boolean-value */
/* eslint-disable no-underscore-dangle */
// packages
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import fileDownload from 'js-file-download';
import { useSelector, useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { IconButton } from '@material-ui/core';
import { SettingsBackupRestoreSharp } from '@material-ui/icons';

// services
import { READ_PIECES_FROM_PIECETYPE_ID, EXPORT_FROM_LIBRARY } from '../../../services/libraryServices';
// import { PRINT_FROM_LIBRARY } from '../../../services/printerServices';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// components
import PrintList from './PrintList';
import ConfigurationSelect from './ConfigurationSelect';
import { CustomButton, ModalConfirmation } from '../../../shared/components';

// description shore
import shoreDescription from './shoreDescription';

// helpers
import { cleanFilter, cleanAvailablePieces, getFrenchNameforFile } from './helpers';

// styles
import { colors } from '../../../shared/styles';

// style
import {
  ConfigurationContainer,
  TitleForm,
  TitleFormCat,
  TitleFormName,
  ButtonWrapper,
  Overview,
  OverviewTitle,
  ImagePiece,
  ConfigurationForm,
  // PiecesNumber,
} from './style';

function Configuration() {
  // hooks
  const params = useParams();

  // redux
  const { selectedPieceTypeToConfig } = useSelector((state) => state.library);

  // navigation
  const history = useHistory();

  const {
    name,
    thumbnailPreview,
  } = selectedPieceTypeToConfig;

  // local state
  const [shore, setShore] = useState('');
  const [options, setOptions] = useState(null);
  const [filter, setFilter] = useState({
    footSide: '',
    footSize: '',
    thickness: '',
  });
  const [showPrintModal, setShowPrintModal] = useState(false);
  const [availablePieces, setAvailablePieces] = useState([]);

  function triggerPrintModal() {
    setShowPrintModal(true);
  }

  const dispatch = useDispatch();

  // Query graphql
  const [filterPieces, { data, error: readPiecesError }] = useLazyQuery(
    READ_PIECES_FROM_PIECETYPE_ID,
    {
      variables: { pieceTypeId: selectedPieceTypeToConfig.id },
      fetchPolicy: 'no-cache',
      onCompleted: (d) => {
        if (!options) setOptions(d.pieces);
        setAvailablePieces(d.pieces);
      },
    },
  );

  // export (download) piece after configuration
  const [
    exportGcode,
    { error: exportError },
  ] = useLazyQuery(
    EXPORT_FROM_LIBRARY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (d) => {
        if (d && d.exportGcode) {
          const { footSide, footSize, thickness } = data?.pieces[0];

          fileDownload(d.exportGcode, `${getFrenchNameforFile({
            name, footSide, footSize, shore, thickness,
          })}.gcode`);
          setShowPrintModal(false);
        }
      },
    },
  );

  // handle reset all filter
  const resetFilter = () => {
    setShore('');
    setFilter({
      footSize: '',
      footSide: '',
      thickness: '',
    });
  };

  // handle filter changes
  const handleChangeFilter = (event) => {
    event.preventDefault();
    if (event.target.name === 'shore') {
      setShore(event.target.value);
    } else {
      setFilter((prevFilter) => ({ ...prevFilter, [event.target.name]: event.target.value }));
    }
  };

  // At initialization
  useEffect(() => {
    if (!params.libraryId) {
      history.push('/bibliotheques');
    }

    if (!selectedPieceTypeToConfig || !selectedPieceTypeToConfig.id) {
      history.push(`/bibliotheques/${params.libraryId}`);
    }
    filterPieces({ variables: { pieceTypeId: selectedPieceTypeToConfig.id } });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (readPiecesError) {
      dispatch(requestActionCreators.failure(readPiecesError));
    }
    if (exportError) {
      dispatch(requestActionCreators.failure(exportError));
    }
  }, [
    dispatch,
    // loading,
    exportError,
    readPiecesError,
  ]);

  // request newlist of pieces
  useEffect(() => {
    const copyFilter = { ...filter };
    filterPieces({
      variables: { pieceTypeId: selectedPieceTypeToConfig.id, filter: cleanFilter(copyFilter) },
      fetchPolicy: 'no-cache',
    });
  }, [filter, filterPieces, selectedPieceTypeToConfig.id]);

  if (!selectedPieceTypeToConfig) return null;
  return (
    <>
      {(data && data.pieces && shore !== '') && (
        <ModalConfirmation
          actionClose={() => setShowPrintModal(false)}
          show={showPrintModal}
          titleAction="Exporter"
          twoButtons={false}
          title="CONFIRMATION"
          sumup={true}
          onSubmit={() => exportGcode({ variables: { pieceIds: { id: data?.pieces[0].id, shore } } })}
        >
          <PrintList piecesList={data.pieces || []} pieceTypeName={name} pieceTypePreview={thumbnailPreview} shore={shore} />
        </ModalConfirmation>
      )}
      <ConfigurationContainer>
        <Overview>
          <OverviewTitle>Aperçu</OverviewTitle>
          <ImagePiece src={thumbnailPreview} />
        </Overview>
        <ConfigurationForm>
          <TitleForm>
            <TitleFormCat>ELEMENT:</TitleFormCat>
            <TitleFormName>{name}</TitleFormName>
          </TitleForm>
          <IconButton style={{ marginLeft: 'auto' }} onClick={() => resetFilter()}>
            <SettingsBackupRestoreSharp />
          </IconButton>
          <ConfigurationSelect
            value={filter.footSize}
            name="footSize"
            id="footSize"
            options={options?.reduce((acc, val) => {
              if (acc.indexOf(val.footSize) === -1) {
                acc.push(val.footSize);
              }
              return acc;
            }, []).sort((a, b) => a - b)}
            onChange={handleChangeFilter}
            label="Taille"
            availableOptions={cleanAvailablePieces(availablePieces, 'footSize').sort()}
          />
          <ConfigurationSelect
            value={shore}
            name="shore"
            id="shore"
            options={shoreDescription}
            availableOptions={shoreDescription}
            onChange={handleChangeFilter}
            label="Shore"
          />
          <ConfigurationSelect
            value={filter.thickness}
            name="thickness"
            id="thickness"
            options={options?.reduce((acc, val) => {
              if (acc.indexOf(val.thickness) === -1) {
                acc.push(val.thickness);
              }
              return acc;
            }, []).sort((a, b) => a - b)}
            onChange={handleChangeFilter}
            label="Hauteur (mm)"
            availableOptions={cleanAvailablePieces(availablePieces, 'thickness').sort()}
          />
          <ConfigurationSelect
            value={filter.footSide}
            name="footSide"
            id="footSide"
            options={options?.reduce((acc, val) => {
              if (acc.indexOf(val.footSide) === -1) {
                acc.push(val.footSide);
              }
              return acc;
            }, [])}
            onChange={handleChangeFilter}
            label="Côté"
            availableOptions={cleanAvailablePieces(availablePieces, 'footSide')}
          />
        </ConfigurationForm>
        {/* <PiecesNumber>
          {`${data?.pieces ? data.pieces.length : 0} configuration${data?.pieces?.length > 1 ? 's' : ''} trouvée${data?.pieces?.length > 1 ? 's' : ''}`}
        </PiecesNumber> */}
        <ButtonWrapper>
          <CustomButton
            primarycolor={colors.BUTTONBLUE}
            secondarycolor={colors.WHITE}
            type="button"
            onClick={() => triggerPrintModal()}
            isDisabled={(data?.pieces?.length !== 1 || shore === '')}
          >
            Suivant
          </CustomButton>
        </ButtonWrapper>
      </ConfigurationContainer>
    </>
  );
}

export default Configuration;
