export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAACAAAAAnCAYAAABuf0pMAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAF6SURB
VFiF7dg/SxxBHMbxz8kFPRMiItgFrJSACCEIoqSTgNgIvgBJYZPC1hcQSB9CCGdtkd5KFAVfgV0K
sQiXpIiFEPwT1HgWM0vukiWHx41nsQ8Mu7+dZZ8vM8vsswN7uEY9cXstR2W8yOu4K5UbzgfwK6HX
VSuAi9gyPcM0HiWA+YJNHPNnjvoabngj/XvxHeOlWEBFmIIJ7OMSn6SZlglMYYd/R2A51usJjDMN
RY+znpzOSjyeJgTInl3JA7hTFQAFQAFQABQArQBm8LCbAAfYxTuMdAPgBxaxgENsYBalTgE0BpId
IQU9wRi+4XPs6xciWvap3sdHITOcteHbh/Os+O32ceoIq+htwzwDqKNexkuMCxkN5vAK21iL15Yw
jxN8wFv8bNO8pVYiXTXWk8IwVzHcIY+mEfifeoTwOIqvHTJvUiuAa7xPYZzp3q+EBUABUAB0BSBb
8Z5jMJHv7F9eTXqMmvSbVnVhIyRXT7El/EanMK5F8wc3HM6vzPwLE0gAAAAASUVORK5CYII=`;
