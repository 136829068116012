// packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import customised from 'styled-components';

// styles
import {
  Input,
  FormControl,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { colors } from '../../styles';

const StyledFormControl = styled(FormControl)({
  margin: '10px 0',
  minWidth: '50%',
  display: 'flex',
  width: 'auto',
});

const StyledDatePicker = styled(Input)({
  backgroundColor: '#E4E4E4',
  borderRadius: '7px',
  paddingLeft: '20px',
  fontWeight: 'normal',
  fontSize: (props) => (props.istabletscreen === 'true' ? '15px' : '27px'),
  lineHeight: (props) => (props.istabletscreen === 'true' ? '23px' : '35px'),
  height: (props) => (props.istabletscreen === 'true' ? '30px' : '50px'),
  color: `${colors.SECTION_VALUE}`,
  underLine: 'none',

  '&.Mui-error': {
    border: `1px solid ${colors.RED}`,
  },

  '&label + .MuiInput-formControl': {
    marginTop: 12,

  },
});

const StyledInput = styled(Input)({
  backgroundColor: '#E4E4E4',
  borderRadius: '7px',
  paddingLeft: '20px',
  fontWeight: 'normal',
  fontSize: (props) => (props.istabletscreen === 'true' ? '15px' : '27px'),
  lineHeight: (props) => (props.istabletscreen === 'true' ? '23px' : '35px'),
  height: (props) => (props.istabletscreen === 'true' ? '30px' : '50px'),
  color: `${colors.SECTION_VALUE}`,
  underLine: 'none',

  '&.Mui-error': {
    border: `1px solid ${colors.RED}`,
  },

  '&label + .MuiInput-formControl': {
    marginTop: 12,

  },
});

const StyledErrorMessage = customised.p`
  min-width: 50%;
  margin: 8px 0;
  font-size: 17px;
  text-align: center;
  font-weight: 300;
  line-height: 23px;
  color: ${colors.RED};
`;

const StyledInputPassword = (
  {
    id,
    inputRef,
    placeholder,
    defaultValue,
    name,
    istabletscreen = 'false',
  },
) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledInput
      istabletscreen={istabletscreen}
      inputRef={inputRef}
      id={id}
      defaultValue={defaultValue}
      type={showPassword ? 'text' : 'password'}
      name={name}
      placeholder={placeholder}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )}
      disableUnderline
      fullWidth
    />
  );
};

StyledInputPassword.propTypes = {
  istabletscreen: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

StyledInputPassword.defaultProps = {
  istabletscreen: 'false',
  defaultValue: '',
};

export default {
  StyledFormControl,
  StyledInput,
  StyledErrorMessage,
  StyledDatePicker,
  StyledInputPassword,
};
