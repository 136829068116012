import * as yup from 'yup';

// TODO: make a password validator in schemma-validator package
const PASSWORD_MIN = 8;
const PASSWORD_MAX = 40;
const PASSWORD_PATTERN = /^([A-Z]+[a-z]+[0-9]+[<>?!@()#%$]+).*$/;

export default yup.object().shape({
  currentPassword: yup.string().required('Le mot de passe actuel est requis'),
  passwordNew: yup.string().test(
    'password',
    'Le format du mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial',
    (value) => value === ''
      || (value.length > PASSWORD_MIN
        && value.length < PASSWORD_MAX
        && PASSWORD_PATTERN.test(value)
      ),
  ).required('Le mot de passe est requis'),
  passwordConfirmation: yup.mixed()
    .oneOf([yup.ref('passwordNew')], 'Les mots de passe sont différents'),
});
