/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
// packages
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// services
import { CHANGE_USER_PASSWORD } from '../../../../services/userServices';

// components
import { CustomButton, ModalConfirmation } from '../../../../shared/components';
import PasswordChangeForm from './PasswordChangeForm';

// action creators
import { requestActionCreators } from '../../../../reducers/requestReducer';

// style
import {
  AsideSectionWrapper,
  SectionTitle,
} from '../style';

// validationSchema
import { changePasswordValidationSchema } from '../validationSchemas';

// colors
import { colors } from '../../../../shared/styles';

function Password() {
  // hooks
  const dispatch = useDispatch();

  // local state
  const [showModal, setShowModal] = useState(false);

  // useForm
  const {
    handleSubmit, register, errors,
  } = useForm({ resolver: yupResolver(changePasswordValidationSchema) });

  const [
    changeUserPassword,
    { loading, error },
  ] = useMutation(
    CHANGE_USER_PASSWORD, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data) {
          setShowModal(false);
          dispatch(requestActionCreators.success(['Votre mot de passe a bien été modifié !']));
        }
      },
    },
  );

  // onSubmit
  const onSubmit = (request, evt) => {
    evt.preventDefault();
    dispatch(requestActionCreators.clean());
    const password = {
      new: request.passwordNew,
      confirmation: request.passwordConfirmation,
    };
    changeUserPassword({ variables: { password, currentPassword: request.currentPassword } });
  };

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);

  return (
    <>
      <ModalConfirmation
        actionClose={() => setShowModal(false)}
        show={showModal}
        onSubmit={handleSubmit((r, event) => onSubmit(r, event))}
        titleAction="Modifier"
        title="Mot de passe"
      >
        <PasswordChangeForm errors={errors} register={register} />
      </ModalConfirmation>
      <AsideSectionWrapper>
        <SectionTitle>Mot de passe</SectionTitle>
        <CustomButton
          primarycolor={colors.BUTTONBLUE}
          secondarycolor={colors.WHITE}
          isprofile={true}
          type="button"
          onClick={() => setShowModal(true)}
        >
          Modifier
        </CustomButton>
      </AsideSectionWrapper>
    </>
  );
}

export default Password;
