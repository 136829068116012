export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAObQAADm0B1P1JnQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAOpSURB
VEiJlZVNTCN1GMZ/QoUhQt2AqMvQPbAFbSJdspQgkJLANAIBPEAGJHTjgb0qyUAhdjAZD36c2AMe
1ESNEhN31SxtbdYoREDXbJaDiVC/VoJf0aWxi2CXNS3d8TClKQ3Q+lxm5v8+z/PO//3P+w7kgPHx
8afq6+tvFxcX6w6HI6ooymAuuqzQNO1UZWVlIhAI6PF4XPf7/brFYkmoqmrJps1LuxcOI2xubo40
NTXl9fT0YDKZ6O3tpbGxMW9ra2skV/MC4EfgiYz446FQyBWJRA4sRiIRVldXXUDjceb5yWsC+BR4
H/gNiACzwPlwOPyWIAjt6+vrpsLCQmZmZgiFQv+ura1d2NvbexloAz4HdrPt5BHgdyAMTAAmAFVV
RVmWr7W2tt6SZfmaqqpikm8CngN+AmzZzMuBn4E/gGeykdPQD9wAHjiOdAnwAg8D3wBj/yPB8xhl
PRQOjEO9N/n8IPA18EqO5gUYb392fyEfYGpq6rQgCO/W1NRsuN3uy4uLi3HgNnARo6aPAvPHOWua
ViQIQltVVdXQ0NDQwvLy8hZjY2PnRFFMuN1uvbOzU7fZbLsZDXIf8BnwGgf7It34lM1m2+3q6tKH
h4d1URQTiqK4sdvtO1euXNH34fF49P7+/qsZ+kLgMvAeyS8oHX19fV9NTk6mPILBoF5XV7eN2Wy+
G4/HU4H5+Xm9paXl5hE1vQT4kslSaG5uvrmwsJDyiMViutlsvptntVp3AoFAiujz+aioqPj+EPMY
MAT8ldxF0X5AFMUf/H5/ihgIBLBardsmSZJGRkdHL87OzuaHw2Gi0eg/3d3dR029BHAeuAB8AvQC
O9XV1QPBYPCG0+ksKS8vZ2VlZW9wcNCYPV6v92R7e/uXkiR9oGlawRHGmXgBWAHKADRNK5Ak6UOX
y/WF1+t9KJN8Blgno55ZMAmsAScxpuoGULsfzE8jbmI00hmMQZQLrgL3AK8CVuBP4I2jyCeA74Bz
6YuqqlpkWb7udDpvDQwMXNc0rTJD9ybGgZ8lC04nE7wICNPT00W1tbV3JiYm9KWlJd3j8eh2u/2O
pmkCRileAr4FngV+BR7LluAE8A6w0dHR4ZMkSU9HW1ub7nK55jBq/DZwf1LXA/wC1MEh3ZbE38DT
QG0ikXi9tLT0QLCsrIzt7e1y4ElgNS30MRAHPsI4g+OhqqrFYrEkfD6fHovF9Lm5uf0ftHiMLNfP
GRRFkR0OR7SkpERvaGiIKooi56L7DxgeZ72QOC5IAAAAAElFTkSuQmCC`;
