const ENV = process.env.NODE_ENV || 'development';

const development = {
  name: 'web-afo-ocare',
  debug: true,
  assets: 'https://api.ocare.next.afotech.fr/assets',
  api: {
    url: 'https://api.ocare.next.afotech.fr/graphql',
    wss: 'ws://api.ocare.next.afotech.fr/graphql',
    defaultToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3IiOnsiaWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ0eXBlIjoiYW5vbiIsImxvYyI6ImZyLUZSIn0sInBvbCI6W3siYWN0IjoibG9naW4iLCJyZXMiOiJudWxsIn1dLCJpYXQiOjE2MzIzMTM3NjcsImV4cCI6MTAyNzIyMjczNjd9.gYHc4vJFAn0kM5sJYsSGArvy8jKBZPmh0IqoaTfv9i0',
  },
};

const testing = {
  name: 'web-afo-ocare',
  debug: true,
  assets: 'https://api.ocare.testing.afotech.fr/assets',
  api: {
    url: 'https://api.ocare.testing.afotech.fr/graphql',
    wss: 'ws://api.ocare.testing.afotech.fr/graphql',
    defaultToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3IiOnsiaWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ0eXBlIjoiYW5vbiIsImxvYyI6ImZyLUZSIn0sInBvbCI6W3siYWN0IjoibG9naW4iLCJyZXMiOiJudWxsIn1dLCJpYXQiOjE2NDU2MTU2MDksImV4cCI6MTAyODU1MjkyMDl9.Ihj8fSCbkvgYHR2DqqILPQjD6-q39LLVsQGEnzPsdaQ',
  },
};

const local = {
  ...development,
  assets: 'http://localhost:8000/assets',
  api: {
    ...development.api,
    url: 'http://localhost:8000/graphql',
    wss: 'ws://localhost:8000/graphql',
    defaultToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3IiOnsiaWQiOiIwMDAwMDAwMC0wMDAwLTAwMDAtMDAwMC0wMDAwMDAwMDAwMDAiLCJ0eXBlIjoiYW5vbiIsImxvYyI6ImZyLUZSIn0sInBvbCI6W3siYWN0IjoibG9naW4iLCJyZXMiOiJudWxsIn1dLCJpYXQiOjE2Mzk0NzQ0MDEsImV4cCI6MTAyNzkzODgwMDF9.81H2ExpAzvpmj9_TUOtmDX6Qi_TRlGC4iQyES94aBAA',
  },
};

const config = {
  development,
  testing,
  local,
};

export {
  development,
  local,
  testing,
};
export default config[ENV];
