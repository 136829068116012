/* eslint-disable import/prefer-default-export */
// packages
import { styled } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

// styles
// import { colors } from '../../../../../shared/styles';

const ListPieceTypesContainer = styled(List)({
  padding: '15px',
  gap: '10px',
  gridGap: '10px',
  margin: '0px',
  height: 'auto',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexWrap: 'wrap',
  borderRadius: '8px',
});

export { ListPieceTypesContainer };
