import * as sharedAssets from './shared';
import * as sideNavBarAssets from './sideNavBar';
import * as authAssets from './Login';
import * as CadAssets from './App/CAD';
import * as HomeAssets from './App/Home';
import * as MaterialLibraryAssets from './MaterialLibrary';
import * as ProfileAssets from './Profile';

export {
  sharedAssets,
  authAssets,
  HomeAssets,
  sideNavBarAssets,
  CadAssets,
  MaterialLibraryAssets,
  ProfileAssets,
};
