// packages
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';
import { colors } from '../../../shared/styles';

const LibraryCarrouselWrapper = styled.div`
  margin: 0;
  padding: 33px 23px;
  height: auto;
  width: 100%;
  background-color: ${colors.WHITE};
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 24px;
  display: flex;
  flex-direction: column;

  div.slick-slider {
    div.slick-arrow::before {
      content: none;
    }
    div.slick-list {
      div.slick-slide {
        min-height: 200px;
      }
      div.slick-track {
        padding-top: 5px;
      }
    }
  }
`;

const LibraryCarrouselHeader = styled.div`
  margin-bottom: 37px;
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const GoToLibraryLink = styled(Link)`
  text-decoration: none;
  margin-top: 50px
  cursor: pointer;
  color: ${colors.SECTION_CONTENT};
  text-align: left;
  font-weight: normal;
  font-size: 25px;
  line-height: 48px;
  letter-spacing: 0px;
  display: block;
  align-self: end;

  &:hover {
    color: ${colors.BLACK};
  }
`;

const LibraryTitle = styled.h2`
  text-align: left;
  width: auto;
  display: block;
  font-weight: medium;
  font-size: 36px;
  line-height: 48px;
  letter-spacing: 0px;
  color: ${colors.SECTION_TITLE_H3};
`;

export {
  LibraryCarrouselWrapper,
  LibraryCarrouselHeader,
  GoToLibraryLink,
  LibraryTitle,
};
