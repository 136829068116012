import {
  ApolloLink,
  // Operation,
  // FetchResult,
  Observable,
} from '@apollo/client/core';
import {
  print,
  // GraphQLError,
} from 'graphql';
import {
  createClient,
  // ClientOptions,
  // Client,
} from 'graphql-ws';

export default class WebSocketLink extends ApolloLink {
  constructor(options) {
    super();
    this.client = createClient(options);
    this.options = options;
  }

  request(operation) {
    operation.setContext(this.options.connectionParams());
    return new Observable((sink) => this.client.subscribe(
      { ...operation, query: print(operation.query) },
      {
        next: sink.next.bind(sink),
        complete: sink.complete.bind(sink),
        error: (err) => {
          if (err instanceof Error) {
            return sink.error(err);
          }

          if (err instanceof 'CloseEvent') {
            return sink.error(
              // reason will be available on clean closes
              new Error(
                `Socket closed with event ${err.code} ${err.reason || ''}`,
              ),
            );
          }

          return sink.error(
            new Error(
              err
                .map(({ message }) => message)
                .join(', '),
            ),
          );
        },
      },
    ));
  }
}
