// packages
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// shared
import { colors } from '../../shared/styles';

const AuthentificationContainer = styled.div`
  padding: 0px;
  margin: 0px;
  height: 100vh;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  background-color: ${colors.APPBACKGROUNDCOLOR};
  `;

const AuthentificationWrapper = styled.div`
  z-index: 10;
  position: absolute;
  height: 100%;
  max-height: 888px;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${({ path }) => {
    switch (path) {
      case '/login':
        return 'right: 50%';
      case '/register':
        return 'right: 40%';
      default:
        return 'left: 50%';
    }
  }};

  @media screen and (max-width: 1200px) {
    ${({ path }) => {
    switch (path) {
      case '/login':
        return 'right: 50%';
      case '/register':
        return 'right: 40%';
      default:
        return 'left: 40%';
    }
  }};
  }

  @media screen and (max-width: 1400px) {
    ${({ path }) => (path === '/register'
    ? `
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      `
    : null)
};
  }

  form.auth_Child {
    height: 80%;
  }

  @media screen and (max-width: 900px) {
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
  }
`;

const AuthentificationBackground = styled.img`
  z-index: 5;
  position: absolute;
  max-width: 702px;
  max-height: 888px;
  height: 90%;
  border-radius: 10px;
  ${({ path }) => {
    switch (path) {
      case '/login':
        return 'left: 40%';
      case '/register':
        return 'right: 10%';
      default:
        return 'right: 40%';
    }
  }};

  @media screen and (max-width: 1200px) {
    ${({ path }) => {
    switch (path) {
      case '/login':
        return 'left: 40%';
      case '/register':
        return 'right: 50%';
      default:
        return 'right: 42%';
    }
  }};
  }
  @media screen and (max-width: 1400px) {
    ${({ path }) => (path === '/register'
    ? `
      opacity: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100vw;
      `
    : null)
};
  }

  @media screen and (max-width: 900px) {
    top: -240px;
    left: 0;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    transform: scale(1.11);
  }

  @media screen and (max-width: 680px) { 
    top: -220px;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    transform: scale(1.11);
  }
  @media screen and (max-width: 600px) {
    top: -115px;
    left: 0;
    right: 0;
    bottom: 0;
    max-width: 100vw;
    transform: scale(1.11);
  }

`;

const AuthentificationLogo = styled.img`
  width: 200px;
  height: 200px;
  flex: 0 1;
  display: none;

  @media screen and (max-width: 900px) {
    display: block;
    margin-top: 70px;
  }
`;
const AuthentificationMotto = styled.p`
  width: 100%;
  font-size: 30px;
  line-height: 36px;
  text-align: center;
`;
const AuthentificationChangeTypeAuthWrapper = styled.div`
  font-size: 15px;
  width: 100%;
  text-align: center;
`;
const AuthentificationChangeTypeAuthLink = styled(Link)`
  color: ${colors.MAINBLUE};
  text-decoration: none;
  margin-left: 5px;
`;

export {
  AuthentificationContainer,
  AuthentificationBackground,
  AuthentificationWrapper,
  AuthentificationLogo,
  AuthentificationMotto,
  AuthentificationChangeTypeAuthWrapper,
  AuthentificationChangeTypeAuthLink,
};
