// packages
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
// import { BrowserRouter as Router } from 'react-router-dom';

// colors
import { colors } from '../../styles';

const ItemContainer = styled.div`
  margin: 0 0 5px;
  padding: 5px 10px;
  min-width: calc(100% - 20px);
  min-height: 34px;
  background-color: ${colors.BUTTONBLUE};
  color: ${colors.WHITE};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;  
`;

const Item = styled.div`
  margin: 0;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: ${colors.WHITE};
  display: flex;
  align-items: center;
`;

function ItemSmallSize({
  children,
}) {
  return (
    <ItemContainer>
      <Item>
        {children}
      </Item>
    </ItemContainer>
  );
}

ItemSmallSize.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
};

export default ItemSmallSize;
