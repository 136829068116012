/* eslint-disable no-undef */
/* eslint-disable no-underscore-dangle */
// packages
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';

// action creators
import { libraryActionCreators } from '../../../../reducers/libraryReducer';

// style
import { PieceTypeContainer, PieceTypeName, PieceTypeImg } from './style';

function PieceType({ pieceType }) {
  // hooks
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobileScreen = useMediaQuery('(max-width: 600px)');

  const { id, name, thumbnailUnSelected } = pieceType;

  function goConfig() {
    dispatch(libraryActionCreators.selectPieceTypeToConfig({
      ...pieceType,
      thumbnailPreview: pieceType.thumbnailPreview._getFile(),
    }));
    history.push(`/bibliotheques/${pieceType.id}libraryId/configuration/${pieceType.id}`);
  }
  return (
    <>
      <PieceTypeContainer key={id} onClick={() => goConfig()} ismobilescreen={isMobileScreen.toString()}>
        {/* <FavoriteStatus isFavoris={isFavoris} /> */}
        <PieceTypeImg src={thumbnailUnSelected._getFile()} isMobileScreen={isMobileScreen} />
        <PieceTypeName>{name}</PieceTypeName>
      </PieceTypeContainer>
    </>
  );
}

PieceType.propTypes = {
  pieceType: PropTypes.shape({
    _typename: PropTypes.string,
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    isFavoris: PropTypes.bool,
    thumbnailUnSelected: PropTypes.shape({
      _getFile: PropTypes.func.isRequired,
      typename: PropTypes.string,
      mimeType: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    thumbnailSelected: PropTypes.shape({
      _getFile: PropTypes.func.isRequired,
      typename: PropTypes.string,
      mimeType: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
    thumbnailPreview: PropTypes.shape({
      _getFile: PropTypes.func.isRequired,
      typename: PropTypes.string,
      mimeType: PropTypes.string,
      id: PropTypes.string.isRequired,
      name: PropTypes.string,
    }).isRequired,
  }),
};

PieceType.defaultProps = {
  pieceType: {
    _typename: null,
    isFavoris: false,
  },
};
export default PieceType;
