/* eslint-disable no-console */
// packages
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// services
import { CONFIRM_RESET_PASSWORD } from '../../../services/userServices';

// shared components
import { CustomInput, CustomButton } from '../../../shared/components';

// validation schema
import { confirmResetPasswordSchema } from './validationSchema';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// styles
// style
import {
  ConfirmResetPasswordTitle,
  ConfirmResetPasswordTitleInfo,
  ConfirmResetPasswordContainer,
  ComeBackToLogin,
} from './style';

function ConfirmResetPasswordPage() {
  const {
    StyledLabel,
    StyledInputPassword,
    StyledErrorMessage,
    StyledFormControl,
  } = CustomInput;

  // hooks
  const history = useHistory();
  const { location = {} } = history;
  const { state = {} } = location;
  const { email = null, validationCode = null } = state;
  const dispatch = useDispatch();

  // useForm
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(confirmResetPasswordSchema),
    defaultValues: {
      passwordNew: null,
      passwordConfirmation: null,
    },
  });

  const [
    confirmPassword,
    { loading, error },
  ] = useMutation(
    CONFIRM_RESET_PASSWORD, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data) {
          dispatch(requestActionCreators.success(['Votre mot de passe a bien été modifié !']));
          setTimeout(() => history.push('/login'), 1500);
        }
      },
    },
  );

  // onSubmit
  function onSubmit(request, evt) {
    evt.preventDefault();
    dispatch(requestActionCreators.clean());
    const password = {
      new: request.passwordNew,
      confirmation: request.passwordConfirmation,
    };
    if (email && validationCode) {
      confirmPassword({ variables: { email, password, validationCode } });
    } else {
      dispatch(requestActionCreators.failure(['Adresse email inconnue']));
    }
  }

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);
  return (
    <ConfirmResetPasswordContainer className="auth_Child" onSubmit={handleSubmit((r, event) => onSubmit(r, event))}>
      <ConfirmResetPasswordTitle>
        Confirmation du mot de passe
      </ConfirmResetPasswordTitle>
      <ConfirmResetPasswordTitleInfo>
        Entrez votre nouveau mot de passe
      </ConfirmResetPasswordTitleInfo>
      <StyledFormControl>
        <StyledLabel htmlFor="passwordNew">Mot de passe</StyledLabel>
        <StyledInputPassword
          error={!!errors.passwordNew}
          inputRef={register}
          id="passwordNew"
          name="passwordNew"
          placeholder="Mot de passe"
        />
        {errors?.passwordNew && <StyledErrorMessage>{errors.passwordNew.message}</StyledErrorMessage>}
      </StyledFormControl>
      <StyledFormControl>
        <StyledLabel htmlFor="passwordConfirmation">Confirmation de mot de passe</StyledLabel>
        <StyledInputPassword
          error={!!errors.passwordConfirmation}
          inputRef={register}
          id="passwordConfirmation"
          name="passwordConfirmation"
          placeholder="Confirmation de mot de passe"
        />
        {errors?.passwordConfirmation && <StyledErrorMessage>{errors.passwordConfirmation.message}</StyledErrorMessage>}
      </StyledFormControl>
      <ComeBackToLogin to="/login">Revenir à la connexion</ComeBackToLogin>
      <CustomButton
        type="submit"
      >
        Envoyer
      </CustomButton>
    </ConfirmResetPasswordContainer>
  );
}

export default ConfirmResetPasswordPage;
