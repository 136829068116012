export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAA7bUf6AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKQSURB
VDiNfVQ7SyNRGD1KDGoiem+lGCeFk3hnBkHR2scvEMVK1GpB2B+xrptGUy0YrHwgVj5a342mEJQY
LBQSY7RTMcpUiUUczxaLs2YTPHCr79xzz3c+vguUo8EwjF/t7e3XQoi8x+NxhBB5XdevlVIzAPwV
7vyDYRjfGxsbX0dHR7m1tcW7uzvats3b21tubm5yZGSEQohX0zSnKgpYlhULBoNviUSCX+Hs7Iya
pr1ZljVf5iAYDBbn5uY4ODjIq6urigIrKyvs7+9nPB6npmnFz44apJSvFxcX7OnpIQC2tLQwnU6X
CCwtLbG6upoAGI1GeX5+TillAYAflmVFJicnSZLHx8esr68nAAYCAd7c3JAk19bWXIGuri7atk2S
HB8fp2maP6HremZvb8998fDwkHV1dQTAtrY2Liws0OPxEACVUnx8fHS5Ozs71HU9jaampsLLy0uJ
9f39fdbW1hIAq6qqCIDhcJj39/clvFwuRyFEHl6v13EcpyzESCRCAATAmpoaJpPJMo7jOPR6vQ6E
EIXn5+eS4sHBgevk43R0dPDh4aGE9/T0RCllHrquX+/u7rqFeDxOn8/nZhKLxdxMOjs7mcvlXO72
9jbD4XAapmnOTExMlAm0trYyk8mQJFdXV93pdHd3u9MZGxt7N01zGgD8UspCMplkb28vAbC5uZmp
VKrE+uLiohtyNBplIpH42wrgAwAopaY0TSvOzs5yYGCAl5eXZSGS5PLyMvv6+nh0dERN04qGYXz7
f3fmNU17Oz09rSjwgZOTEwYCgaJlWb8rLqFpmlNCiMLw8PD7xsYGs9ksbdtmNpvl+vo6h4aG3qWU
hTIHFeBXSk2HQqHU5/8kFAqllFI/3Aw+4Q8lJlhKYZIvmAAAAABJRU5ErkJggg==`;
