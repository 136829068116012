// packages
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

// services
import { READ_USER } from '../../../services/userServices';

// components
import Profile from './Profile';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

function ProfileContainer() {
  // const { path } = useRouteMatch();
  const userId = localStorage.getItem('@credentialsID');
  // hooks
  const dispatch = useDispatch();

  const {
    data, loading, error, refetch,
  } = useQuery(
    READ_USER, {
      variables: { userId },
      fetchPolicy: 'no-cache',
    },
  );

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    } else if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else if (!data || !data.user) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error, data]);

  return (
    <Profile user={data?.user || null} refetchUser={refetch} />
  );
}

export default ProfileContainer;
