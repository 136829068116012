// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';
import { colors } from '../../../shared/styles';

const RegisterContainer = styled.form`
overflow: auto;
padding: 0 5%;
height: auto;
width: 880px;
display: flex;
flex-direction: raw;
flex-wrap: wrap;
justify-content: space-between;
background-color: ${colors.WHITE};
box-shadow: 2px 19px 45px #76767693;
align-items: center;
border-radius: 35px;

@media screen and (max-width: 900px) {
  width: 100vw;
  margin-bottom: 0;
  border-radius: 35px 35px 0 0;
  min-height: 605px;
}
`;

const RegisterTitle = styled.h1`
color: ${colors.BLACKTITLE};
font: normal normal bold 42px/55px Roboto;
letter-spacing: 0px;
text-align: center;
width: 80%;
`;

const ActionsWrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
margin: 22px auto;
row-gap: 22px;
`;

const BackButton = styled.div`
width: 46px;
height: 46px;
border: 3px solid ${colors.DARKGRAY};
opacity: 1;
border-radius: 999px;
margin: auto 0;
`;

const TitleContainer = styled.div`
width: 100%;
display: flex;
justify-content: center;
flex-direction: row;
`;

const EmptySpace = styled.div`
width: 46px;
`;

const NextButton = styled.div`
width: 46px;
height: 46px;
border: 3px solid ${colors.BLUE};
opacity: 1;
border-radius: 999px;
align-self: center;
`;

export {
  RegisterContainer, RegisterTitle, ActionsWrapper, TitleContainer, BackButton, EmptySpace, NextButton,
};
