// Packages
import { ApolloLink } from '@apollo/client';

// Configuration
import config from '../../config';

// TODO: Update me when file server is done
// TODO: Refactor: Allow passing getter function by args
const defaultFileGetterFn = (file) => () => `${config.assets}/${file.id}`;

const until = (pred, callback) => (q) => (pred(q) && callback(q)) || q;

// eslint-disable-next-line
const isTypeOf = (type = "", obj = {}) => obj?.__typename === type;
const isTypeFile = (obj) => isTypeOf('File', obj);

const injectFunction = (obj, key, fn) => ({ ...obj, [key]: fn });
const injectContentFunction = (obj) => injectFunction(obj, '_getFile', defaultFileGetterFn(obj));

const transformFileTypeOnly = until(isTypeFile, injectContentFunction);

const identity = (val) => val;

function transformAll(val) {
  if (!val) {
    return val;
  }

  if (Array.isArray(val)) {
    return val.map(transformAll);
  }

  return {
    ...Object.entries(val).reduce((acc, [key, value]) => {
      const type = typeof value;
      const transformMap = {
        object: (obj) => transformAll(transformFileTypeOnly(obj)),
        array: (arr) => arr.map(transformAll),
      };

      const getTransformerFromType = (t) => transformMap[t] || identity;
      const transformer = getTransformerFromType(type);

      return {
        ...acc,
        [key]: transformer(value),
      };
    }, {}),
  };
}

const injectFileGetterLink = new ApolloLink((operation, forward) => forward(operation)
  .map((data) => ({ ...transformAll(data) })));

export default injectFileGetterLink;
