/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/extensions */
// packages
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

// components
import { IconButton } from '@material-ui/core';
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import PieceType from '../ListPieceTypes/PieceType';

// services
import { READ_LIBRARY_FROM_ID } from '../../../services/libraryServices';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// reducers
import { libraryActionCreators } from '../../../reducers/libraryReducer';

// styles
import {
  LibraryCarrouselWrapper,
  GoToLibraryLink,
  LibraryTitle,
  LibraryCarrouselHeader,
} from './style';

function SampleNextArrow({ className, onClick }) {
  return (
    <div
      className={className}
      style={{ zIndex: 100, right: 0 }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <IconButton
        style={{
          backgroundColor: 'white', width: '41px', height: '40px', boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <ArrowForwardIos />
      </IconButton>
    </div>
  );
}

function SamplePrevArrow({ className, onClick }) {
  return (
    <div
      className={className}
      style={{ zIndex: 100, left: 0 }}
      onClick={onClick}
      onKeyPress={onClick}
      role="button"
      tabIndex={0}
    >
      <IconButton
        style={{
          backgroundColor: 'white', width: '41px', height: '40px', boxShadow: '0px 3px 6px #00000029',
        }}
      >
        <ArrowBackIos />
      </IconButton>
    </div>
  );
}

function LibraryCarrousel({ library }) {
  // hooks
  const dispatch = useDispatch();
  const { selectedPieceTypeIdToConfig } = useSelector((state) => state.library);

  // navigation
  const history = useHistory();

  // local state
  const [selectedPiecesNumber, setselectedPiecesNumber] = useState(0);

  // initialization
  useEffect(() => {
    dispatch(libraryActionCreators.reset());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialization
  useEffect(() => {
    setselectedPiecesNumber(selectedPieceTypeIdToConfig.length || 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPieceTypeIdToConfig]);

  const { data, loading, error } = useQuery(
    READ_LIBRARY_FROM_ID, {
      variables: { libraryId: library.id },
      fetchPolicy: 'no-cache',
    },
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 4,
    // className: 'center',
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    // adaptiveHeight: true,
    centerMode: false,
    initialSlide: 0,
    centerPadding: '1em',
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 740,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 450,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 350,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // handle selection of pieces in an array to configure and print
  const handleSelection = (pieceTypeId) => () => {
    const currentIndex = selectedPieceTypeIdToConfig.indexOf(pieceTypeId);
    let newChecked = [...selectedPieceTypeIdToConfig];

    // TODO change for maxPieceType variable
    if (currentIndex === -1 && selectedPiecesNumber < 1) {
      newChecked.push(pieceTypeId);
      setselectedPiecesNumber((prev) => prev + 1);
    } else if (currentIndex === -1 && selectedPiecesNumber === 1) {
      newChecked = [pieceTypeId];
    } else {
      newChecked.splice(currentIndex, 1);
      setselectedPiecesNumber((prev) => prev - 1);
    }

    dispatch(libraryActionCreators.selectPieceTypeIdToConfig(newChecked));
    history.push(`/bibliotheques/${library.id}`);
  };

  // initialization
  useEffect(() => {
    dispatch(libraryActionCreators.reset());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // initialization
  useEffect(() => {
    setselectedPiecesNumber(selectedPieceTypeIdToConfig.length || 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPieceTypeIdToConfig]);

  // if error || loading
  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    } else if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);

  return (
    (data && data.library) ? (
      <LibraryCarrouselWrapper>
        <LibraryCarrouselHeader>
          <LibraryTitle>{library.name}</LibraryTitle>
          <GoToLibraryLink to={`/bibliotheques/${library.id}`}>Afficher tout</GoToLibraryLink>
        </LibraryCarrouselHeader>
        <Slider {...settings}>
          {data.library.pieceTypes.map((pieceType) => (
            <PieceType
              key={pieceType.id}
              pieceType={pieceType}
              isSelected={selectedPieceTypeIdToConfig.indexOf(pieceType.id) !== -1}
              onClick={() => handleSelection(pieceType.id)}
            />
          ))}
        </Slider>
      </LibraryCarrouselWrapper>
    ) : null
  );
}

SampleNextArrow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // style: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.string,
  onClick: PropTypes.func,
};
SampleNextArrow.defaultProps = {
  // eslint-disable-next-line react/forbid-prop-types
  // style: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: null,
  onClick: () => null,
};

SamplePrevArrow.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  // style: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: PropTypes.string,
  onClick: PropTypes.func,
};
SamplePrevArrow.defaultProps = {
  // eslint-disable-next-line react/forbid-prop-types
  // style: PropTypes.object.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  className: null,
  onClick: () => null,
};

LibraryCarrousel.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  library: PropTypes.object,
  selectedPiecesNumber: PropTypes.number,
  selectedPieceTypeIdToConfig: PropTypes.arrayOf(PropTypes.string),
  setselectedPiecesNumber: PropTypes.func,
};
LibraryCarrousel.defaultProps = {
  library: null,
  selectedPiecesNumber: 0,
  selectedPieceTypeIdToConfig: [],
  setselectedPiecesNumber: () => null,
};

export default LibraryCarrousel;
