/* eslint-disable react/jsx-props-no-spreading */
// packages
import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';

// component
import {
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

// styles
import { colors } from '../../styles';

const StyledFormRadioControl = styled(FormControl)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
});

const StyledFormControlLabel = styled(FormControlLabel)({
  color: colors.DARKGRAY,
});

const StyledRadioGroup = styled(RadioGroup)({
  display: 'flex',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
});
const StyledRadioFormLabel = styled(FormLabel)({
  display: 'flex',
  margin: 'auto',
  paddingRight: '10%',
  color: colors.DARKGRAY,
});

const StyledFormRadioContent = styled('div')({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  '&.Mui-error': {
    border: `1px solid ${colors.RED}`,
  },

});

function StyledRadio({
  label, value, inputRef, name, error,
}) {
  return (
    <StyledFormControlLabel
      error={error}
      label={label}
      value={value}
      name={name}
      inputRef={inputRef}
      control={(
        <Radio
          color="default"
          size="small"
        />
      )}
    />
  );
}

StyledRadio.propTypes = {
  error: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

StyledRadio.defaultProps = {
  label: '',
  error: false,
};

export default {
  StyledRadio,
  StyledFormRadioControl,
  StyledFormControlLabel,
  StyledRadioGroup,
  StyledRadioFormLabel,
  StyledFormRadioContent,
};
