export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABsAAAAUCAYAAAB8gkaAAAAACXBIWXMAAADEAAAAxAHPilhMAAAA
GXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAjVJREFUSIml1F1ojmEYB/Dfuw/M
SGErDSEOhCFb1g6UlJQcOEE7UMopcqScLDGUQ2knjnYiJbOWlBQp2XBASr5FPsrHSrFhy+vgvp92
79n7hu1fTz33df2v639/XNdVafJYhvuYhbsYLkes+seEM7AOqzEX0/AV79CGBWjHbOyf6K63ohs/
UPzLN4wVExFpRl+JhN/Qgy6M5Hyd/ytSgaNxl0V8T5L1oi7yZuJt4vuK+iTPyfhVlhOqwcXkBAdw
Ia5vohobcAc/c6c6lOSZjoFo7xbedwyqcTkSnmMVavEr2lqxHIPGX+3LXMKKGP8q+q9iSip2Njqe
YV60rYy2IRTQUUKoiB1Jnlasjf8NeBI5XRmhLRo+Y1ES2GS0yrbjYQmhW3Ej2YnuoSXJsQQfI3c3
oVd+Y5uxmBPt5Ur9N9Yn/N3RVjc2jS3R/rQiBmY7S/EFN5THOfTH/1ocx218yvEK6WJXFBzA4hxx
o9KnG8TChNce7Ztz8UuF5yliZ2bsjIYXwuhJcaSEWEfibxBapSMXN18ouCJOp44pwmTISnlNLvB8
IvRBaOoMZ7Avx2/E68i/Ilf6mWC30XI/KPRfvTAhMrG9SUxBGL4ZqoRhMBS5PcKwKIkCDhude8+E
psyEHig9gmqwB48ibwTHjC+6kmjCdePfalPCacYJ4ZrSGdpnbEv8M/qTJL2JfapQTGl1XhJ6qqAM
yjqEqnwT/4eFR38c143CmHovXF2/8E6TQguu4dRkE8EfHPvGlsQWnIQAAAAASUVORK5CYII=`;
