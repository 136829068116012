/* eslint-disable react/jsx-boolean-value */
// packages
import React, { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import {
  Switch, Route, Redirect,
  useLocation,
} from 'react-router-dom';

// services
import { READ_USER } from '../../services/userServices';

// action creators
import { requestActionCreators } from '../../reducers/requestReducer';
import { userActionCreators } from '../../reducers/userReducer';

// components
import HomePage from './HomePage';
// import Cad from '../CAD';
import Library from '../Library';
// import Cad from '../CAD';
import Profile from './Profile';
import NoMatch from '../NoMatch';

// style
import { ContentContainer } from './style';

function Main() {
  const userId = localStorage.getItem('@credentialsID');
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [readUser, { data, loading }] = useLazyQuery(
    READ_USER, {
      variables: { userId },
      fetchPolicy: 'cache-and-network',
    },
  );

  useEffect(() => {
    readUser();
  }, [readUser]);

  useEffect(() => {
    if (data && data.user) {
      dispatch(userActionCreators.read(data.user));
    }

    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [data, dispatch, loading]);

  return (
    <ContentContainer cad={!(pathname === '/modelisation')}>
      <Switch>
        <Route path="/home">
          <HomePage />
        </Route>
        <Route path="/bibliotheques">
          <Library />
        </Route>
        {/* <Route path="/modelisation">
            <Cad />
          </Route> */}
        <Route path="/profile">
          <Profile />
        </Route>
        <Redirect from="/login" to="/home" />
        <Redirect from="/" to="/home" />
        <Route path="*">
          <NoMatch />
        </Route>
      </Switch>
    </ContentContainer>
  );
}

export default Main;
