/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
// packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useMutation, useApolloClient } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// services
import { UPDATE_USER } from '../../../services/userServices';

// components
import Avatar from './Avatar';
import ProfileDetails from './ProfileDetails';
import Password from './Password';
import Identity from './Identity';

// actions
import { userActions } from '../../../actions';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// validationSchema
import { userUpdateValidationSchema } from './validationSchemas';

// shared
import { CustomButton, Modal } from '../../../shared/components';
import { colors } from '../../../shared/styles';

// assets
import { ProfileAssets } from '../../../assets';

// style
import {
  DecorumImg,
  LogoutButton,
  LogoutIcon,
  EditButton,
  EditIcon,
  ProfileTitleBackground,
  ProfilePageContainer,
  ButtonWrapper,
} from './style';

function Profile({ user, refetchUser }) {
  // hooks
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();

  // local state
  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // useForm
  const {
    handleSubmit, register, errors, control,
  } = useForm({ resolver: yupResolver(userUpdateValidationSchema) });

  function triggerLogoutModal() {
    setShowModal(true);
  }

  // logout
  function triggerLogout() {
    apolloClient.clearStore();
    dispatch(userActions.logout());
  }

  const [
    updateUser,
    { loading, error },
  ] = useMutation(
    UPDATE_USER, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data) {
          refetchUser();
          dispatch(requestActionCreators.success(['Votre profil a bien été modifié !']));
          setEditMode(false);
        }
      },
    },
  );

  // onSubmit
  function onSubmit(request, evt) {
    evt.preventDefault();
    dispatch(requestActionCreators.clean());
    if (user.id) {
      updateUser({ variables: { userId: user.id, updateUser: { ...request } } });
    } else {
      dispatch(requestActionCreators.failure(['Utilisateur inconnu']));
    }
  }

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);

  return (
    <ProfilePageContainer>
      <Modal
        actionClose={() => setShowModal(false)}
        actionValidate={() => triggerLogout()}
        show={showModal}
        titleClose="Non"
        titleAction="Oui"
        twoButtons
        title="Voulez-vous vraiment vous déconnecter ?"
      />
      <LogoutButton title="logout" onClick={() => triggerLogoutModal()}>
        <LogoutIcon />
      </LogoutButton>
      {user && (
        <>
          <EditButton onClick={() => setEditMode((prev) => !prev)}>
            <EditIcon src={ProfileAssets.edit} />
          </EditButton>
          <ProfileTitleBackground style={{ backgroundImage: `url(${ProfileAssets.ProfileBackground})` }}>
            <Avatar avatar={user?.avatar?._getFile()} userId={user?.id} refetchUser={refetchUser} />
            <Identity
              editMode={editMode}
              refetchUser={refetchUser}
              errors={errors}
              register={register}
              gender={user?.gender}
              name={user?.name}
              forename={user?.forename}
              office={user?.office}
            />
          </ProfileTitleBackground>
          <ProfileDetails user={user} editMode={editMode} errors={errors} register={register} control={control} />
          <Password />
          <DecorumImg src={ProfileAssets.profileCosmetic} />
          {editMode && (
          <ButtonWrapper>
            <CustomButton
              primarycolor={colors.BUTTONBLUE}
              secondarycolor={colors.WHITE}
              isprofile={true}
              type="submit"
              onClick={handleSubmit((r, event) => onSubmit(r, event))}
            >
              Enregister
            </CustomButton>
          </ButtonWrapper>
          )}
        </>
      )}
    </ProfilePageContainer>
  );
}

Profile.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    avatar: PropTypes.object,
    gender: PropTypes.string,
    email: PropTypes.string,
    birthdate: PropTypes.string,
    telephone: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
    localisation: PropTypes.string,
    type: PropTypes.string,
    name: PropTypes.string,
    forename: PropTypes.string,
    office: PropTypes.string,
  }),
  refetchUser: PropTypes.func.isRequired,
};

Profile.defaultProps = {
  user: {
    gender: 'M',
    birthdate: null,
    avatar: null,
    email: null,
    telephone: null,
    address: null,
    city: null,
    postalCode: null,
    localisation: null,
    type: null,
    name: null,
    forename: null,
    office: null,
  },
};

export default Profile;
