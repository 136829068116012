// packages
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';

// components
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

// shared
import PlainInput from '../PlainInput/PlainInput';

moment.locale('fr');

const {
  StyledDatePicker,
} = PlainInput;

function CustomDateTimePicker({
  defaultValue = null,
  inputRef,
  placeholder,
  name,
  istabletscreen,
}) {
  const [selectedDate, setSelectedDate] = useState();

  useEffect(() => {
    if (defaultValue) {
      const convertedDate = Number(defaultValue) / 1000;
      setSelectedDate(moment.unix(convertedDate).format('MM-DD-YYYY'));
    }
  }, [defaultValue]);

  const renderInput = ({ onClick }) => (
    <StyledDatePicker
      name={name}
      inputRef={inputRef}
      onClick={onClick}
      disableUnderline
      istabletscreen={istabletscreen}
      value={selectedDate ? moment(selectedDate).format('MM-DD-YYYY') : ''}
      placeholder={placeholder}
    />
  );

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale="fr">
      <DatePicker
        variant="dialog"
        // onError={console.log}
        format="MM-DD-YYYY"
        ampm={false}
        clearable
        todayLabel="Aujourd'hui"
        cancelLabel="Retour"
        disableFuture
        clearLabel="Supprimer"
        onChange={setSelectedDate}
        TextFieldComponent={renderInput}
      />
    </MuiPickersUtilsProvider>
  );
}

CustomDateTimePicker.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  istabletscreen: PropTypes.string.isRequired,
};

CustomDateTimePicker.defaultProps = {
  name: '',
  defaultValue: null,
  placeholder: null,
};

export default CustomDateTimePicker;
