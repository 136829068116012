// eslint-disable-next-line import/no-extraneous-dependencies
// packages
import React, { useState, useEffect } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
  Switch, Route, Redirect,
  useLocation,
} from 'react-router-dom';

// components
import Login from './Login';
import ResetPassword from './ResetPassword';
import Register from './Register';
import { ConfirmCodeValidation, ConfirmResetPassword } from './ConfirmResetPassword';
import ConfirmAccountCreation from './ConfirmAccountCreation';

// style
import {
  AuthentificationContainer,
  AuthentificationWrapper,
  AuthentificationBackground,
  AuthentificationLogo,
} from './style';

// assets
import { sharedAssets } from '../../assets';

// helpers
import { getAuthBackground, getResetBackground } from './backgroundHelper';
// import { routeHelper } from '../../shared/helpers';

function Authentification() {
  const [path, setPath] = useState(true);
  const localization = useLocation();
  const authRef = React.createRef();
  const isMobileScreen = useMediaQuery('(max-width: 900px)');

  useEffect(() => {
    setPath(localization.pathname);
  }, [localization]);

  useEffect(() => {
    authRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [authRef]);

  return (
    <AuthentificationContainer ref={authRef}>
      <AuthentificationBackground
        path={path}
        src={(path === '/login' || path === '/register') ? getAuthBackground(isMobileScreen) : getResetBackground(isMobileScreen)}
        alt="background_authentification"
      />
      <AuthentificationWrapper path={path}>
        {isMobileScreen && <AuthentificationLogo src={path === '/login' ? sharedAssets.logo : sharedAssets.logoWhite} alt="logo Ocare" />}
        <Switch>
          <Route exact strict path="/">
            <Redirect from="/" to="/login" />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/resetPassword">
            <ResetPassword />
          </Route>
          <Route
            path="/ConfirmAccountCreation"
            render={({ location = {} }) => (location.state?.email ? (
              <ConfirmAccountCreation />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            ))}
          />
          <Route
            path="/confirmCodeValidation"
            render={({ location = {} }) => (location.state?.email ? (
              <ConfirmCodeValidation />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            ))}
          />
          <Route
            path="/confirmResetPassword"
            render={({ location = {} }) => ((location.state?.email && location.state?.validationCode) ? (
              <ConfirmResetPassword />
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { from: location },
                }}
              />
            ))}
          />
          <Route path="*">
            <Redirect from="*" to="/login" />
          </Route>
        </Switch>
      </AuthentificationWrapper>
    </AuthentificationContainer>
  );
}

export default Authentification;
