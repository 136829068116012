export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABsAAAAVCAYAAAC33pUlAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFGSURB
VEiJvdU9L0RBFMbx33rZRjYIQUU0KJQ60WgUROMTSFQqUShFfAGVDo1SoiAqEZ3QKWTFF1AQIkgk
iFXs3uRm9rJr194nmWbmmfM/Z3JmJqM29WAKQ2hFAXmc4LbGmGUaxi4+S4BwfGALHfVA2rH9CyQc
NxgMg2SqAE2UqhmIzb3iDFel4EOYRFvMc4t5vOALF5VAK0E191hGLsGbwyrelVf69sMe0IzNYMMB
+iplh3E8JABHksyt2A+MG6o7chjDcwKsMzS2YC8wrVcJiRQmWsBjaMooNkLctPNHECwmwI5C01pg
OEW2BlhG+eksxA1ziu0ZLd6htwZQpCwOS7Ee0BUt9OMpyGSuDlCkJsxiND55HIB2/wH0o8Ku6U4L
ttRIUBx2rXihU4FNNxoUwc7TAEWwmbRgl6p/ZOtSs+IflU8D9g1B/n1j+fU56wAAAABJRU5ErkJg
gg==`;
