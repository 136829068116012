export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABkAAAAbCAYAAACJISRoAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFzSURB
VEiJ7dU/Sx1BFIbxX7yGIFFBUVQEBYMExRQWAQsFIQQsBEllIdjaWQRCinwCIU2KVAFJlc5aiGAX
ECGQToRAlGAf0CgY/xU7V8bduxdd9lbmhQM7Z86cZw/Dvsvd1ItvOMYaHt3x/K20gssoFtGC12Fv
qEjTNsxiJKxXU5A3+BCt99CUbtJcB/AY3zGMC8zn1E1Ez4Pox++4IEON9DwAqnULOXUPaqyX8BOb
GKg3SfpSb3vJ7fiICp7gXb1JiqojAKrqaQQko/+QewyZxo7E9D7L/x7GMJXKzaIvlZuudfiXm370
PuRnUvmisd4k61/LGJX4UBkaqATaTJSs4BkO8KIkkGb8kB3zS41ckTiqgiYldh5vnpQEOYsnKuvN
0/EvhvTiTwMgx7ElH+EcL5Wr/XTiYQOmeVWL/LZEwFfZ3/O1tkoAbKM7D0DiRxsFm5/iE1qrzXJH
CRrDHMbxFF3oxN+wfyj5Dg6wG2JN4ofXugKxV+OVCw3GugAAAABJRU5ErkJggg==`;
