// packages
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@material-ui/core/styles';
import customised from 'styled-components';

// styles
import {
  Input,
  InputLabel,
  FormControl,
  IconButton,
  InputAdornment,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { colors } from '../../styles';

const StyledFormControl = styled(FormControl)({
  width: (props) => (props.fullWidth ? '100%' : '352px'),
  margin: '10px 0',
  display: 'flex',
});

const StyledLabel = styled(InputLabel)({
  zIndex: '2',
  paddingLeft: '20px',
  color: `${colors.DARKGRAY}`,
  marginBottom: '20px',
  fontSize: '17px',
  fontWeight: '300',
  lineHeight: '23px',

  '&.MuiInputLabel-formControl': {
    transform: 'translate(0, 35px) scale(1)',
  },
  '&.MuiInputLabel-shrink': {
    transform: 'translate(-24px, -15px) scale(1.15)',
    color: `${colors.BLACK}`,
  },
});

const StyledInput = styled(Input)({
  backgroundColor: `${colors.APPBACKGROUNDCOLOR}`,
  borderRadius: '15px',
  height: '64px',
  paddingLeft: '20px',
  fontSize: '17px',
  fontWeight: '300',
  lineHeight: '23px',
  color: `${colors.SECTION_CONTENT}`,

  '&.Mui-error': {
    border: `1px solid ${colors.RED}`,
  },

  '&label + .MuiInput-formControl': {
    marginTop: 12,

  },
});

const StyledErrorMessage = customised.p`
  min-width: 100%;
  margin: 8px 0;
  font-size: 17px;
  text-align: center;
  font-weight: 300;
  line-height: 23px;
  color: ${colors.RED};
`;

const StyledInlineFormControl = styled(FormControl)({
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  flexWrap: 'wrap',
  width: '450px',
});

const StyledInlineLabel = styled(InputLabel)({
  // color: `${colors.DARKGRAY}`,
  fontSize: '19px',
  fontWeight: '500',
  lineHeight: '23px',
  height: '37px',

  '&.MuiInputLabel-formControl': {
    position: 'relative',
    transform: 'unset',
  },
  '&.MuiInputLabel-shrink': {
    transform: 'unset',
    color: 'unset',
  },
});
const StyledInlineInput = styled(Input)({
  backgroundColor: `${colors.LIGHTBLUEGRAY}`,
  borderRadius: '15px',
  width: '220px',
  height: '37px',
  paddingLeft: '20px',
  fontSize: '15px',
  fontWeight: '300',
  lineHeight: '18px',
  color: `${colors.DARKGRAY}`,

  '&.Mui-error': {
    border: `1px solid ${colors.RED}`,
  },

});

const StyledInlineErrorMessage = customised.p`
  min-width: 100%;
  margin: 8px 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-align: 'right';
  color: ${colors.RED};
`;

const StyledInputPassword = (
  {
    id,
    error,
    inputRef,
    placeholder,
    defaultValue,
    name,
    istabletscreen = 'false',
  },
) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((prevState) => !prevState);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <StyledInput
      error={error}
      istabletscreen={istabletscreen}
      inputRef={inputRef}
      id={id}
      defaultValue={defaultValue}
      type={showPassword ? 'text' : 'password'}
      name={name}
      placeholder={placeholder}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
          >
            {showPassword ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )}
      disableUnderline
      fullWidth
    />
  );
};

StyledInputPassword.propTypes = {
  error: PropTypes.bool,
  istabletscreen: PropTypes.string,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string.isRequired,
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

StyledInputPassword.defaultProps = {
  error: false,
  istabletscreen: 'false',
  defaultValue: '',
  placeholder: '',
};

export default {
  StyledFormControl,
  StyledLabel,
  StyledInput,
  StyledErrorMessage,
  StyledInputPassword,
  StyledInlineFormControl,
  StyledInlineInput,
  StyledInlineLabel,
  StyledInlineErrorMessage,
};
