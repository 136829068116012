/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
// packages
import React from 'react';
import PropTypes from 'prop-types';

// components
import PieceToPrint from './PieceToPrint';

// style
import { PrintListContainer, PrintListTitle } from './style';

function PrintList({
  piecesList = [],
  pieceTypeName,
  pieceTypePreview,
  shore,
}) {
  if (!piecesList) return null;
  return (
    <>
      <PrintListTitle>Résumé</PrintListTitle>
      <PrintListContainer>
        {
          piecesList && piecesList.map((piece) => {
            const enhancedPiece = {
              ...piece, pieceTypeName, pieceTypePreview, shore,
            };
            return (
              <PieceToPrint
                piece={enhancedPiece}
                key={piece.id}
              />
            );
          })
        }
      </PrintListContainer>
    </>
  );
}

PrintList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  piecesList: PropTypes.array,
  pieceTypeName: PropTypes.string.isRequired,
  pieceTypePreview: PropTypes.string.isRequired,
  shore: PropTypes.number.isRequired,
};

PrintList.defaultProps = {
  piecesList: [],
};

export default PrintList;
