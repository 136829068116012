// actionCreators
import { userActionCreators } from '../reducers/userReducer';
import { requestActionCreators } from '../reducers/requestReducer';

// Logout
const logout = () => (dispatch) => {
  dispatch(requestActionCreators.clean());
  localStorage.removeItem('@credentials');
  localStorage.removeItem('@credentialsID');
  dispatch(userActionCreators.logout());
};

// eslint-disable-next-line import/prefer-default-export
export { logout };
