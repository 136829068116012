/* eslint-disable react/no-array-index-key */
// packages
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { styled } from '@material-ui/core/styles';

// shared
import { colors } from '../../styles';

const CustomAlert = styled(Alert)({
  '&.MuiAlert-filledSuccess': {
    backgroundColor: `${colors.GREEN_TOASTER}`,
  },
});

function SuccessToaster() {
  // localstate
  const [show, setShow] = useState(false);
  // redux
  const successes = useSelector((state) => state.request.success);

  useEffect(() => {
    if (successes) {
      setShow(true);
    }
  }, [successes]);

  if (!successes) return null;
  return (
    <>
      {successes.map((success, index) => (
        <Snackbar
          open={show}
          key={`${index}_${success}`}
          autoHideDuration={6000}
          onClose={() => setShow(false)}
        >
          <CustomAlert
            elevation={6}
            variant="filled"
            onClose={() => setShow(false)}
            severity="success"
          >
            <AlertTitle>{success}</AlertTitle>
          </CustomAlert>
        </Snackbar>
      ))}
    </>
  );
}

export default SuccessToaster;
