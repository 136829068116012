// packages
import { styled } from '@material-ui/core/styles';
import customised from 'styled-components';

// styles
import { IconButton } from '@material-ui/core';
import { PowerSettingsNew } from '@material-ui/icons';
import { colors } from '../../../shared/styles';

const ProfilePageContainer = customised.div`
  margin: 0;
  width: 100%;
  min-height: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 400px 170px auto 5em;
  grid-template-areas:"title title title""details details aside""details details icon"". button .";
  gap: 20px 25px;
  grid-gap: 20px 25px;

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: 400px 1fr 170px auto 5em;
    grid-template-areas:"title""details""aside""icon""button";
  }
  `;

// width: 100%;
// min-height: 100%;
const ProfileContainer = customised.form`
  margin: 0;
  background-color: ${colors.WHITE};
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

const ProfileSectionWrapper = customised.div`
  grid-area: details;
  padding: 32px 32px 32px 57px;
  boxShadow: 0px 3px 6px #00000029;
  background-color: ${colors.WHITE};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 32px 5px 32px 15px;
  }
  `;

const ProfileDetailWrapper = customised.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  `;

const ProfileChangeAvatarWrapper = customised.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 15px;

  @media screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
    flex-direction: column;
    align-content: center;
  }
  `;

const ProfileChangePasswordWrapper = customised.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    margin-bottom: 10px;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 10px;
    flex-direction: column;
    align-content: center;
  }
  `;

const ProfileDetailNameWrapper = customised.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  max-width: 100%;
  
  @media screen and (min-width: 1200px) {
    margin-bottom: 1rem;
  }
  @media screen and (max-width: 1200px) {
    margin-bottom: ${(props) => (props.editMode ? '-15px' : '1rem')};
  }
  @media screen and (max-width: 600px) {
    margin-left: 25%;
    justify-content: ${(props) => (props.editMode ? 'flex-end' : 'flex-start')};
    margin: 0 auto 1rem;
    min-width: ${(props) => (props.editMode && '90%')};
  }
  `;

const ProfileDetailLabel = customised.span`
  text-align: left;
  font: normal normal normal 27px/35px Roboto;
  letter-spacing: 0px;
  color: ${colors.SECTION_TITLE};
  min-width: 50%;
  margin-bottom: 15px;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 21px;
  }
  `;

const ProfileDetailValue = customised.span`
  text-align: left;
  font: normal normal normal 27px/35px Roboto;
  letter-spacing: 0px;
  min-width: 50%;
  color: ${colors.SECTION_VALUE};
  margin-bottom: 15px;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 21px;
  }
  `;

const ProfileDetailNameLabel = customised.span`
  font: normal normal normal 27px/35px Roboto;
  letter-spacing: 0px;
  margin: 0 15px 0 0;
  min-width: ${(props) => (props.editMode ? '107px' : 'none')};
  text-align: ${(props) => (props.editMode ? 'right' : 'left')};

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 21px;
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
    line-height: 21px;
    min-width: 0;
  }
  `;

const ProfileDetailNameValue = customised.span`
  text-align: left;
  font: normal normal normal 27px/35px Roboto;
  ${(props) => ((!props.editMode && props.isName) ? `color: ${colors.SECTION_TITLE}` : `color: ${colors.SECTION_TITLE}`)};
  letter-spacing: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 21px;
  }
  `;

const ProfileDetailNameValueUppercase = customised.span`
  text-align: left;
  font: normal normal normal 27px/35px Roboto;
  letter-spacing: 0px;
  color: ${colors.SECTION_TITLE};
  text-transform: uppercase;

  @media screen and (max-width: 1200px) {
    font-size: 14px;
    line-height: 21px;
  }
  `;

const AsideSectionWrapper = customised.div`
  grid-area: aside;
  padding: 32px 32px 32px 57px;
  boxShadow: 0px 3px 6px #00000029;
  background-color: ${colors.WHITE};
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  `;

const ButtonWrapper = customised.div`
  grid-area: button;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  `;

const ProfileTitleBackground = customised.div`
  position: relative;
  grid-area: title;
  background-repeat: no-repeat;
  background-position: bottom;
  background-clip: border-box;
  background-position: 0% 0%;
  margin: 117px -5px -1px;
  padding: 0;
  background-size: 100% 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 1200px) {
    height: 250px;
    margin-top: 140px;
    padding-top: 50px;
  }
`;

const ProfileTitleBackgroundImg = customised.img`
  width: 100%;
  height: 334px;
`;

const DecorumImg = customised.img`
  grid-area: icon;
  width: 100%;
  height: 218px;
`;

const SectionTitle = customised.h2`
  text-align: left;
  margin-bottom: 30px;
  font: normal normal medium 36px/48px Roboto;
  letter-spacing: 0px;
  color: ${colors.SECTION_TITLE};
  width: 100%;

  @media screen and (max-width: 900px) {
    font-size: 20px;
    line-height: 32px;
  }
`;

const EditProfileAvatarWrapper = customised.div`
  width: 200px;
  height: 200px;
  border-radius: 100%;
  background-color: ${colors.WHITE};
  boxShadow: 0px 3px 6px #00000029;
  position: absolute;
  left: 5em;
  top: -35%;

  @media screen and (max-width: 800px) {
    width: 150px;
    height: 150px;
    top: -25%;
    left: 2em;
  }

  @media screen and (max-width: 600px) {
    width: 100px;
    height: 100px;
    top: -25%;
    left: 2em;
  }
`;

const ProfileAvatarWrapper = customised.div`
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-color: ${colors.WHITE};
  boxShadow: 0px 3px 6px #00000029;
  overflow: hidden;
`;

const ProfileAvatarFormWrapper = customised.div`
  width: 343px;
  height: 343px;
  border-radius: 100%;
  background-color: ${colors.WHITE};
  boxShadow: 0px 3px 6px #00000029;
  overflow: hidden;

  @media screen and (max-width: 700px) {
    width: 150px;
    height: 150px;
  }

`;

const ProfileAvatar = customised.img`
  box-shadow: 0px 3px 6px #00000043;
  margin-right: 46px;
  height: 100%;
  width: 100%;
  object-fit: cover;

  @media screen and (max-width: 900px) {
    margin-right: 0;
    margin-bottom: 15px;
  }
`;

const ImportButton = customised.label`
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 22px 92px;
  box-sizing: content-box;
  align-items: center;
  background: ${colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000043;
  border-radius: 10px;
  max-width: 378px;
  height: 83px;

  @media screen and (max-width: 600px) {
    width: auto;
    padding: 10px;
  }

  &:hover {
    background: ${colors.BUTTONBLUE};

    & span {
      color: ${colors.WHITE};
    }
  }
  &:active {
    background: ${colors.BUTTONBLUE};

    & span {
      color: ${colors.WHITE};
    }
  }

  &:invalid+span {
    color: ${colors.RED_TOASTER};
  }
  &:valid+span {
    color: ${colors.GREEN_TOASTER};
  }
`;

const ImportButtonLabel = customised.span`
  font: normal normal normal 29px/38px Roboto;
  color: ${colors.SECTION_TITLE};
`;

const DeleteButton = customised.button`
  font: normal normal normal 29px/38px Roboto;
  color: ${colors.SECTION_TITLE};
  margin-top: 1em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 22px 92px;
  box-sizing: content-box;
  align-items: center;
  background: ${colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000043;
  border-radius: 10px;
  max-width: 378px;
  border: none;
  height: 83px;

  @media screen and (max-width: 600px) {
    width: auto;
    padding: 10px;
  }

  &:hover {
    background: ${colors.BUTTONBLUE};
    color: ${colors.WHITE};
  }
  &:active {
    background: ${colors.BUTTONBLUE};
    color: ${colors.WHITE};
  }
`;

const AvatarButtonsContainer = customised.div`
  width: auto;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;

`;

const LogoutButton = styled(IconButton)({
  width: '40px',
  height: '40px',
  backgroundColor: `${colors.RED_COUNTDOWN}`,
  position: 'absolute',
  boxSizing: 'content-box',
  boxShadow: '0px 3px 6px #00000029',
  top: 0,
  right: 32,
});

const LogoutIcon = styled(PowerSettingsNew)({
  width: '30px',
  height: '30px',
  color: `${colors.WHITE}`,
  '&:hover': {
    color: `${colors.RED_COUNTDOWN}`,
  },
});

const EditButton = styled(IconButton)({
  width: '40px',
  height: '40px',
  position: 'absolute',
  boxSizing: 'content-box',
  background: '#FFFFFF 0% 0 % no-repeat padding-box',
  top: 0,
  right: 110,
});

const EditIcon = customised.img`
  width: 80px;
  height: 80px;
`;

const EditAvatarButton = styled(IconButton)({
  width: (props) => (props.ismobilescreen === 'true' ? '25px' : '30px'),
  height: (props) => (props.ismobilescreen === 'true' ? '25px' : '30px'),
  top: (props) => (props.ismobilescreen === 'true' ? '0' : '5px'),
  right: (props) => (props.ismobilescreen === 'true' ? '-5px' : '0'),
  position: 'absolute',
  boxSizing: 'content-box',
  background: '#FFFFFF 0% 0 % no-repeat padding-box',
  zindex: '10',
});

const EditAvatarIcon = customised.img`
  width: 60px;
  height: 60px;

  @media screen and (max-width: 900px) {
    width: 50px;
    height: 50px;
  }
`;

export {
  ProfilePageContainer,
  ProfileContainer,
  ProfileDetailWrapper,
  ProfileDetailLabel,
  ProfileDetailValue,
  ProfileDetailNameLabel,
  ProfileDetailNameValue,
  AsideSectionWrapper,
  ProfileChangeAvatarWrapper,
  ProfileTitleBackground,
  ProfileTitleBackgroundImg,
  ButtonWrapper,
  ProfileChangePasswordWrapper,
  SectionTitle,
  ProfileDetailNameValueUppercase,
  ImportButtonLabel,
  ProfileSectionWrapper,
  ProfileDetailNameWrapper,
  ProfileAvatarFormWrapper,
  EditProfileAvatarWrapper,
  ProfileAvatarWrapper,
  AvatarButtonsContainer,
  DeleteButton,
  ProfileAvatar,
  LogoutButton,
  LogoutIcon,
  EditButton,
  EditIcon,
  EditAvatarButton,
  EditAvatarIcon,
  DecorumImg,
  ImportButton,
};
