export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAYAAAARfGZ1AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOTQAADk0BRtVoCQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAM/SURB
VEiJnZVNTCJ3GMZ/KtXZROlGS5uK7MFFE5LCmopSNRxwSNQoHDRAjZimsXeTUTQBayZN2t7swT31
0Kb10q+kBUK8aKr2S9a9rbTd3RoTvSwkLnVLpRFxekCIS5ZF9rnN+z7P7z/zJv934AqanZ19u7Oz
89/6+nrFbDanJEnyXCVXVrIs32hpacmGw2Elk8kooVBI0el02UAgoCuXrS5niMfjUz09PdUjIyOo
VCocDgcWi6U6mUxOlcuqyvTfisVidpXqadvR0RHxeNwOrALRUuGaEvVXgRXgvUQi8ZkgCP17e3uq
uro6lpeXicVi/+3u7n5ydnb2MWADfgROiiHPGosB+A3YBozpdPq20+nUHxwcRBcXF5OHh4dRh8Oh
T6fTt4E3gLsXfkOZKaAB/gJGyxmLNAY8BF55nukbwF8hOK/3ga9KNc3AA+ClF4TXknv7N/OFGoCF
hYWbgiB82d7evu/1er/f2NjIVEqWZfmaIAi21tbW8fHx8fWtra0kMzMzk1qtNuv1epXBwUHFYDCc
XOWCFIFvGAyGk6GhIWViYkLRarVZSZK8mEymJ6urq0pePp9PGRsb+6US+Ojo6K/z8/MFRiQSUTo6
Oo5Rq9XnmUym0FhbW1P6+voeVQLv7e19tL6+XmCcnp4qarX6vFqv1z8Jh8MFYzAYpLm5+c9K4Fqt
9n4oFCo8h8Nh9Hr9sUoUxanp6emvV1ZWahKJBKlU6p/h4eGKtl5bW5s7Eok8tFqtDRqNhp2dnTOP
x5PbPX6///X+/v6fRVH8Vpbl2krAecmyXCuK4nd2u/0nv9//WnH/FrAH1L0IHBCAfcCYL1xeXHFy
F+kWuUVUqT4AHgOfljJcB/4AJi8XA4GAzuVy3bFarY/dbvcdWZZbinKTwO8X+efq5sUBHwLC0tLS
NaPRmJ6bm1M2NzcVn8+nmEymtCzLArlRfHQBbr3qJ14HvgD2BwYGgqIoKpdls9kUu93+A7kZfw68
/CxIqd/c38A7gDObzWoaGxufajY1NVFVVaUBnMC7wHEl8LzuWSwWz/b29nkoFCKTyRAMBolGo+fd
3d1u4F6ZfHlJkuQym82phoYGpaurKyVJkusquf8B0U1OwlxMaqcAAAAASUVORK5CYII=`;
