// eslint-disable-next-line import/no-extraneous-dependencies
// packages
import React from 'react';
import { useSelector } from 'react-redux';
import { HashRouter as Router } from 'react-router-dom';

// components
import Authentification from './Authentification';
import Main from './Main';
import { Loader, ErrorToaster, SuccessToaster } from '../shared/components';

// style
import { AppContainer, GlobalStyle } from './style';

function App() {
  // get redux values
  const { isConnected } = useSelector((state) => state.user);
  return (
    <Router>
      <GlobalStyle />
      <AppContainer>
        <Loader />
        <ErrorToaster />
        <SuccessToaster />
        {isConnected ? <Main /> : <Authentification />}
      </AppContainer>
    </Router>
  );
}

export default App;
