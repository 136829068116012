/* eslint-disable react/no-array-index-key */
// packages
import { styled } from '@material-ui/core/styles';

// component
import {
  MenuItem,
  FormControl,
  Select,
} from '@material-ui/core';

// styles
import { colors } from '../../styles';

const StyledFormSelectControl = styled(FormControl)({
  margin: '5px 0',
  display: 'flex',
  border: 'none',
  minWidth: '50%',
  width: '50%',
});

const CustomStyledSelect = styled(Select)({
  '&.MuiInputBase-root': {
    backgroundColor: '#E4E4E4',
    borderRadius: '7px',
    fontWeight: 'normal',
    paddingLeft: '20px',
    color: `${colors.SECTION_VALUE}`,
    fontSize: (props) => (props.istabletscreen === 'true' ? '15px' : '27px'),
    lineHeight: (props) => (props.istabletscreen === 'true' ? '23px' : '35px'),
    height: (props) => (props.istabletscreen === 'true' ? '30px' : '50px'),
  },
  '&.Mui-error': {
    border: `1px solid ${colors.RED}`,
  },
  '&.MuiOutlinedInput-root': {
    minWidth: '100%',
  },
});

const CustomStyledMenuItem = styled(MenuItem)({});

export default { StyledFormSelectControl, CustomStyledSelect, CustomStyledMenuItem };
