export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAACIAAAAeCAYAAABJ/8wUAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGhSURB
VFiF3da9axRBGAfgJ98EI4JiozFNIBIE4x+gnaWxs0mROkF7wTpFSCNaWNnZCGlDkj9ARGyCIObE
gIUgEkIIIVzhRzbF7pLLcne7t7u3J/nBsHDMvPswtzO8nJPcw3AZhQYKrB3EOo7xsQxM3jxBgF1c
7BViDL8iSIBnvYIsNSACHOBy1YhrOEpAAixXDXndBBGgjvGqENP40wIS4FVVkLU2iAC/MdltxP0U
RDze5CneFz2vCD/CCVyPxkT02zhuyH5XHGMTX/A1etawnwapY7Qjfr7sNqC2o1HDj3jCB9m2vBuj
jgcx5AI2eoDYw93ktg3jbYWIHUwlEXH68LwCxHtcbYVozNMuIlZ1eDAe41/JiBfo7wQRZ054UxYF
/MViHkBjPpcAWcjyonZbNYqbufhnM1IUclvYlxbNTFHInRIQ/xXkFoa6DQkyzBnR5hZNgwwIv5FW
OcRLYavwEJ9S3pPp72mWac2P4nfhzXspMb8fj4T9R7N1uRvruUShLcxLP0Ux6Fti/UZeyEpU4B1m
nXZyWTMkhO9EdX6mLTgBFD8mgEEj0nUAAAAASUVORK5CYII=`;
