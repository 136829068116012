/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
// eslint-disable-next-line import/no-extraneous-dependencies
// packages
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useHistory } from 'react-router-dom';

// style
import {
  RegisterContainer, RegisterTitle, ActionsWrapper, TitleContainer,
} from './style';
import { AuthentificationChangeTypeAuthWrapper, AuthentificationChangeTypeAuthLink } from '../style';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// validation schema
import validationSchema from './validationSchema';

// fields
import fields from './fields';

// components
import Page from './Page';

// services
import { REGISTER } from '../../../services/userServices';

// shared
import { CustomButton } from '../../../shared/components';

function Register() {
  // navigation
  const history = useHistory();

  // hooks
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const {
    register, handleSubmit, control, errors, setValue,
  } = useForm({ resolver: yupResolver(validationSchema), shouldUnregister: false });

  const [
    registerUser,
    { loading, error },
  ] = useMutation(
    REGISTER, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data) {
          dispatch(requestActionCreators.success(['Mot de passe envoyé !']));
          const location = {
            pathname: '/ConfirmAccountCreation',
            state: { email },
          };
          setTimeout(() => history.push(location), 1500);
        }
      },
    },
  );

  // onSubmit
  function onSubmit(request, evt) {
    evt.preventDefault();
    dispatch(requestActionCreators.clean());
    const finalUser = { user: request };
    delete finalUser.user.captcha;
    setEmail(finalUser.user.email);
    registerUser({ variables: finalUser });
  }

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading]);

  return (
    <RegisterContainer onSubmit={handleSubmit((r, event) => onSubmit(r, event))}>
      <TitleContainer>
        <RegisterTitle>Inscription</RegisterTitle>
      </TitleContainer>
      <Page
        inputRef={register}
        fields={fields}
        handleSubmit={handleSubmit}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <ActionsWrapper>
        <CustomButton
          type="submit"
        >
          Inscription
        </CustomButton>
        <AuthentificationChangeTypeAuthWrapper>
          <span>Vous avez déja un compte ? </span>
          <AuthentificationChangeTypeAuthLink to="/login"> Connectez-vous</AuthentificationChangeTypeAuthLink>
        </AuthentificationChangeTypeAuthWrapper>
      </ActionsWrapper>
    </RegisterContainer>
  );
}

export default Register;
