export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAACYAAAAjCAYAAAD48HgdAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGXSURB
VFiF7Zg/a1RREEfPzH0xWGjnl0hhs2QR/NOKoF2KEPB7CKlSWNtrkSJVmjRBEP0ISWFnY9JJQCsJ
JG6WOz+LzcIiCO/deN1XvAO3GWaGw3tzizvGjIaUtlxaB25RRnN9fhXWK+CUiF3gRwM0ZvaRiKsw
O8RsWth43SLW5P6usB6X7sv9syKeNKS0SUSW9AxJpU1J6dzM7irnt6UtAsD9q7u/dpfGMnsPlEv9
SyIOBWNnNlOTZfssMAFWfdkWf6O3Yk21zim9dOlRy2xFxA5wNg/UE8v5S6R0u2X2FPi5GKgnBsfk
fFxa3NsZ661YzV85IqVRy9wpOe8DF/NAzVu55lJbMQV84L+I5bwXsFda3tsZ661YzeG/A9xrmTsB
vi0Gqom5+yvBZst0KeIpcDIPVBOLiG1gu7S+tzM2iHVlEOvKINaVQawrg1hXHLiifPVUg1Vg4mF2
ZNJzwJZtBID7C4MjY7Yf+wRcyv2APx6erTF7aDmP5f6mUGnFpQeCDUU8nn+l5vpJPwJWChvfdKPI
wkbx+2+6GIRFxs9jTAAAAABJRU5ErkJggg==`;
