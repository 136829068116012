/* eslint-disable no-console */
// packages
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// services
import { IS_VALIDATION_CODE_VALID } from '../../../services/userServices';

// shared components
import { CustomInput, CustomButton } from '../../../shared/components';

// validation schema
import confirmCodeValidationSchema from './validationSchema';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// styles
// style
import {
  ConfirmResetPasswordTitle,
  ConfirmResetPasswordTitleInfo,
  ConfirmResetPasswordContainer,
  ComeBackToLogin,
} from './style';

function ConfirmAccountCreation() {
  const {
    StyledLabel,
    StyledInput,
    StyledErrorMessage,
    StyledFormControl,
  } = CustomInput;

  // hooks
  const history = useHistory();
  const { location = {} } = history;
  const { state = {} } = location;
  const { email = null } = state;
  const dispatch = useDispatch();

  // localState
  const [codeValidationValue, setCodeValidationValue] = useState(null);

  // useForm
  const { handleSubmit, register, errors } = useForm({
    resolver: yupResolver(confirmCodeValidationSchema),
    defaultValues: {
      validationCode: null,
    },
  });

  const [
    isCodeValidationValid,
    { loading, error },
  ] = useLazyQuery(
    IS_VALIDATION_CODE_VALID, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (data && codeValidationValue) {
          dispatch(requestActionCreators.success(['Code de validation vérifié !']));
          const locationParams = {
            pathname: '/login',
          };
          setTimeout(() => history.push(locationParams), 1500);
        }
      },
    },
  );

  // onSubmit
  function onSubmit(request, evt) {
    evt.preventDefault();
    dispatch(requestActionCreators.clean());

    setCodeValidationValue(request.validationCode);
    if (email) {
      isCodeValidationValid({ variables: { email, validationCode: request.validationCode } });
    } else {
      dispatch(requestActionCreators.failure(['Code non vérifié']));
    }
  }

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);
  return (
    <ConfirmResetPasswordContainer className="auth_Child" onSubmit={handleSubmit((r, event) => onSubmit(r, event))}>
      <ConfirmResetPasswordTitle>
        Confirmation du code de validation
      </ConfirmResetPasswordTitle>
      <ConfirmResetPasswordTitleInfo>
        Entrez le code que vous avez reçu par mail
      </ConfirmResetPasswordTitleInfo>
      <StyledFormControl>
        <StyledLabel htmlFor="validationCode">Code de validation</StyledLabel>
        <StyledInput
          error={!!errors.validationCode}
          inputRef={register}
          id="validationCode"
          type="input"
          name="validationCode"
          placeholder="Code de validation"
          disableUnderline
          fullWidth
        />
        {errors?.validationCode && <StyledErrorMessage>{errors.validationCode.message}</StyledErrorMessage>}
      </StyledFormControl>
      <ComeBackToLogin to="/login">Revenir à la connexion</ComeBackToLogin>
      <CustomButton
        type="submit"
      >
        Envoyer
      </CustomButton>
    </ConfirmResetPasswordContainer>
  );
}

export default ConfirmAccountCreation;
