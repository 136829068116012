export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAYAAAB2KjFhAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAO9QAADvUBnFDdoQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFoSURB
VEiJpdUrSwRRGIfxd1bDiqCgyHpBFFFERARFMIjJ4G6xmA0Gv4TFJIIYtmjYosEtG2QNFoNBxCYI
G/0M3vB+2cdywHXcc/A/nniG8+OZdwaOWYIFHAB5oDPJ+Vpomu/14NCepNgRv9czsJUSoQkzm6/z
KG1mTWpVuU4VwBvQr0DjQNWDFdSqkgf6AIYUaAT49GC7alUxUDWsQIPAuwcrqlV7HqgKjCnQQKCq
pFYVAlWTCtQLvHqwslq17YEAphSoC3jyQEdqVT5QNaNAGeDRAx2rVZuBqlkFagfuPdCZWrUeqJpT
oFbgxgOdq1VrgaqsArUA1x7oAogULAe8eLAF6RUdmAE2+PmPVYC/32BAX+1MgA43v1tgUS3KuYpL
YAlocPttUpU7tBybUcWhjRJkZikzy8T2Rs1sxcyak2Ddsb0TM8tGUXSnYvHL9RBIy0gNduqg/SRz
imNXwI785TzY6r8Rt74AGvdVqD4EbGMAAAAASUVORK5CYII=`;
