import displayFrTranslation from './displayFrTranslation';

const { displayFrFootSizeOption, displayFrFootSideOption } = displayFrTranslation;
/**
 * Create a French name for gcode file according to the chosen configuration
 *
 * @param {Object} options object configuration of file
 * @params {Array.<{
 *   name: String!,
 *   footSide: String!,
 *   footSize: Number!,
 *   shore: Number!,
 *   thickness: Number!,
 * }>} object configuration of file
 * @return {String}return new name of file
 */
const getFrenchNameforFile = (
  {
    name, footSide, footSize, shore, thickness,
  },
) => {
  if (!name) {
    throw new Error('name is null');
  }
  if (!footSide) {
    throw new Error('footSide is null');
  }
  if (!footSize) {
    throw new Error('footSize is null');
  }
  if (!shore) {
    throw new Error('shore is null');
  }
  if (!thickness) {
    throw new Error('thickness is null');
  }
  return `${name}_P${displayFrFootSizeOption(footSize).split(' ').join('')}_S${shore}_H${thickness}_${displayFrFootSideOption(footSide)[0].toUpperCase()}`;
};

export default getFrenchNameforFile;
