// packages
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';
// import { PersistGate } from 'redux-persist/integration/react';

import { PersistGate } from 'redux-persist/integration/react';
// GraphQLClient
import createGQLClient from './GraphQLClient';

// store
import store, { persistor } from './reducers';

// Components
import App from './components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const WrappedApp = () => (
  <ApolloProvider client={createGQLClient()}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </ApolloProvider>
);

if (typeof window !== 'undefined') {
  // eslint-disable-next-line no-undef
  ReactDOM.render(<WrappedApp />, document.getElementById('main'));
}
