// eslint-disable-next-line import/no-extraneous-dependencies
import styled from 'styled-components';
import { colors } from '../../shared/styles';

const LibraryContainer = styled.div`
  padding: 0px;
  margin: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7px;
  grid-gap: 7px;
`;

const LibraryWrapper = styled.div`
  margin: 0;
  padding: 52px 42px 70px;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.WHITE};
  box-shadow: 0px 3px 6px #00000029;
  flex-wrap: wrap;
`;

const LibrariesListContainer = styled.div`
  margin: 0;
  /* padding: 52px 42px 70px; */
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LibraryTitle = styled.h1`
  display: block;
  text-align: center;
  height: 40px;
  padding: 0 25px 5px;
  @media (max-width: 840px) {
    width: 100%;
  }
`;

const LibraryTitleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 30px;
  right: 40px;

  @media (max-width: 900px) {
    bottom: 115px;
  }

`;

const NoLibraryData = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-content: wrap;
`;

const LibraryInfo = styled.span`
  display: block;
  text-align: center;
  padding: 0 25px 5px;
  color: ${colors.TEXTGREYINFO};
  font-size: 18px;
  line-height: 27px;
  font-weight: 700;
  ${(props) => (
    !props.isFullWidth && 'width: 100%'
  )};
`;

const LibraryTitleBackgroundWrapper = styled.div`
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  max-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;
  background-color: ${colors.WHITE};

  @media screen and (max-width: 900px) {
    height: 200px;
  }
`;

const LibraryTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 616px;
  padding: 10px;

  @media screen and (max-width: 900px) {
    max-width: 200px;
  }
`;

const LibraryTitleBackground = styled.h1`
  text-align: left;
  display: block;
  font-weight: medium;
  font-size: 50px;
  margin: 0;
  line-height: 60px;
  letter-spacing: 0;
  color: ${colors.SECTION_TITLE};
  text-transform: uppercase;

  @media screen and (max-width: 900px) {
    font-size: 25px;
    line-height: 33px;
  }
`;

const LibraryExplanations = styled.p`
  text-align: left;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0;
  color: ${colors.SECTION_TITLE};
  opacity: 1;

  @media screen and (max-width: 900px) {
    font-size: 11px;
    line-height: 20px;
  }
`;

export {
  LibraryContainer,
  LibrariesListContainer,
  LibraryTitle,
  LibraryTitleContainer,
  LibraryWrapper,
  LibraryInfo,
  LibraryTitleBackgroundWrapper,
  LibraryTitleWrapper,
  LibraryTitleBackground,
  NoLibraryData,
  LibraryExplanations,
};
