export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAKqSURB
VEiJrZZBi9NAFMd/abJC0yIi7NVTlz24iH4BL+3FFvoFvHu0pl3FD6B4KxULpR53uyiIrHrIQbd+
B0Vo02VvehAlEbfsgmsYD0mzk0nSbWX/8MhM5uX937z35k1gOUwBEcrhkt/OxQbwHCiGc6HIDE+A
6v8QXAa6wElo8PEcolvS3AbWlyG6rxj8A7wDfOmdD7wEviq6bxYhuBA+i8C3lB2cJX+B64otAHLS
eAP4SRAmHfi0iGcKvgAjwAK+E4QVAE1S+gBUwvGU0wIAwDRNKpUKpVIJIQQHBwfs7e1xdHSkkrkE
OQYYA9cIcg1AnYxwaJomNjc3hed5QoXneaLZbApN0+aF857sRYWUnGiaJnZ2dhIEKra3t7PIPOC2
uuUisCsrtlqtmMHRaCTa7bZot9tiPB7H1izLUkneA6sqyQwvZoqFQiEWrm63K3Rdjwzpui56vV60
7rquME1TJhqoxuW2Ekm9Xo+MjMfjGIlM5jhOpFer1bLydJgDCmlbW1tbi8a2beP7fkLH931s247m
6+uZTaGYy1o5b+QIQpfAZDKJxtVqFV3XEzqGYVCr1aK54zhZPAmO14RxNU0zVgy9Xk8YhhHF3TAM
0e/3Y8WQz+flvOymMa4ADZTCaDabsRJ2HEd0Oh3R6XTEZDKJrTUaDbUAToCnwEWZ6CEp1aJpmhgM
Bmce2K2trXndoS8TrRKc4lQyy7KE67oJAtd1RaPRmEdyAlyFeFO1gDbwC/gM3JQ9yefzlMvlqOz3
9/cZDoccHx+raXgL3ACuAM+Au2l5ehDuLgd8zPBynkyBSwTtrMVpF89EZigXkEdnGZehFscUeEXy
Kh8gHYlQfofRWQgrwB3gR/ixFb5XvYf4tT8kuOiWxipB3mYephEBlAkuz3PDoUSy1A/kPzvmQeBO
hP+aAAAAAElFTkSuQmCC`;
