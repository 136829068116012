/* eslint-disable react/jsx-boolean-value */
// packages
import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// shared
import { PlainInput } from '../../../../shared/components';

// style
import {
  ProfileDetailNameWrapper,
  ProfileDetailNameLabel,
  ProfileDetailNameValue,
  ProfileDetailNameValueUppercase,
} from '../style';

function Identity({
  editMode,
  errors = null,
  register,
  gender = 'M',
  name = null,
  forename = null,
  office = null,
}) {
  const {
    StyledInput,
    StyledErrorMessage,
    StyledFormControl,
  } = PlainInput;

  const istabletscreen = useMediaQuery('(max-width: 1200px)');
  return (
    <>
      {editMode ? (
        <>
          <ProfileDetailNameWrapper editMode={editMode}>
            <ProfileDetailNameLabel editMode={editMode}>Nom :</ProfileDetailNameLabel>
            <StyledFormControl ismodal="false">
              <StyledInput
                error={!!errors.forename}
                istabletscreen={istabletscreen.toString()}
                inputRef={register}
                defaultValue={forename}
                id="forename"
                type="text"
                name="forename"
                placeholder="Nom de famille"
                disableUnderline
                fullWidth
              />
              {errors?.forename && <StyledErrorMessage>{errors.forename.message}</StyledErrorMessage>}
            </StyledFormControl>
          </ProfileDetailNameWrapper>
          <ProfileDetailNameWrapper editMode={editMode}>
            <ProfileDetailNameLabel editMode={editMode}>Prénom :</ProfileDetailNameLabel>
            <StyledFormControl ismodal="false">
              <StyledInput
                error={!!errors.name}
                istabletscreen={istabletscreen.toString()}
                inputRef={register}
                defaultValue={name}
                id="name"
                type="text"
                name="name"
                placeholder="Prénom"
                disableUnderline
                fullWidth
              />
              {errors?.name && <StyledErrorMessage>{errors.name.message}</StyledErrorMessage>}
            </StyledFormControl>
          </ProfileDetailNameWrapper>
        </>
      ) : (
        <ProfileDetailNameWrapper editMode={editMode}>
          <ProfileDetailNameLabel isName={true} editMode={editMode}>{gender === 'M' ? 'M.' : 'Mme'}</ProfileDetailNameLabel>
          <ProfileDetailNameValue isName={true} editMode={editMode}>
            <ProfileDetailNameValueUppercase>{name || ''}</ProfileDetailNameValueUppercase>
            <span>{` ${forename || ''}`}</span>
          </ProfileDetailNameValue>
        </ProfileDetailNameWrapper>
      )}
      <ProfileDetailNameWrapper editMode={editMode}>
        <ProfileDetailNameLabel editMode={editMode}>Cabinet :</ProfileDetailNameLabel>
        {editMode ? (
          <StyledFormControl ismodal="false">
            <StyledInput
              istabletscreen={istabletscreen.toString()}
              error={!!errors.office}
              inputRef={register}
              defaultValue={office}
              id="office"
              type="text"
              name="office"
              placeholder="Cabinet"
              disableUnderline
              fullWidth
            />
            {errors?.office && <StyledErrorMessage>{errors.office.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailNameValue>
            {`${office || ''}`}
          </ProfileDetailNameValue>
        )}
      </ProfileDetailNameWrapper>
    </>
  );
}

Identity.propTypes = {
  editMode: PropTypes.bool.isRequired,
  gender: PropTypes.string,
  name: PropTypes.string,
  forename: PropTypes.string,
  office: PropTypes.string,
  register: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  errors: PropTypes.object,
};

Identity.defaultProps = {
  gender: 'M',
  name: null,
  forename: null,
  office: null,
  errors: null,
};

export default Identity;
