// packages
import { gql } from '@apollo/client';

/**

 * @params {object} user
 * @params {string} user.localisation
 * @params {string} user.type
 * @params {string} user.gender
 * @params {string} user.name
 * @params {string} user.birthdate
 * @params {string} user.forename
 * @params {string} user.email
 * @params {string} user.telephone
 * @params {string} user.postalCode
 * @params {string} user.address
 * @params {string} user.city
 * @params {string} user.office
 *
 * @return {string} userId uuidv4 id of user
 */

const REGISTER = gql`
mutation registerUser($user: NewRegisteringUser!){
  register(newUser: $user){
    email
  }
}
`;
/** SIGN_IN
 *
 * @params {string} email of user
 * @params {string} password of user
 *
 * @return {string} return a credential (token for to put on each header for request)
 *
 */
const SIGN_IN = gql`
  query login($email: String!, $password: String!) {
    generateCredentials(email: $email, password: $password) {
      id
      token
    }
  }
`;

/** RESET_PASSWORD
 *
 * @params {string} email of user
 *
 * @return {boolean} return a boolean if emailing for resetting was successfully sent or not
 *
 */
const RESET_PASSWORD = gql`
  mutation resetPassword($email: String!) {
    resetUserPassword(email: $email)
  }
`;

/** IS_VALIDATION_CODE_VALID
 *
 * @params {string} email of user
 * @params {string} validationCode of user
 *
 * @return {boolean} return a boolean if confirmation for CODE was successfull or not
 *
 */
const IS_VALIDATION_CODE_VALID = gql`
  query isCodeValidationValid($email: String!, $validationCode: String!) {
    isCodeValidationValid(email: $email, validationCode: $validationCode)
  }
`;

/** CONFIRM_RESET_PASSWORD
 *
 * @params {string} email of user
 * @params {Object} password object of user
 * @params {String} password.new
 * @params {String} password confirmation of user
 * @params {string} validationCode of user
 *
 * @return {boolean} return a boolean if confirmation for password was successfull or not
 *
 */
const CONFIRM_RESET_PASSWORD = gql`
  mutation confirmResetPassword($email: String!, $password: NewPassword!, $validationCode: String!) {
    confirmResetPassword(email: $email, password: $password, validationCode: $validationCode)
  }
`;

/** CHANGE_USER_PASSWORD
 *
 * @params {string} currentPassword current password of user
 * @params {Object} password object of user
 * @params {String} password.new
 * @params {String} password confirmation of user
 *
 * @return {boolean} return a boolean if confirmation for password was successfull or not
 *
 */
const CHANGE_USER_PASSWORD = gql`
  mutation changeUserPassword($password: NewPassword!, $currentPassword: String!) {
    changeUserPassword(password: $password, currentPassword: $currentPassword)
  }
`;

/** UPDATE_USER_AVATAR
 *
 * @params {string} userId uuidv4 of user
 * @params {File} newUserAvatar new image
 *
 * @return {File} return updated image
 *
 */
const UPDATE_USER_AVATAR = gql`
  mutation updateUserAvatar($userId: ID!, $newUserAvatar: Upload!) {
    updateUserAvatar(userId: $userId, newUserAvatar: $newUserAvatar) {
      id
      filename
      mimetype
      __typename
    }
  }
`;

/** GET_USER by Id
 *
 * @params {string} id of user
 *
 * @return {object} return all information related to user
 *
 */
const READ_USER = gql`
  query readUser($userId: ID!) {
    user(userId: $userId) {
      id
      address
      birthdate
      telephone
      avatar {
        id
        filename
        mimetype
        __typename
      }
      city
      email
      office
      forename
      gender
      # isActive
      localisation
      name
      postalCode
      printers {
        id
        name
        configuration {
          id
          name
          description
          configuration
        }
      }
      type
      email
      libraries {
        id
      }
    }
  }
`;

/** UPDATE_USER
 *
 * @params {string} userId uuidv4 id of user
 * @params {object} updateUser
 * @params {string} updateUser.localisation
 * @params {string} updateUser.type
 * @params {string} updateUser.gender
 * @params {string} updateUser.name
 * @params {string} updateUser.birthdate
 * @params {string} updateUser.forename
 * @params {string} updateUser.email
 * @params {string} updateUser.telephone
 * @params {string} updateUser.postalCode
 * @params {string} updateUser.address
 * @params {string} updateUser.city
 * @params {string} updateUser.office
 *
 * @return {object} return updatedUser
 *
 */

const UPDATE_USER = gql`
  mutation updateUser($userId: ID!, $updateUser: UpdateUser!) {
    updateUser(userId: $userId, updateUser: $updateUser) {
      id
      address
      birthdate
      telephone
      city
      email
      office
      forename
      gender
      # isActive
      localisation
      name
      postalCode
      # printers
      type
      email
    }
  }
`;

/** GET_USER by Id
 *
 * @params {string} id of user
 *
 * @return {bool} return true
 *
 */
const DELETE_USER_AVATAR = gql`
 mutation deleteUserAvatar($userId: ID!) {
  deleteUserAvatar(userId: $userId)
 }
`;

export {
  REGISTER,
  SIGN_IN,
  READ_USER,
  UPDATE_USER,
  RESET_PASSWORD,
  IS_VALIDATION_CODE_VALID,
  CONFIRM_RESET_PASSWORD,
  CHANGE_USER_PASSWORD,
  UPDATE_USER_AVATAR,
  DELETE_USER_AVATAR,
};
