/* eslint-disable no-case-declarations */
/* eslint-disable consistent-return */
// Packages
import { onError } from '@apollo/client/link/error';

// actions
import { userActions } from '../../actions';

// redux store
import store from '../../reducers';

// Configuration
import config from '../../config';

// Default token for first connection
const DEFAULT_TOKEN = config.api.defaultToken;

const errorLink = onError(({
  graphQLErrors, networkError, operation, forward,
}) => {
  const oldHeaders = operation.getContext().headers;
  if (graphQLErrors && graphQLErrors.length >= 1) {
    // eslint-disable-next-line no-console
    console.log({ graphQLErrors });
    graphQLErrors.forEach((graphQLError) => {
      // @TODO : need error codes from back-enf to rely on
      switch (graphQLError.message) {
        // Insufficient policies
        case 'Insufficient policies to grant permissions':
          // Modify the operation context with a new token
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${DEFAULT_TOKEN}`,
            },
          });
          // logout
          store.dispatch(userActions.logout());
          break;
          // User is unknown
        case 'Ressource User was not found':
          // Modify the operation context with a new token
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${DEFAULT_TOKEN}`,
            },
          });
          // logout
          store.dispatch(userActions.logout());
          break;

        default:
          // eslint-disable-next-line no-console
          console.error('error', graphQLError);
      }
    });
  }

  if (networkError) {
    // eslint-disable-next-line no-console
    console.log({ networkError });
    switch (networkError.statusCode) {
      // Apollo Server sets code to UNAUTHENTICATED
      // when an AuthenticationError is thrown in a resolver
      case 401:
        // Modify the operation context with a new token
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${DEFAULT_TOKEN}`,
          },
        });
        // logout
        store.dispatch(userActions.logout());
        break;

      // HttpUnauthorizedError: Invalid JWT (invalid signature)
      case 403:
        // Modify the operation context with a new token
        operation.setContext({
          headers: {
            ...oldHeaders,
            authorization: `Bearer ${DEFAULT_TOKEN}`,
          },
        });
        // logout
        store.dispatch(userActions.logout());
        break;

      default:
        // eslint-disable-next-line no-console
        console.error('error', networkError);
    }
  }
  // Retry the request, returning the new observable
  return forward(operation);
});

export default errorLink;
