// packages
import React from 'react';
import { useLocation } from 'react-router-dom';

// style
import {
  TopHeaderContainer,
  TopHeaderLogoButton,
  TopHeaderLogo,
  TopHeaderProfile,
} from './style';

// assets
import { sharedAssets } from '../../../assets';

function TopHeader() {
  const { pathname } = useLocation();
  return (
    <TopHeaderContainer>
      <TopHeaderLogoButton to="/" title="Home">
        <TopHeaderLogo src={sharedAssets.OcareLogo} alt="Ocare Home logo" />
      </TopHeaderLogoButton>
      {pathname !== '/profile' && (
        <TopHeaderLogoButton to="/profile" title="Profil">
          <TopHeaderProfile src={sharedAssets.profile} alt="profil" />
        </TopHeaderLogoButton>
      )}
    </TopHeaderContainer>
  );
}

export default TopHeader;
