// packages
import { gql } from '@apollo/client';

/** GET_LIBRARIES (pieceTypes without pieces) of user by userId
 *
 * @params {String} id of user
 *
 * @return {Object} return all libraries of user
 *
 */
const READ_ALL_LIBRARIES = gql`
  query readlibraries($userId: ID!) {
    libraries(userId: $userId) {
      id
      name
      createdAt
      pieceTypes {
        thumbnailPreview {
          id
          filename
          mimetype
          __typename
        }
        thumbnailSelected {
          id
          filename
          mimetype
          __typename
        }
        thumbnailUnSelected {
          id
          filename
          mimetype
          __typename
        }
        id
        name
      }
    }
  }
`;

/** GET_LIBRARY by libraryId
 *
 * @params {String} id of library
 *
 * @return {Object} return library according yo its id
 *
 */
const READ_LIBRARY_FROM_ID = gql`
  query readlibraryById($libraryId: ID!) {
    library(libraryId: $libraryId) {
      id
      name
      createdAt
      pieceTypes {
        thumbnailPreview {
          id
          filename
          mimetype
          __typename
        }
        thumbnailSelected {
          id
          filename
          mimetype
          __typename
        }
        thumbnailUnSelected {
          id
          filename
          mimetype
          __typename
        }
        id
        name
      }
    }
  }
`;

/** Get pieces from pieceTypeId and filter search
 *
 * @params {String} pieceTypeId
 * @param {Object} filter object search filter
 * @param {number} filter.thickness
 * @param {number} filter.footSize
 * @param {string} filter.footSide
 *
 * @return {Array} Found pieces

 *
 */
const READ_PIECES_FROM_PIECETYPE_ID = gql`
  query getPiecesByPieceTypeId($pieceTypeId: ID!, $filter: PieceFilter) {
    pieces: getPiecesByPieceTypeId(pieceTypeId: $pieceTypeId, filter: $filter) {
      ...on PieceSVG {
        id
        createdAt
        lastUpdate
        footSide
        footSize
        thickness
        svg
          options {
          shore
          orientation
            bevel {
            size
            segment
            thickness
          }
          thickness
        }
      }
      ...on PieceSTL {
        id
        createdAt
        lastUpdate
        footSide
        footSize
        thickness
          file {
          id
          filename
          mimetype
          size
          __typename
        }
      }
    }
  }
`;

const EXPORT_FROM_LIBRARY = gql`
  query exportGcode($pieceIds: PieceSpecs!) {
    exportGcode(pieceIds: $pieceIds)
  }
`;

// eslint-disable-next-line import/prefer-default-export
export {
  READ_ALL_LIBRARIES, READ_LIBRARY_FROM_ID, READ_PIECES_FROM_PIECETYPE_ID, EXPORT_FROM_LIBRARY,
};
