// packages
import { createSlice } from '@reduxjs/toolkit';

// actionCreators
import { userActionCreators } from './userReducer';

const initialState = {
  modal: null,
  modalTool: null,
  components: [],
  selectedComponent: null,
  activeTool: null,
};

const cadSlice = createSlice({
  name: 'cad',
  initialState,
  reducers: {
    // RESET
    RESET: () => ({
      modal: null,
      modalTool: null,
      components: [],
      selectedComponent: null,
      activeTool: null,
    }),
    // UPDATE_COMPONENTS
    UPDATE_COMPONENTS: (state, action) => ({
      ...state,
      components: action.payload,
    }),
    // UPDATE_SELECTED_COMPONENT
    UPDATE_SELECTED_COMPONENT: (state, action) => ({
      ...state,
      selectedComponent: action.payload,
    }),
    // SAVE_TEMPORARY_SOLES
    SAVE_TEMPORARY_SOLES: (state, action) => ({
      ...state,
      tmpSoles: action.payload,
    }),
    // CAD_MODAL
    TOGGLE_MODAL: (state, action) => ({
      ...state,
      modal: {
        name: action.payload,
      },
    }),
    TOGGLE_MODAL_TOOL: (state, action) => ({
      ...state,
      modalTool: {
        name: action.payload,
      },
    }),
  },
  extraReducers: {
    // LOGOUT
    [userActionCreators.logout]: () => initialState,
  },
});

export const cadActionCreators = cadSlice.actions;
export default cadSlice.reducer;
