// eslint-disable-next-line import/no-extraneous-dependencies
// packages
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';

// validationSchema
import validationSchema from './validationSchema';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';
import { userActionCreators } from '../../../reducers/userReducer';

// services
import { SIGN_IN } from '../../../services/userServices';

// style
import { LoginContainer, LoginTitle, AuthLink } from './style';

// shared
import { colors } from '../../../shared/styles';
import { CustomInput, CustomButton } from '../../../shared/components';

function Login() {
  const {
    StyledLabel,
    StyledInput,
    StyledErrorMessage,
    StyledFormControl,
    StyledInputPassword,
  } = CustomInput;

  // hooks
  const dispatch = useDispatch();
  const apolloClient = useApolloClient();
  const {
    register,
    handleSubmit,
    errors,
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  // Ensure client and cache are clear for possible previous session
  useEffect(() => {
    apolloClient.clearStore();
    localStorage.clear();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [
    login,
    { loading, error },
  ] = useLazyQuery(
    SIGN_IN, {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        localStorage.setItem('@credentials', data.generateCredentials.token);
        localStorage.setItem('@credentialsID', data.generateCredentials.id);
        dispatch(userActionCreators.loginSuccess());
      },
    },
  );

  // onSubmit
  function onSubmit(request, evt) {
    evt.preventDefault();
    dispatch(requestActionCreators.clean());
    login({ variables: request });
  }

  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    }
  }, [dispatch, error]);

  useEffect(() => {
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading]);

  return (
    <LoginContainer className="auth_Child" onSubmit={handleSubmit((r, event) => onSubmit(r, event))}>
      <LoginTitle>Connexion</LoginTitle>
      <StyledFormControl>
        <StyledLabel htmlFor="email">Identifiant</StyledLabel>
        <StyledInput
          error={!!errors.email}
          inputRef={register}
          id="email"
          type="email"
          name="email"
          placeholder="E-mail"
          disableUnderline
          fullWidth
        />
        {errors?.email && <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>}
      </StyledFormControl>
      <StyledFormControl>
        <StyledLabel htmlFor="password">Mot de passe</StyledLabel>
        <StyledInputPassword
          error={!!errors.password}
          inputRef={register}
          id="password"
          name="password"
          defaultValue=""
          placeholder="Mot de passe"
        />
        {errors?.password && <StyledErrorMessage>{errors.password.message}</StyledErrorMessage>}
      </StyledFormControl>
      <AuthLink to="/resetPassword">
        Mot de passe oublié ?
      </AuthLink>
      <CustomButton
        primarycolor={colors.BUTTONBLUE}
        secondarycolor={colors.WHITE}
        type="submit"
      >
        Connexion
      </CustomButton>
    </LoginContainer>
  );
}

export default Login;
