// packages
import React from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useLocation,
} from 'react-router-dom';

// components
import LibrariesList from './LibrariesList';
import LibraryList from './LibraryList';
// import SelectedPieceTypesToConfig from './SelectedPieceTypesToConfig';
import Configuration from './Configuration';

// style
import {
  LibraryContainer,
  LibraryTitleBackgroundWrapper,
  LibraryTitleWrapper,
  LibraryTitleBackground,
  LibraryExplanations,
} from './style';

// assets
import { MaterialLibraryAssets } from '../../assets';

function Library() {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <LibraryContainer>
      <LibraryTitleBackgroundWrapper style={{ backgroundImage: `url(${MaterialLibraryAssets.LibraryBackground})` }}>
        <LibraryTitleWrapper>
          <LibraryTitleBackground>BIBLIOTHÈQUE</LibraryTitleBackground>
          <LibraryExplanations>
            {!pathname.includes('configuration') ? 'Retrouver tout le catalogue d\'éléments.' : 'Configuration de vos éléments'}
          </LibraryExplanations>
        </LibraryTitleWrapper>
      </LibraryTitleBackgroundWrapper>
      <Switch>
        <Route exact path={path}>
          <LibrariesList />
        </Route>
        <Route exact path={`${path}/:libraryId`}>
          <LibraryList />
        </Route>
        {/* Multiple selection and configuration: feature put it in on hold */
          /* <Route exact path={`${path}/:libraryId/selection`}>
          <SelectedPieceTypesToConfig />
        </Route> */}
        <Route exact path={`${path}/:libraryId/configuration/:pieceTypeId`}>
          <Configuration />
        </Route>
      </Switch>
    </LibraryContainer>
  );
}

export default Library;
