import * as yup from 'yup';

export default yup.object().shape({
  localisation: yup.string(),
  type: yup.string(),
  gender: yup.string(),
  name: yup.string().required('Champs requis'),
  forename: yup.string().required('Champs requis'),
  email: yup.string().email('Adresse email non valide').required('Champs requis'),
  birthdate: yup.string(),
  telephone: yup.string().matches(/^([0-9]{10})$/, { message: 'Le numéro de téléphone doit contenir 10 chiffres', excludeEmptyString: true }),
  postalCode: yup.string(),
  address: yup.string(),
  city: yup.string(),
  office: yup.string(),
});
