/* eslint-disable react/no-array-index-key */
// packages
import React from 'react';
import PropTypes from 'prop-types';

// components
import { CustomSelect } from '../../../shared/components';

// helpers
import displayFrTranslation from './helpers/displayFrTranslation';

function ConfigurationSelect(
  {
    value = '',
    name,
    onChange,
    options = [],
    label,
    isDisabled = false,
    availableOptions = [],
  },
) {
  const { displayFrFootSideOption, displayFrFootSizeOption } = displayFrTranslation;
  const displayFrOption = (nameSelection, opt) => {
    let frOpt;

    switch (nameSelection) {
      case 'footSide':
        frOpt = displayFrFootSideOption(opt);
        break;
      case 'footSize':
        frOpt = displayFrFootSizeOption(opt);
        break;
      default:
        frOpt = opt;
        break;
    }

    return frOpt;
  };

  return (
    <CustomSelect
      value={value}
      name={name}
      options={options}
      onChange={onChange}
      label={label}
      isDisabled={isDisabled}
      displayFrOption={displayFrOption}
      availableOptions={availableOptions}
    />
  );
}

ConfigurationSelect.propTypes = {
  isDisabled: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  options: PropTypes.array,
  label: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  availableOptions: PropTypes.array,
};

ConfigurationSelect.defaultProps = {
  isDisabled: false,
  label: null,
  options: [],
  availableOptions: [],
};

export default ConfigurationSelect;
