// package
import styled from 'styled-components';
import ListItem from '@material-ui/core/ListItem';

// colors
import { colors } from '../../../../shared/styles';

const PieceTypeContainer = styled(ListItem)({
  '&.MuiListItem-root': {
    width: (props) => (props.ismobilescreen === 'true' ? '8rem' : '10rem'),
    height: (props) => (props.ismobilescreen === 'true' ? '7rem' : '9.5rem'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '5px',
    position: 'relative',
    cursor: 'pointer',
    margin: '0',
    flexWrap: 'wrap',
    background: '0% 0% no-repeat padding-box',
    boxShadow: `0px 3px 6px ${colors.SHADOW}`,
    '&:hover': {
      boxShadow: `0px 3px 6px ${colors.TEXTGREY}`,
      transform: 'scale(1.05)',
    },
    '&.Mui-selected': {
      backgroundColor: `${colors.BUTTONBLUE}`,
      '&:hover': {
        backgroundColor: `${colors.BUTTONBLUE}`,
      },
    },
  },
});

const PieceTypeName = styled.p`
  display: block;
  padding: 0 10px;
  font: normal normal 700, sans-serif;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0px;
  width: 100%;
  text-align: center;
  color: ${(props) => (props.isSelected ? colors.WHITE : colors.TEXTGREY)};
`;

const PieceTypeImg = styled.img`
  height: 80px;
  width: 80px;

  @media screen and (max-width: 600px) {
    height: 41px;
    width: 45px;
  }
`;

export { PieceTypeContainer, PieceTypeName, PieceTypeImg };
