/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
// packages
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch } from 'react-redux';
import { useMutation } from '@apollo/client';

// services
import { UPDATE_USER_AVATAR, DELETE_USER_AVATAR } from '../../../../services/userServices';

// action creators
import { requestActionCreators } from '../../../../reducers/requestReducer';

// components
import { ModalConfirmation } from '../../../../shared/components';
import AvatarUploadForm from './AvatarUploadForm';

// assets
import { ProfileAssets } from '../../../../assets';

// style
import {
  ProfileAvatarWrapper,
  ProfileAvatar,
  EditProfileAvatarWrapper,
  EditAvatarButton,
  EditAvatarIcon,
} from '../style';

function Avatar({ userId, avatar, refetchUser = () => null }) {
  // local state
  const [showModal, setShowModal] = useState(false);
  const [avatarFile, setAvatarFile] = useState(null);
  const isMobileScreen = useMediaQuery('(max-width: 900px)');
  // hooks
  const dispatch = useDispatch();

  // mutation graphQL
  const [
    updateUserAvatar,
    { loading, error: uploadError },
  ] = useMutation(
    UPDATE_USER_AVATAR, {
      fetchPolicy: 'no-cache',
      onCompleted: (data) => {
        if (data) {
          setShowModal(false);
          dispatch(requestActionCreators.success(['Votre photo de profil a bien été modifiée !']));
          refetchUser();
        }
      },
    },
  );

  const [
    deleteUserAvatar,
    { error: deletionError },
  ] = useMutation(
    DELETE_USER_AVATAR, {
      fetchPolicy: 'no-cache',
      onCompleted: (bool) => {
        if (bool) {
          setShowModal(false);
          dispatch(requestActionCreators.success(['Votre photo de profil a bien été supprimée !']));
          refetchUser();
        }
      },
    },
  );

  const onSubmit = async (e) => {
    e.preventDefault();
    await updateUserAvatar({ variables: { userId, newUserAvatar: avatarFile } });
  };

  const onDelete = async (e) => {
    e.preventDefault();
    await deleteUserAvatar({ variables: { userId } });
  };

  useEffect(() => {
    if (uploadError) {
      setAvatarFile(null);
      dispatch(requestActionCreators.failure(uploadError));
    }
    if (deletionError) {
      dispatch(requestActionCreators.failure(deletionError));
    }
    if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, uploadError, deletionError]);
  return (
    <>
      <ModalConfirmation
        actionClose={() => setShowModal(false)}
        onSubmit={(event) => onSubmit(event)}
        show={showModal}
        titleAction="Enregister"
        disabled={!avatarFile}
      >
        <AvatarUploadForm
          setAvatarFile={setAvatarFile}
          onDelete={(event) => onDelete(event)}
          avatarFile={avatarFile ? URL.createObjectURL(avatarFile) : null}
          avatar={avatar || ProfileAssets.avatarProfileDefault}
          defaultAvatar={ProfileAssets.avatarProfileDefault}
        />
      </ModalConfirmation>
      <EditProfileAvatarWrapper>
        <EditAvatarButton
          ismobilescreen={isMobileScreen.toString()}
          onClick={() => setShowModal((prev) => !prev)}
        >
          <EditAvatarIcon src={ProfileAssets.edit} />
        </EditAvatarButton>
        <ProfileAvatarWrapper>
          <ProfileAvatar src={avatar || ProfileAssets.avatarProfileDefault} alt="avatar image" />
        </ProfileAvatarWrapper>
      </EditProfileAvatarWrapper>
    </>
  );
}

Avatar.propTypes = {
  refetchUser: PropTypes.func,
  userId: PropTypes.string.isRequired,
  avatar: PropTypes.string,
};

Avatar.defaultProps = {
  avatar: null,
  refetchUser: () => null,
};

export default Avatar;
