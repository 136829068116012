// packages
import React from 'react';
import PropTypes from 'prop-types';

// packages
import Button from '@material-ui/core/Button';
import { styled } from '@material-ui/core/styles';
import { colors, animations } from '../../styles';

const StyledButton = styled(Button)({
  border: '2px solid',
  borderColor: `${colors.BUTTONBLUE}`,
  textTransform: 'none',
  flexShrink: 2,
  margin: '0 5px',
  position: (props) => (props.ispositionfixed === 'true' ? 'fixed' : 'static'),
  top: (props) => (props.ispositionfixed === 'true' && 'calc(100vh - 93px)'),
  boxShadow: '0px 3px 6px #0000006F',
  width: (props) => (props.isprofile === 'true' ? '200px' : 'auto'),
  padding: (props) => ((props.isprofile === 'true' && props.ismobilescreen === 'false') ? '5px 50px' : '14px 36px'),
  borderRadius: '7px',
  backgroundColor: (props) => props.primarycolor,
  color: (props) => props.secondarycolor,

  '&.MuiButtonBase-root': {
    cursor: 'pointer',
    pointerEvents: 'auto',

    '&.Mui-disabled': {
      border: `2px solid ${colors.LIGHTGRAY}`,
      backgroundColor: `${colors.LIGHTGRAY}`,
      color: `${colors.WHITE}`,
      cursor: 'not-allowed',
      opacity: '0.7',
      '&:hover': {
        color: `${colors.WHITE}`,
        backgroundColor: `${colors.LIGHTGRAY}`,
      },
    },
  },
  '&.MuiButton-root': {
    fontWeight: '700',
    transition: `${animations.componentTransition}`,
    fontSize: '19px',
    lineHeight: '25px',
    color: (props) => props.secondarycolor,
    '&:hover': {
      color: (props) => props.primarycolor,
      backgroundColor: (props) => props.secondarycolor,
    },
  },
});

function CustomButton(
  {
    children,
    type = 'button',
    onClick,
    isDisabled = false,
    isPositionFixed = false,
    isprofile = false,
    isMobileScreen = false,
    primarycolor,
    secondarycolor,
  },
) {
  return (
    <StyledButton
      primarycolor={primarycolor}
      secondarycolor={secondarycolor}
      isprofile={isprofile.toString()}
      ismobilescreen={isMobileScreen.toString()}
      ispositionfixed={isPositionFixed.toString()}
      type={type}
      onClick={onClick}
      disabled={isDisabled}
    >
      { children }
    </StyledButton>
  );
}

CustomButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]).isRequired,
  type: PropTypes.string.isRequired,
  primarycolor: PropTypes.string,
  secondarycolor: PropTypes.string,
  isprofile: PropTypes.bool,
  isMobileScreen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isPositionFixed: PropTypes.bool,
  onClick: PropTypes.func,
};

CustomButton.defaultProps = {
  isDisabled: false,
  isPositionFixed: false,
  isMobileScreen: false,
  primarycolor: `${colors.WHITE}`,
  secondarycolor: `${colors.BUTTONBLUE}`,
  isprofile: false,
  onClick: () => {},
};

export default CustomButton;
