// components
import styled from 'styled-components';

// styles
import { colors } from '../../../../shared/styles';

const PrintListContainer = styled.div`
  width: 100%;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const PrintListTitle = styled.h2`
  width: 100%;
  text-align: left;
  font: normal normal normal 36px/48px Roboto;
  letter-spacing: 0px;
  color: ${colors.SECTION_TITLE};

  @media screen and (max-width: 900px) {
    font-size: 21px;
  }
`;

const PieceToPrintContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-wrap: nowrap;
  gap: 30px;
`;

const PieceDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PieceOverview = styled.img`
  width: 194px;
  height: 191px;
  box-shadow: 0px 3px 6px #00000031;
  border-radius: 19px;

  @media screen and (max-width: 900px) {
    height: 100px;
    width: 100px;
  
  @media screen and (max-width: 500px) {
    height: 50px;
    width: 50px;
  }
`;

const PieceDescriptionTitle = styled.span`
  text-align: left;
  font: normal normal normal 28px/37px Roboto;
  letter-spacing: 0px;
  color: #424957;
  margin-left: -25px;

  @media screen and (max-width: 900px) {
    font-size: 13px;
  }
`;

const PieceDescriptionWrapper = styled.div`
  display: flex;
`;

const PieceDescription = styled.span`
  text-align: left;
  font: normal normal normal 20px/26px Roboto;
  letter-spacing: 0px;
  color: #A7A7A7;
  margin-right: 5px;

  @media screen and (max-width: 900px) {
    font-size: 9px;
  }
`;

const PieceNbCounter = styled.span`
  text-align: left;
  font: normal normal normal 37px/49px Roboto;
  letter-spacing: 0px;
  color: ${colors.SECTION_TITLE};

  @media screen and (max-width: 900px) {
    font-size: 24px;
  }

  &::before {
    content: 'x';
    font-size: 1rem;
  }
`;

export {
  PrintListContainer,
  PrintListTitle,
  PieceToPrintContainer,
  PieceDescriptionContainer,
  PieceOverview,
  PieceDescriptionTitle,
  PieceDescriptionWrapper,
  PieceDescription,
  PieceNbCounter,
};
