/* eslint-disable no-return-assign */
// packages
import { createSlice } from '@reduxjs/toolkit';

// actionCreators
import { userActionCreators } from './userReducer';

// helpers
import { handleError } from '../shared/helpers';

const initialState = {
  isLoading: false,
  error: null,
  success: null,
};

const requestSlice = createSlice({
  name: 'request',
  initialState,
  reducers: {
    onGoing: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    failure: (state, action) => ({
      isLoading: false,
      error: handleError(action.payload),
      success: null,
    }),
    success: (state, action) => ({
      isLoading: false,
      error: null,
      success: action.payload,
    }),
    clean: () => ({
      isLoading: false,
      error: null,
      success: null,
    }),
  },
  extraReducers: {
    [userActionCreators.logout]: () => initialState,
  },
});

export const {
  read, onGoing, failure, success,
} = requestSlice.actions;
export const requestActionCreators = requestSlice.actions;
export default requestSlice.reducer;
