/* eslint-disable no-return-assign */
// packages
import { createSlice } from '@reduxjs/toolkit';

// actionCreators
import { userActionCreators } from './userReducer';

const initialState = {
  selectedPieceTypeToConfig: [],
  selectedPieceTypeIdToConfig: [],
  selectedPiecesToPrint: [],
};

const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    reset: () => initialState,
    selectPieceTypeIdToConfig: (state, action) => ({
      ...state,
      selectedPieceTypeIdToConfig: [...action.payload],
    }),
    selectPieceTypeToConfig: (state, action) => ({
      ...state,
      selectedPieceTypeToConfig: action.payload,
    }),
    selectPiecesToPrint: (state, action) => ({
      ...state,
      selectedPiecesToConfig: [...state.selectedPiecesToConfig, ...action.payload],
    }),
  },
  extraReducers: {
    [userActionCreators.logout]: () => initialState,
  },
});

export const {
  read, onGoing, failure, success,
} = librarySlice.actions;
export const libraryActionCreators = librarySlice.actions;
export default librarySlice.reducer;
