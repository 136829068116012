const page = [
  {
    label: 'Genre',
    id: 'gender',
    type: 'radio',
    choices: [
      {
        label: 'Monsieur',
        value: 'M',
      },
      {
        label: 'Madame',
        value: 'F',
      },
      {
        label: 'Ne pas préciser',
        value: '',
      },
    ],
  },
  {
    label: 'Prénom',
    id: 'forename',
    type: 'text',
  },
  {
    label: 'Nom',
    id: 'name',
    type: 'text',
  },
  {
    label: 'Numéro de téléphone',
    id: 'telephone',
    type: 'phone',
  },
  {
    label: 'Email',
    id: 'email',
    type: 'mail',
  },
  {
    label: 'Mot de passe',
    id: 'password',
    type: 'password',
  },
  {
    label: 'Confirmation de mot de passe',
    id: 'confirmPassword',
    type: 'password',
  },
  {
    label: 'Adresse',
    id: 'address',
    type: 'text',
    fullWidth: true,
  },
  {
    label: 'Ville',
    id: 'city',
    type: 'text',
  },
  {
    label: 'Code Postal',
    id: 'postalCode',
    type: 'text',
  },
  {
    type: 'captcha',
  },
];

export default page;
