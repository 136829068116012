const colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  BLACKTITLE: '#3E4554',
  SECTION_TITLE: '#424957',
  SECTION_TITLE_H3: '#3E4555',
  SECTION_CONTENT: '#707070',
  SECTION_VALUE: '#8A96AF',
  BLACKINPUT: '#0B0B0F',
  SHADOW: '#00000029',
  RED: 'red',
  RED_TOASTER: '#FF5E5E',
  BLUE: '#45aaf2',
  BLUE_ICON: 'rgb(69,170,242, 0.55)',
  RED_COUNTDOWN: '#FF657A',
  ORANGE: '#FFA3A0',
  ORANGE_ICON: 'rgb(254,164,102, 0.55)',

  APPBACKGROUNDCOLOR: '#F3F3F3',
  YELLOW: '#F7DB42',
  GREEN: '#67DB8A',
  GREEN_TOASTER: '#33D2A2',
  GREEN_PROFILE: '#4BD6B9',
  GREENFLUO: '#3AD29F',
  MAINBLUE: '#1F7CE6',
  BUTTONBLUE: '#41AAEF',
  LIGHTBLUEGRAY: '#F5F5F5',
  TEXTGREY: '#535353',
  LIGHTGRAY: '#B9B8B5',
  TEXTGREYINFO: '#C3C3C3',
  DARKGRAY: '#929292',
};

export default colors;
