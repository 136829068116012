export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAAB4AAAATCAYAAACHrr18AAAACXBIWXMAAADdAAAA3QFwU6IHAAAA
GXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAgNJREFUSImd1ktvTVEUB/DfVWki
JWpkgpFowtCbjjTEBFENCQOPUEn5AHwGAx9AhZoQiZCWFDNh0gQTr4EiKZ2JhmgHfajBWTd39/T0
9tz+k52cs9Ze//9+rb12RTmswiG0YxvWYU34xvADr/ESA/hbkndBtKEPE5gt2SYipm0pgq24gekC
4j/hu4QHdQYwHf1ay4rux2hC8Cv5foG10a8S4kWiacwoOhYTvYipCPiMg3gf/x+wMoTv4ned2b6L
2OH4n0T3QqKXk8B+rMbuxHYYzXhbRzBtO4OjP7H15EWPYSacvbJlhHNhmwrRIyVFZ3E2OJbhZthm
0FkV3SBLiVncQ1MyoKtqJ3UFrjUgfCXhacL9sI+Fpidh+IiW3EpcSIh+4l8DwudzXC34FL7H1HK0
y3xsaUAo3zYX8HWFb1yoLzRjGFqC6FABTzrjAebu8UNz9xj2yNKhrOgkduU4luORWo6vrzo61U51
X3RMcUbxLVZ0W50uEL2jdqqP5peiJyF4av5VdwDf6oh+ld16KVrxLOkzL4+r6FZb1pEQS9GME7K8
fB6tF8fDlx/oiBI3VxUdsjJXHeUg9i4WFKjISudgEj+KfSXjC6vTMK7jJLZjY7QdOBW+L+bud68G
qlOKTbgly7uyp3octy1Sjyv1nAlaZEWiHVtl6ZC+QL7jDV4p+QL5D4DpGqjT/6/8AAAAAElFTkSu
QmCC`;
