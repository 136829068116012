// packages
import { matchPath } from 'react-router-dom';

/**
 * Can Verify if :
 ************- path of one route is the same as the option
 ************- for each path of several route are the same as the option
 *
 * @param {array || string} array of path or one path
 * @return {boolean} return true / false
 */
const checkIfRouteMatched = (pathToCheck, options) => {
  let IsMatched;
  if (Array.isArray(pathToCheck)) {
    IsMatched = pathToCheck.find((path) => matchPath(path, options));
  } else {
    IsMatched = matchPath(pathToCheck, options);
  }
  return IsMatched || null;
};

export default { checkIfRouteMatched };
