// packages
import React from 'react';
import { useSelector } from 'react-redux';
import SpinLoader from 'react-loader-spinner';

// components
import styled from 'styled-components';

// styles
import { colors } from '../../styles';

const LoaderContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100vw;
  background-color: ${colors.WHITE};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999999999999;
  ${(props) => (
    !props.show && 'display: none;'
  )};
`;

function Loader() {
  // redux
  const isLoading = useSelector((state) => state.request.isLoading);
  return (
    <LoaderContainer show={isLoading}>
      <SpinLoader
        type="ThreeDots"
        color={colors.BUTTONBLUE}
        height={100}
        width={100}
      />
    </LoaderContainer>
  );
}

export default Loader;
