// packages
import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';
import PropTypes from 'prop-types';

// services
import { READ_LIBRARY_FROM_ID } from '../../../services/libraryServices';

// action creators
import { requestActionCreators } from '../../../reducers/requestReducer';

// reducers
import { libraryActionCreators } from '../../../reducers/libraryReducer';

// components
import PieceType from './PieceType';

// style
import { ListPieceTypesContainer } from './style';

function ListPieceTypes({ selectedPiecesNumber, setselectedPiecesNumber, selectedPieceTypeIdToConfig }) {
  // Hooks
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { data, loading, error } = useQuery(
    READ_LIBRARY_FROM_ID, {
      variables: { libraryId: params.libraryId },
      fetchPolicy: 'no-cache',
    },
  );

  // handle selection of pieces in an array to configure and print
  const handleSelection = (pieceTypeId) => () => {
    const currentIndex = selectedPieceTypeIdToConfig.indexOf(pieceTypeId);
    let newChecked = [...selectedPieceTypeIdToConfig];

    // TODO change for maxPieceType variable
    if (currentIndex === -1 && selectedPiecesNumber < 1) {
      newChecked.push(pieceTypeId);
      setselectedPiecesNumber((prev) => prev + 1);
    } else if (currentIndex === -1 && selectedPiecesNumber === 1) {
      newChecked = [pieceTypeId];
    } else {
      newChecked.splice(currentIndex, 1);
      setselectedPiecesNumber((prev) => prev - 1);
    }

    dispatch(libraryActionCreators.selectPieceTypeIdToConfig(newChecked));
  };

  // if no libraryId in URL params
  useEffect(() => {
    if (!params.libraryId) {
      history.push('/bibliotheques');
    }
  }, [history, params]);

  // if error || loading
  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    } else if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);
  return (
    (data && data.library) ? (
      <ListPieceTypesContainer>
        {data.library.pieceTypes.map((pieceType) => (
          <PieceType
            key={pieceType.id}
            pieceType={pieceType}
            isSelected={selectedPieceTypeIdToConfig.indexOf(pieceType.id) !== -1}
            onClick={() => handleSelection(pieceType.id)}
          />
        ))}
      </ListPieceTypesContainer>

    ) : 'Cette liste est vide'
  );
}

ListPieceTypes.propTypes = {
  selectedPiecesNumber: PropTypes.number.isRequired,
  selectedPieceTypeIdToConfig: PropTypes.arrayOf(PropTypes.string).isRequired,
  setselectedPiecesNumber: PropTypes.func.isRequired,
};

export default ListPieceTypes;
