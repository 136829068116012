/* eslint-disable import/prefer-default-export */
// packages
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// shared
import { colors } from '../../../shared/styles';

const HomePageContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const HomePageTitleBackground = styled.div`
  margin: 0;
  padding: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  max-height: 350px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.WHITE};
  align-items: center;
  box-shadow: 0px 3px 6px #00000029;

  @media screen and (max-width: 900px) {
    height: 200px;
  }
`;

const HomePageTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  max-width: 616px;
  padding: 10px;

  @media screen and (max-width: 900px) {
    max-width: 200px;
  }
`;

const HomePageTitle = styled.h1`
  text-align: left;
  display: block;
  font-weight: medium;
  font-size: 80px;
  line-height: 90px;
  letter-spacing: 0;
  color: ${colors.SECTION_TITLE};
  text-transform: uppercase;

  @media screen and (max-width: 900px) {
    font-size: 25px;
    line-height: 33px;
  }
`;

const HomePageExplanations = styled.p`
  text-align: left;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  letter-spacing: 0;
  color: ${colors.SECTION_TITLE};
  opacity: 1;

  @media screen and (max-width: 900px) {
    font-size: 11px;
    line-height: 20px;
  }
`;

const MenuContainer = styled.div`
  margin: 15px 0 0;
  width: 100%;
  height: 100%;
  max-height: 500px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:"library cad""library cad";
  gap: 15px;
  grid-gap: 15px;

  @media screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:"library""cad";
  }
`;

const SectionButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 169px;
  height: 57px;
  min-width: 169px;
  min-height: 57px;
  background: ${colors.WHITE} 0% 0% no-repeat padding-box;
  border: 1px solid ${colors.BLUE};
  color: ${colors.BLUE};
  border-radius: 9px;
  align-self: flex-end;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: ${colors.BLUE};
    border: 1px solid ${colors.WHITE};
    color: ${colors.WHITE};
  }
`;

const TopicSection = styled.section`
  margin: 0;
  padding: 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-decoration: none;
  background-color: ${colors.WHITE};
  box-shadow: 0px 3px 6px #00000029;
  min-width: 40%;

  div.section_wrapper_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    h3.section_title {
      margin: 0;
      text-align: left;
      font-size: 45px;
      line-height: 60px;
      font-weight: 500;
      letter-spacing: 0px;
      color: ${colors.SECTION_TITLE_H3};
      text-transform: uppercase;
    }

  }
  
  p.section_info {
    width: 100%;
    text-align: left;
    font-size: 25px;
    line-height: 40px;
    font-weight: normal;
    letter-spacing: 0px;
    color: ${colors.SECTION_CONTENT};
  }

  @media screen and (max-width: 600px) {
    padding: 10px 8px;
    div.section_wrapper_title > h3.section_title  {
        font-size: 20px;
        line-height: 30px;
    }
    
    p.section_info {
      font-size: 13px;
      line-height: 20px;
    }
  }
`;

const LibrarySection = styled(TopicSection)`
  grid-area: library;
`;

const CadSection = styled(TopicSection)`
  grid-area: cad;
`;

const TopicIcon = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const TopicIconWrapper = styled.div`
  width: 95px;
  height: 95px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 50px;
    height: 50px;
  }
`;

export {
  HomePageContainer,
  MenuContainer,
  HomePageTitleBackground,
  HomePageTitleWrapper,
  HomePageTitle,
  HomePageExplanations,
  LibrarySection,
  SectionButton,
  CadSection,
  TopicIcon,
  TopicIconWrapper,
};
