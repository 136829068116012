// packages
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isConnected: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logout: () => initialState,
    loginSuccess: (state) => ({
      ...state,
      isConnected: true,
    }),
    read: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    // signup: (state, action) => ({
    //   ...state,
    //   email: action.payload.email,
    // }),
  },
});

export const userActionCreators = userSlice.actions;
export default userSlice.reducer;
