/**
 * Filter and return a list whom elements have the filter as key
 *
 * @param {array} array of elements
 * @param {string} key to filter
 * @return {Array.<string>} return new array
 */
const cleanAvailablePieces = (availablePieces, filter) => {
  let cleanedPieces = [];
  if (!availablePieces || !filter) {
    cleanedPieces = [...availablePieces];
  } else {
    cleanedPieces = availablePieces.map((piece) => piece[filter]);
  }
  return cleanedPieces;
};

export default cleanAvailablePieces;
