export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABgAAAArCAYAAABimUj7AAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAbASURB
VFiFrZdbTFvJHYd/NpCc8ZyLMXCMc2GNnWVtLokDKlJDgFSRNs95jNS+YJEXViuhKGq7ap+yl6co
T0FakJCqbaU+NEKyslGqSG0gcVuhCMkxMQYSNpEiXxJsc3x8fLAdM30IBxnCxVn293g8833znzkz
cwxUF5skSVMWiyVvsVjykiRNAbBV2ffAfEIpzQBgJpOJmUwmBoBtPvvksHAnpXQNAPN6vSwSibBI
JMK8Xq8hWQPgPPTIvV4vW11dZUYymQzr6upiABghJAvA/YvBKyWnT5+ulJyqFu7keV4BwNrb21kq
lfoAbmRtbW1LYrFY1GoknxJCVACso6NjX3il5MyZM0YlGoD2veBtlfB0On0g3IiiKMzn8zEAjOO4
/G6SzwghOQDM5/MxRVGqhhvJ5XKst7e3UtJhwI9xHKcBYD09PSybzX403Eg2m2U9PT2GRAPggCRJ
/zLgqqr+bLgRVVW3JJIkPQDHcQUA7Pnz54eGG1laWjKqWDfX1NSUACCZTFb1DleTN2/eAABqa2tL
4Hn+ewDMbrez5eXlQ49+eXmZ2e12BoDxPD8GAEQQhOcAmCzLbGlp6VBTI8syA8A2mcSoShIEYREA
s9ls7NmzZx8NX1xcZE1NTcYh+BOA+p1TR0VRXDAk8/PzVcOj0ShrbGxkAJgoii8AiLutjZVS+jdK
qQKA1dfXs3A4/FFwi8WiUkr/jl0uIyul9C8AAi0tLT96vd6qJAsLC6yhoYEBYG1tbarT6bwHIEAp
/SuAhq2p2XwQaG1tDcTj8YCiKD92dXVtSZ4+fbov3Ov1qqlU6l48Hg+0trYGNiU/AKAQBOGPAAIu
lyuQSCQCjLEAY2ybxGq1slAotAWPRCLMZrMxvD/Ss+l0+p7RL5FIBFwuVwBAQBCE35sIIf/Qdf1I
OBxGZ2fntnnLZDJ1AwMDv5mfnz8qSRJu3boFABgdHYWiKOjs7CzMzMz8u76+vlTZLxQKwefzwWKx
FM27rTYAlMtlczwed46NjSV9Pl9RURT4/X74/X4oigKfz1ccGxtLxmIxV7lc3pNTQyl1FwqFkw8e
PMCVK1dAKUW5XDYvLi6eyufzhOM4XL9+PSfLclnX9Vqn0/ludHRUuX37dlpVVRSLxVpFUaSGhoaM
2Wxmb9++xaVLl5BOp8Fx3P9MAHhK6feapgkulwuPHz82ZzKZU/l8nqupqYHH4wEhZNfRra+vIxqN
4t27dyCEFGw22/L58+c3VlZWQClVNU27agaQ0zTtC0ppZmVlxdzb23vq1atXB8IBgOM4eDwe1NbW
IhaLHT137tynKysrZkppVtO0LwFopor2dp7n/5nL5awnTpxAMBhES0vLnvDKxGIx9PX14eXLl8bI
PwcQBwBjcYgoiiO5XG5OEISCJEkYGRnZOnb3SzqdxsjICHiehyiKRU3TngiC8AUAagjqRFH8KpvN
yrIsF+/fvx90Op2FRCKBoaGhfSWpVAp+vx+vX7/GyZMni3fv3g3KslxQVbVBFMU/AKgziaL422w2
e95utyMYDMLtdiORSBwZHh4+l0gkuMbGRkxOTsLhcGyDJ5NJ+P1+JJNJNDU1FScmJoLHjx8vvHjx
An19fUgmk6CUPjJvbGz8CgCmpqbgdr//Amxubi5OTEz8x+FwrK+urmJoaAixWGwveMGAA4Db7cad
O3cAACaTqddcKpVqAECW5W0jbG5uLo6Pj29JhoeHMTs7i9nZWQwNDSGZTMLhcKxPTk5uwY00NjYC
AMrlco3JarWOrq2tfdbd3Y3p6WnwPL9zKo5cvXr117FYjJjN79+JjY0NHDt2TB8fH/+v3W4vVrbP
5XIYHBzE3NwcrFbrggmARAi5oev6ke7ubjx8+BCCIGyT5PN5882bN9uePHniMJlM6OnpiV+7dm3J
YrFsVLZTVRUXLlzA3NwcCCFFXdf/bOwDOyHkK13Xj549exbT09MfSA5KLpfDxYsXMTs7C0JISdf1
7wDEtm00Q9LZ2YmZmRnU139wre4aRVEwODiIUChkjPw7bG4004628qaEa29vx6NHj2Cz7f9XbBf4
twASxu87j9k3uq7f4Hl+PRKJoL+/H+l0el94f38/QqEQKKUFXddvVMJ3q8CIjVL6J03TLB6PB8Fg
8INKMpkMBgcHEQ6HQQhZ13X9GwBvd4L2uijSmqZ9TSnNR6NR9PX1baskk8lgYGAA4XAYlNI94ftV
YKRhsxLi8XgwNTUFALh8+TKi0SgopbqmaV8DSO0FOEgAVExX5UajlOY34XsvUpUCAKCSJP2uVCp1
AEBdXd0zRVF+AKAd1PH/k4XpgMic3VgAAAAASUVORK5CYII=`;
