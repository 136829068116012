/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable max-len */
// packages
import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Controller } from 'react-hook-form';
import moment from 'moment';

// shared
import { PlainInput, PlainSelect, CustomDateTimePicker } from '../../../shared/components';

// style
import {
  ProfileSectionWrapper,
  ProfileDetailWrapper,
  ProfileDetailLabel,
  ProfileDetailValue,
  SectionTitle,
} from './style';

function ProfileDetails({
  user,
  editMode,
  errors,
  register,
  control,
}) {
  const {
    gender,
    email,
    birthdate,
    telephone,
    address,
    city,
    postalCode,
  } = user;

  const {
    StyledInput,
    StyledErrorMessage,
    StyledFormControl,
  } = PlainInput;

  const {
    CustomStyledSelect,
    StyledFormSelectControl,
    CustomStyledMenuItem,
  } = PlainSelect;

  const istabletscreen = useMediaQuery('(max-width: 1200px)');

  return (
    <ProfileSectionWrapper>
      <SectionTitle>Informations personnelles</SectionTitle>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Civilité :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormSelectControl>
            <Controller
              name="gender"
              control={control}
              as={(
                <CustomStyledSelect
                  error={!!errors.gender}
                  istabletscreen={istabletscreen.toString()}
                  defaultValue={gender}
                  id="gender"
                  name="gender"
                  label={gender === 'M' ? 'Monsieur' : 'Madame'}
                  placeholder="Civilité"
                  disableUnderline
                  fullWidth
                >
                  {[{ value: 'M', label: 'Monsieur' }, { value: 'F', label: 'Madame' }].map((option, index) => (
                    <CustomStyledMenuItem
                      key={`${option}_${index}`}
                      value={option.value}
                    >
                      {option.label}
                    </CustomStyledMenuItem>
                  ))}
                </CustomStyledSelect>
              )}
            />
            {errors?.gender && <StyledErrorMessage>{errors.gender.message}</StyledErrorMessage>}
          </StyledFormSelectControl>
        ) : (
          <ProfileDetailValue>{gender === 'M' ? 'Monsieur' : 'Madame'}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Adresse mail :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormControl>
            <StyledInput
              error={!!errors.email}
              istabletscreen={istabletscreen.toString()}
              inputRef={register}
              defaultValue={email}
              id="email"
              type="email"
              name="email"
              placeholder="Adresse email"
              readOnly
              disableUnderline
              fullWidth
            />
            {errors?.email && <StyledErrorMessage>{errors.email.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailValue>{email || ''}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Date de naissance :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormControl>
            <Controller
              name="birthdate"
              control={control}
              as={(
                <CustomDateTimePicker
                  error={!!errors.birthdate}
                  istabletscreen={istabletscreen.toString()}
                  inputRef={register}
                  defaultValue={birthdate}
                  id="birthdate"
                  name="birthdate"
                  placeholder="Date de naissance"
                  fullWidth
                />
              )}
            />
            {errors?.birthdate && <StyledErrorMessage>{errors.birthdate.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailValue>{birthdate ? moment.unix(Number(birthdate / 1000)).format('MM-DD-YYYY') : ''}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Numéro de téléphone :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormControl>
            <StyledInput
              error={!!errors.telephone}
              istabletscreen={istabletscreen.toString()}
              inputRef={register}
              defaultValue={telephone}
              id="telephone"
              type="string"
              name="telephone"
              placeholder="Numéro de téléphone"
              disableUnderline
              fullWidth
            />
            {errors?.telephone && <StyledErrorMessage>{errors.telephone.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailValue>{telephone || ''}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Adresse :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormControl>
            <StyledInput
              error={!!errors.address}
              istabletscreen={istabletscreen.toString()}
              inputRef={register}
              defaultValue={address}
              id="address"
              type="text"
              name="address"
              placeholder="Adresse"
              disableUnderline
              fullWidth
            />
            {errors?.address && <StyledErrorMessage>{errors.address.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailValue>{address}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Ville :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormControl>
            <StyledInput
              error={!!errors.city}
              istabletscreen={istabletscreen.toString()}
              inputRef={register}
              defaultValue={city}
              id="city"
              type="text"
              name="city"
              placeholder="Ville"
              disableUnderline
              fullWidth
            />
            {errors?.city && <StyledErrorMessage>{errors.city.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailValue>{city || ''}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
      <ProfileDetailWrapper>
        <ProfileDetailLabel>Code postal :</ProfileDetailLabel>
        {editMode ? (
          <StyledFormControl>
            <StyledInput
              error={!!errors.postalCode}
              istabletscreen={istabletscreen.toString()}
              inputRef={register}
              defaultValue={postalCode}
              id="postalCode"
              type="text"
              name="postalCode"
              placeholder="Code postal"
              disableUnderline
              fullWidth
            />
            {errors?.postalCode && <StyledErrorMessage>{errors.postalCode.message}</StyledErrorMessage>}
          </StyledFormControl>
        ) : (
          <ProfileDetailValue>{postalCode || ''}</ProfileDetailValue>
        )}
      </ProfileDetailWrapper>
    </ProfileSectionWrapper>
  );
}

ProfileDetails.propTypes = {
  user: PropTypes.shape({
    gender: PropTypes.string,
    email: PropTypes.string,
    birthdate: PropTypes.string,
    telephone: PropTypes.string,
    address: PropTypes.string,
    city: PropTypes.string,
    postalCode: PropTypes.string,
  }),
  editMode: PropTypes.bool.isRequired,
  register: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object,
};

ProfileDetails.defaultProps = {
  user: {
    gender: 'M',
    birthdate: null,
    email: null,
    telephone: null,
    address: null,
    city: null,
    postalCode: null,
  },
  errors: null,
  control: null,
};

export default ProfileDetails;
