// components
import styled from 'styled-components';

// styles
import { colors } from '../../../shared/styles';

const ConfigurationContainer = styled.div`
  display: grid;
  padding-bottom: 15px;
  width: 100%;
  gap: 15px;
  grid-template-columns: 40% auto;
  grid-template-rows: auto 1fr;
  grid-template-areas: "overview form""button button";

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: 450px 500px auto;
    grid-template-areas: "overview""form""button";
  }
`;

const Overview = styled.div`
  grid-area: overview;
  background: ${colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const OverviewTitle = styled.h2`
  position: absolute;
  top: 1rem;
  left: 2.5rem;
  font-size: 20px;
  line-height: 24px;
  font-weight: 900;
`;

const TitleForm = styled.h2`
  display: flex;
`;

const TitleFormCat = styled.span`
  color: ${colors.GREENFLUO};
  text-transform: uppercase;
  margin-right: 10px;
`;

const TitleFormName = styled.span`
`;

const ButtonWrapper = styled.div`
  grid-area: button;
  width: 100%;
  display: flex;
  justify-content: center
`;

const ImagePiece = styled.img`
  box-shadow: 0px 6px 6px #00000057;
  border-radius: 28px;
  width: 300px;
  height: auto;

  @media screen and (max-width: 1400px) {
    width: 250px;
    height: auto;
  }

  @media screen and (max-width: 600px) {
    width: 250px;
    height: auto;
  }
`;

const ConfigurationForm = styled.div`
  grid-area: form;
  background: ${colors.WHITE} 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  display: flex;
  flex-direction: column;
  padding: 40px;
`;

const PiecesNumber = styled.div`
  margin: 10px 0;
  font-size: 15px;
  font-weight: 800;
  color: ${colors.TEXTGREYINFO};
`;

const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  color: ${colors.BLACK};
  background-color: transparent;
  position: absolute;
  top: 15px;
  left: 15px;
  font-weight: 600;
  font-size: 25px;
  width: 30px;
  height: 30px;
`;

export {
  ConfigurationContainer,
  TitleForm,
  TitleFormCat,
  TitleFormName,
  ButtonWrapper,
  Overview,
  OverviewTitle,
  ConfigurationForm,
  PiecesNumber,
  ImagePiece,
  CloseButton,
};
