/* eslint-disable react/jsx-boolean-value */
// packages
import React, { useState, useEffect } from 'react';
// import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import { useApolloClient } from '@apollo/client';

// components
import ListPieceTypes from './ListPieceTypes';
// import { CustomButton } from '../../shared/components';

// style
import {
  LibraryWrapper,
  // LibraryInfo,
  LibraryTitleContainer,
} from './style';
// import { colors } from '../../shared/styles';

function Library() {
  // hooks
  // const history = useHistory();
  // const params = useParams();
  // hooks
  const { selectedPieceTypeIdToConfig } = useSelector((state) => state.library);
  // local state
  const [selectedPiecesNumber, setselectedPiecesNumber] = useState(0);

  // initialization
  useEffect(() => {
    setselectedPiecesNumber(selectedPieceTypeIdToConfig.length || 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPieceTypeIdToConfig]);

  return (
    <>
      <LibraryWrapper>
        <ListPieceTypes
          selectedPiecesNumber={selectedPiecesNumber}
          selectedPieceTypeIdToConfig={selectedPieceTypeIdToConfig}
          setselectedPiecesNumber={setselectedPiecesNumber}
        />
      </LibraryWrapper>
      <LibraryTitleContainer>
        {/* <LibraryInfo isFullWidth={false}>{`Choisir 1 à 4 éléments (${selectedPiecesNumber} / 4)`}</LibraryInfo> */}
        {/* <CustomButton
          type="button"
          ispositionfixed={true}
          onClick={() => history.push(`/bibliotheques/${params.libraryId}/selection`)}
          isDisabled={selectedPiecesNumber !== 1}
          primarycolor={colors.BUTTONBLUE}
          secondarycolor={colors.WHITE}
        >
          Suivant
        </CustomButton> */}
      </LibraryTitleContainer>
    </>
  );
}

export default Library;
