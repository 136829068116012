import styled from 'styled-components';
import { colors } from '../../shared/styles';

const MainContainer = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  background-color: ${colors.APPBACKGROUNDCOLOR};
  grid-template-columns: 100px auto;
  grid-template-rows: auto 5px;
  grid-template-areas:"header content""header footer";

  @media screen and (max-width: 900px) {
    grid-template-columns: 1fr;
    grid-template-rows: 80px auto 98px;
    grid-template-areas:"topHeader""content""header";
  }
`;

const ContentContainer = styled.div`
  padding: ${(props) => (props.cad ? '15px 15px 0' : '0')};
  grid-area: content;
  overflow-x: hidden;
  overflow-y: auto;
`;
const FooterContainer = styled.div`
  grid-area: footer;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

export {
  MainContainer,
  ContentContainer,
  FooterContainer,
};
