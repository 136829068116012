// eslint-disable-next-line import/no-extraneous-dependencies
import styled, { createGlobalStyle } from 'styled-components';
import { fonts } from '../shared/styles';

const GlobalStyle = createGlobalStyle`
  html, body {
    height: 100%;
    width: 100%;
    margin: 0;
    font-family: ${fonts.ROBOTO};
    box-sizing: border-box;
  }
  body * {
    font-family: ${fonts.ROBOTO};
    box-sizing: border-box;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 20px;
    border: 3px solid;
  }
`;

const AppContainer = styled.div`
  padding: 0px;
  margin: 0px;
  min-height: 100vh;
  max-height: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
  display: grid;
  gap: 0;
  grid-gap: 0;
  position: relative;
`;

export { AppContainer, GlobalStyle };
