import pointer from './pointer';
import whitePointer from './pointer_w';
import pencil from './pencil';
import whitePencil from './pencil_w';
import width from './width';
import hand from './hand';
import whiteHand from './hand_w';
import back from './back';
import forward from './forward';
import trash from './trash';
import background from './background';
import library from './library';
import layers from './layers';
import settings from './settings';
import favoris from './favoris';
import dimensions from './dimensions';
import symmetry from './symmetry';
import close from './close';
import previsualisation from './previsualisation';
import hidden from './hidden';
import visible from './visible';
import overlap from './overlap';
import tick from './tick';
import ellipsis from './ellipsis';
import eye from './eye';
import eyeSlash from './eyeSlash';

export {
  pointer, pencil, width, hand, back, forward, trash, background, hidden, visible, overlap,
  library, layers, settings, favoris, dimensions, symmetry, close, previsualisation, tick, ellipsis,
  whitePointer, whiteHand, whitePencil, eye, eyeSlash,
};
