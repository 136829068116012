// assets
import { authAssets } from '../../assets';

/** Get Auth background according to mobile media query
 * @param {bool} isMobile
 * @return {object} background image
 */
function getAuthBackground(isMobile) {
  return isMobile ? authAssets.backgroundLoginMobile : authAssets.backgroundLogin;
}

/** Get resetPassword background according to mobile media query
 * @param {bool} isMobile
 * @return {object} background image
 */
function getResetBackground(isMobile) {
  return isMobile ? authAssets.backgroundResetPasswordMobile : authAssets.backgroundResetPassword;
}

export { getAuthBackground, getResetBackground };
