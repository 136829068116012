/* eslint-disable no-console */

// packages
import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

// GraphQLClient
// import createGQLClient from '../GraphQLClient';

// reducers
import userReducer from './userReducer';
import cadReducer from './cadReducer';
import requestReducer from './requestReducer';
import libraryReducer from './libraryReducer';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['request'],
};

const rootReducer = combineReducers({
  user: userReducer,
  request: requestReducer,
  library: libraryReducer,
  cad: cadReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const preloadedState = {};

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => (
    getDefaultMiddleware({
      serializableCheck: false,
    })
  ),
  devTools: process.env.NODE_ENV !== 'production',
  preloadedState,
  // enhancers: []
});
console.log(store.getState());

const persistor = persistStore(store);

export { persistor };
export default store;
