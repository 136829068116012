/* eslint-disable react/forbid-prop-types */
// packages
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';

// style
import { CaptchaContainer, NoError } from './style';

// shared
import { CustomInput, CustomRadio } from '../../../../shared/components';

function Page({
  inputRef,
  fields,
  errors,
  control,
}) {
  const {
    StyledLabel,
    StyledInput,
    StyledInputPassword,
    StyledErrorMessage,
    StyledFormControl,
  } = CustomInput;

  const {
    StyledRadio,
    StyledFormRadioControl,
    StyledRadioGroup,
    StyledRadioFormLabel,
    StyledFormRadioContent,
  } = CustomRadio;

  const haveErrors = (error) => ((error) ? <StyledErrorMessage>{error.message}</StyledErrorMessage> : <NoError />);

  return fields.map((field) => (
    <Fragment key={field.id}>
      {
        {
          password: (
            <StyledFormControl fullWidth={field.fullWidth}>
              <StyledLabel htmlFor={field.id}>{field.label}</StyledLabel>
              <StyledInputPassword
                id={field.id}
                error={!!errors[field.id]}
                type={field.type}
                inputRef={inputRef}
                name={field.id}
                fullWidth
              />
              {haveErrors(errors[field.id])}
            </StyledFormControl>
          ),
          captcha: (
            <CaptchaContainer>
              <Controller
                control={control}
                name="captcha"
                defaultValue={false}
                render={({ onChange, ref }) => (
                  <ReCAPTCHA
                    sitekey="6LfQSZIhAAAAAMPs7mwuSCczl-F8n5BV9IFRzVyi"
                    onChange={() => onChange(true)}
                    ref={ref}
                  />
                )}
              />
              {haveErrors(errors[field.id])}
            </CaptchaContainer>
          ),
          radio: (
            <StyledFormRadioControl>
              <StyledFormRadioContent error={!!errors[field.id]}>
                <StyledRadioFormLabel>Genre</StyledRadioFormLabel>
                <StyledRadioGroup>
                  {
                    field.choices?.map((choice) => (
                      <StyledRadio
                        key={choice.value}
                        value={choice.value}
                        label={choice.label}
                        name={field.id}
                        inputRef={inputRef}
                      />
                    ))
                  }
                </StyledRadioGroup>
              </StyledFormRadioContent>
              {haveErrors(errors[field.id])}
            </StyledFormRadioControl>
          ),
        }[field.type] || (
          <StyledFormControl fullWidth={field.fullWidth}>
            <StyledLabel htmlFor={field.id}>{field.label}</StyledLabel>
            <StyledInput
              error={!!errors[field.id]}
              id={field.id}
              type={field.type}
              inputRef={inputRef}
              name={field.id}
              disableUnderline
            />
            {haveErrors(errors[field.id])}
          </StyledFormControl>
        )
      }
    </Fragment>
  ));
}

Page.propTypes = {
  inputRef: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  control: PropTypes.object.isRequired,
};

export default Page;
