export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABMAAAAjCAYAAAB2KjFhAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOxAAADsQBlSsOGwAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFkSURB
VEiJrdY7SxxhFIDhx0sRERQMQVTEIIYQQhAMQhCxslCbNNYWFv4JG6sgSAqbpLBJithYBC1sLFKI
2InClv4Gb6gx3tZiZ2FX9hs4Qw5M8xUPL2cGvmlRbH5jDIe4LGiATyhnzyVW0VcU267Bqs9ffG0O
QiOYanD+Am3Rqs0GVWXc4nUEGsZjAluLVm0koHu8iUDv8JDAfkSr1nOq3kagIdwlsPVo1c8E9IgP
EWgwp2ojWrWWU/UxAvXjXwLbjFZ9S0BljEagHlwnoO1o1WpO1XgE6sZVAtqJVq3kVE1EoJe4SEB7
0aovOVWTEagTpwloP1q1lFM1HYE6cJKADtAUwWZwk8A+R6DqdGNZ/TdWQugGG1C/k1cq+zvDbLRo
Jqs4whxasvOuaBXMq99RKUNbo1Czyq5q5z0W0F4E63129kdlh+dRjPrLdUvlv6Hw7GbQLwX29HyO
8V2BN9doFv8HAk+d17N2VVeXkQAAAABJRU5ErkJggg==`;
