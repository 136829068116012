export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAAF4AAABuCAYAAACuqKTDAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOigAADooBzoMHjAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAiGSURB
VHic7Z27bxzHGcC/eezz9t7HIylRYkQLBuQACiAZiBMjQDqlSREBqV3ISCWksKt0/APiTo2LFC6T
QkZiwAiMIAgMRE6URE5sSRYYKqL4Enkv8nh3+5xHCpkKJcpybO/u3Inz6zi3/L653y5n57HcQfAU
FhclNhvvVosWLUkinEQKAyTBTztW8wSICwPhJOI4IMjs761f2F1cROLQYU8WXHnnap0n5iwDbuRT
0+cbCjwhBjy4/NrF7sHyR+KllOitX109iYDW8q/e848E1nvz0sVVhJAEACD7HxRPnJ3X0rMDAXau
fXzL/OB3v+kDAGAAgF++/V5DS88eBLR25Z2rdQAAtLgocWn2t9+WBFHVFTsKIC7ZXDW+jRun3q1q
6fkhCaKtFq3ghMuy6socNRIsy1gy6aiuyFGD2MTGlFi6mcmZRAoDJ1LoEWneSIK1dEVo8YrQ4hWh
xStCi1fEWHYlg+FOkYd+kbPE5CBsLoRFKOYEAQMAAC5iw3QjgWlEqBFhakWW7caKq/2VGBvxg97W
VBDszQNLqpzaMnQaSHoeYqaDxYE/TCxiQDyRNPEFZb4wwj5gFmDgMQZiMkA0QQhHGFBIDTTCmESI
0AhhK6SmE47LCVIu3h/0yoN+66wgjtWfPk99bxYEtr/s1xA8nNImBwupCA0chwZmgUuFDzgagZGM
OGW+MOIBYNHCwGMskZFIYsQEQYQJ8i1sjQDhGFtGQIxCQA0rIoTKrL4zgGLxg+7qfBCMXuzOvEL8
0vw3jsewDWDbAFA5WHz4BLHQpGxkYuZ7lI3qJBlJEgfcGO1JwrqYiAQJbAeWQTqm6bXc6vRjq0dp
oES8lALvbt07G0gy1Vr4MWHUzTU/ozYwagNAfb8IwWMuBJh+t+BEbdfr3T0+2rjNCqXpT91iPbUT
kLt4KQXubNz9ru/OeN257xExlh0rDLE7BbE7hfrVl6jtb9PG+ofneOLfLtZObKSTIUekFLi7ufyy
78147blXx1T6YUJ3Gra+dYH4w8EZmdLcVq7fvL25fG7kTJXbx14lX370eMHMEgjiQDjqF9KIl5v4
QWv9VETcanvuBxMn/X8IwJik0tvJRXzk90tBMDjdPflDMqmDZSwYEBYi0/H8VOKlEeRZSClwv7d+
vnPi+7n3XtLEHm4ANZxdhPChp8K+DpmL3+tsnAzsGep7J7JOlSnV7k1muvb9tOJlKh6hhITB4HR/
5vwEt+sApt8Gkuxxp9hspxUzU/E77Y0FvzSPYrOUZZrMKXducssu3E1zGiHzpoZT+9CDsZMEZUNw
/W1Zqp9cTzNupuItu7zl7S5nOtmUNeXubW647v20bqr7ZCre8aoDDIyZYepzTLmARQyF3l1wvGZq
N9VHsdMO+CSG6a0We0s86zxZUNz5TGJqb2cxh5+5eLc0tVYYrgAWLOtUqVPu3hHlSuNuFrEzF2/Z
bmwQu13cWZqott4ePgCJiW8UyqMs4ucyfvdKjX+Xu58KEKnenzKl2FvituOm3rbvk4t4o1AeYWJ2
yzu3J+aqd8ItsJ1aK6v4uc1YFSu1O+XWLYFZmFfKr43tt0ECyXRhPDfxllP1qVW4X9/629j3cKxg
WxoUpTY98DRynaOtTs8tF/x15g7X8kz7lbH8DrdNr59ljlzFI4SFW5660di8xqkY3ybHCndAUmuQ
ZY7cVyXcYrNPLftec+WPHGA8ezlIxshyClGWOZQsB9WmTi2bMurWNz8az/aexxhhkumIT9k6XH3m
1D/d4YZfaf19rC57LBhgoCLrJ8mUiUcIi1Jt7nplbyWodP41NvKxiEEQnPn8htKVZ8f1kmJt7q+l
zpJf37w+Fs2OGbYBYTrMOo/yJX/LduPp+YWPvOHK3vTKBxwLtQ/zFjrL3LbtzazzKBcPACClwWuz
p68XhL92bPk9bvuZjl2+EDPsghO0hFc9/iDrXGMhHgCAECprMwt3PK/8cfP+H5Lag+siz6lkLGJo
rv6Je9XazbRXm56G8ufjn8SrNDvEdD/EOxtn3MG96d3my2RYeSHTnFjEMPOf33PbMFbd0ux2psk+
Z+zEAzy86Tqu90nk90u4feOlSutGcbf5HeKXFkDgdKtsDzeguXmNU8u8V5k6tZxq8GcwluL3sdzy
XtMt/8UftMq0e+sF8eAf9aBwDEaNF4nvzn6DyALc4QaUup8xO9pL3HL1E7fY3Emt4v8HYy1+H7fY
7LvF5o04Cqg1aM3Y63+ebwB3I7MmwmKTRk4TxXb1mf/CY4a7YMZdsIYtXuivAqbGwLacFWd2YTvr
wdLTmAjx+5iWw0xrfr0MsB74Q8MJhxXRX6sGu0sNlHAXyYQAmIJTUyKEJJIMIckREglC1I4Akb5h
kF7x+EILU0vpLN1EiT+I43oJuF4bANplgKX98ij0TRDMxFJKoIZgXLCCV2JCiLFa/ZpY8V/E56tG
j0ZhBgCIMVzrHZt+/FFDi1eEFq8ILV4RWrwitHhFaPGK0OIVocUrQotXhBavCC1eEVq8IrR4RWjx
itDiFaHFK0KLV4QWrwgtXhFavCK0eEVo8YrQ4hWhxStCi1eEFq8ILV4RWrwitHhFaPGK0OIVocUr
QotXhBavCC1eEVq8IrR4RWjxitDiFaHFK0KLV4QWrwhs5PAaKM0TIC4w49Hk7SEx4cgEJxhRFKiu
yFGDCB5iw0eZvq5bcxhpFnZxp/OTHQokUV2ZowLiku1durCLFxeRIEac+QsuNQ8xE7G5iNDD/acv
v3axS3k0mduTTRASWO/y5YtdgAP9+J//7KdrMmQ9ddV6vqEcd9+8dHF1/+dDO09euXK1zm2YZUCM
fKv2fCKAJwTczTde/9FjF/WhDW7ff//XwSvnznTsUi0S0gDgEiEhMGA80duD5gbjQgoUSxQPMeLb
w/Wba7944/VD+3z/F2neLyeTZVJWAAAAAElFTkSuQmCC`;
