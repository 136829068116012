// packages
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@apollo/client';

// services
import { READ_ALL_LIBRARIES } from '../../services/libraryServices';

// action creators
import { libraryActionCreators } from '../../reducers/libraryReducer';
import { requestActionCreators } from '../../reducers/requestReducer';

// components
import LibraryCarrousel from './LibraryCarrousel';

// style
import { LibrariesListContainer, NoLibraryData } from './style';

function LibrariesList() {
  // hooks
  const dispatch = useDispatch();

  // initialization
  useEffect(() => {
    dispatch(libraryActionCreators.reset());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data, loading, error } = useQuery(
    READ_ALL_LIBRARIES, {
      variables: { userId: localStorage.getItem('@credentialsID') },
      fetchPolicy: 'no-cache',
    },
  );

  // if error || loading
  useEffect(() => {
    if (error) {
      dispatch(requestActionCreators.failure(error));
    } else if (loading) {
      dispatch(requestActionCreators.onGoing(true));
    } else {
      dispatch(requestActionCreators.onGoing(false));
    }
  }, [dispatch, loading, error]);
  return (
    (data && data.libraries) ? (
      <LibrariesListContainer>
        {data.libraries.map((library) => (
          <LibraryCarrousel
            key={library.id}
            library={library}
          />
        ))}
      </LibrariesListContainer>

    ) : <NoLibraryData>Toutes vos bibliothèques de pièces sont vides</NoLibraryData>
  );
}

export default LibrariesList;
