import favorisOutline from './favoris_outline.svg';
import favorisOutlineSelected from './favoris_outline_selected.svg';
import favoris from './favoris.svg';
import favorisSelected from './favoris_selected.svg';
import increase from './increase.svg';
import decrease from './decrease.svg';
import increaseDisabled from './increase_disabled.svg';
import decreaseDisabled from './decrease_disabled.svg';
import LibraryBackground from './Library_Background.png';
import plus from './plus.svg';
import minus from './minus.svg';
// import BRC from './BRC.png';
import leftSole from './left_sole.svg';
import rightSole from './right_sole.svg';

export {
  increaseDisabled,
  decreaseDisabled,
  increase,
  LibraryBackground,
  decrease,
  favorisOutline,
  favorisOutlineSelected,
  favoris,
  favorisSelected,
  leftSole,
  rightSole,
  plus,
  minus,
  // BRC,
};
