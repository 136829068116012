// packages
import React from 'react';

// style
import {
  HeaderContainer,
  HeaderGoBackContainer,
  HeaderLogoButton,
  HeaderLogo,
  HeaderActionsContainer,
  HeaderProfile,
} from './style';

// assets
import { sharedAssets } from '../../../assets';

function Header() {
  return (
    <HeaderContainer>
      <HeaderGoBackContainer>
        <HeaderLogoButton to="/home" title="Home">
          <HeaderLogo src={sharedAssets.OcareLogo} alt="Ocare Home logo" />
        </HeaderLogoButton>
      </HeaderGoBackContainer>
      <HeaderActionsContainer>
        <HeaderLogoButton to="/home" title="Menu">
          <HeaderProfile src={sharedAssets.buttonMenu} alt="menu" />
        </HeaderLogoButton>
        <HeaderLogoButton to="/bibliotheques" id="headerGotoLibrary" title="Bibliothèque d'éléments">
          <HeaderProfile src={sharedAssets.buttonLibrary} alt="bibliothèque d'éléments" />
        </HeaderLogoButton>
        {/* <HeaderLogoButton to="/modelisation" title="Modélisation">
          <HeaderProfile src={sharedAssets.buttonCAD} alt="cad" />
        </HeaderLogoButton> */}
      </HeaderActionsContainer>
      <HeaderGoBackContainer>
        <HeaderLogoButton to="/profile" id="headerGotoProfile" title="Profil">
          <HeaderProfile src={sharedAssets.profile} alt="profil" />
        </HeaderLogoButton>
      </HeaderGoBackContainer>
    </HeaderContainer>
  );
}

export default Header;
