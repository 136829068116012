export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABkAAAAcCAYAAACUJBTQAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAO6wAADusBcc2BlQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAGySURB
VEiJ7Za9axRBGIefd3PnRdQoEhPURowgdikUBLEQ0V4EQbDSxr8ggnWwEKxTaaOVRZJCGyuxtNBG
o4iFgoV2EgixSR6L2wt7s+xmc66VPjCw78e8v5lhPhZ2gHpIfaWuqU/VXTvp31Rk3mFuqF31lnpX
PTxK0Y46q07l9sNEZE69V7BX1EjrZDUCXeAl8Bb4ol6sSL1Q+D4FHGksApwBzuXfu4HbFXlpjTH1
kvpcXVAnOjUiexN7H7Bakz+gBywW+q/WzWRUphke4Mm/IVLiv8g/KtJRx4BrwAywFBHvK3LH6Z+V
1Lc96qPC3bOuns39l23GRmJ/TuzlDLiajGwhn1164qtIl3wmsScz4HXinAVuAscbimzH0Qy4A2wm
gXlgf0siB7KIeAM8TgJTDF/hf0IvANRp4BMwUQgKlB6gEdjMACLiB3A/CbYhALCxVUgdBz4Ax1oq
PmBta/tFxC9grmUBgHelJVG/03942uJ8yaNeb3jSm/CkUlp91oLAC3VPnchBdcnyvdSEdfWB2hvU
q92m6gngCnCa/j/VZN66ecrPvH0FPgIrwGJEfCvW+Q1TxeKFRaauhwAAAABJRU5ErkJggg==`;
