// packages
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../shared/styles';

const TopHeaderContainer = styled.div`
  margin: 0;
  grid-area: topHeader;
  padding: 3px 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;

  @media screen and (min-width: 900px) {
    display: none;
  }
`;
const TopHeaderLogoButton = styled(Link)`
  margin: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const TopHeaderLogo = styled.img`
  width: 74px;
  height: 74px;
`;
const TopHeaderNotifications = styled.img`
  width: 19px;
  height: 22px;
`;
const TopHeaderProfile = styled.img`
  width: 40px;
  height: 40px;
  background-color: ${colors.WHITE};
  border-radius: 100%;
  padding: 8px;
  box-sizing: content-box;
`;

export {
  TopHeaderContainer,
  TopHeaderLogoButton,
  TopHeaderLogo,
  TopHeaderNotifications,
  TopHeaderProfile,
};
