// eslint-disable-next-line import/no-extraneous-dependencies
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../shared/styles';

const GoBackHome = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
`;

const NoMatchContainer = styled.div`
  margin: 100px auto 0;
  width: auto;
  max-width: 500px;
`;

const NoMatchLocation = styled.p`
 font: italic bold 24px sans-serif;
 color: ${colors.RED};
`;

const NoMatchExplanation = styled.p`
 font: 18px sans-serif;
`;

export {
  GoBackHome,
  NoMatchContainer,
  NoMatchExplanation,
  NoMatchLocation,
};
