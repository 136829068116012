/* eslint-disable react/jsx-boolean-value */
// packages
import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { HighlightOff, ChevronLeft } from '@material-ui/icons';

// components
import styled from 'styled-components';
import CustomButton from '../CustomButton';

// styles
import { colors } from '../../styles';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: #00000076 0% 0% no-repeat padding-box;
  z-index: 999999999999999999999999;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props.show ? 'flex' : 'none')};

  @media screen and (max-width: 600px) {
    justify-content: ${(props) => (props.sumup ? 'flex-end' : 'center')};
`;

const ModalContentWrapper = styled.form`
  background-color: ${colors.WHITE};
  max-width: 95%;
  width: 1131px;
  margin: 0 auto;
  min-height: 225px;
  box-shadow: 0px 3px 6px ${colors.SHADOW};
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3em;

  @media screen and (max-width: 1200px) {
    width: 900px;
  }
  @media screen and (max-width: 900px) {
    width: 600px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    height: 70vh;
  }
  .modal_content {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }
`;

const ModalContentWrapperSumUp = styled.div`
  background-color: ${colors.WHITE};
  max-width: 95%;
  width: 1131px;
  margin: 0 auto;
  min-height: 225px;
  box-shadow: 0px 3px 6px ${colors.SHADOW};
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3em;

  @media screen and (max-width: 1200px) {
    width: 900px;
  }
  @media screen and (max-width: 900px) {
    width: 600px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    max-width: 100%;
    height: 70vh;
  }
  .modal_content {
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 50px;
  }
`;

const ModalButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-self: flex-end;
  align-items: center;
  margin-top: auto;
  bottom: 9px;
  justify-content: ${(props) => (props.sumup ? 'center' : 'flex-end')};
`;

const ModalTitle = styled.p`
    width: 100%;
    white-space: normal;
    ${(props) => (props.sumup
    ? 'font-weight: medium; font-size: 60px; text-transform: uppercase;text-align: center; margin-top: 0'
    : 'font-weight: 600; font-size: 16px; line-height: 25px; text-align: left;'
  )};

  @media screen and (max-width: 900px) {
    font-size: ${(props) => (props.sumup && '30px')};
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  color: ${colors.BLACK};
  background-color: transparent;
  position: absolute;
  top: 9px;
  font-weight: 600;
  font-size: 25px;
  width: 30px;
  height: 30px;
  ${(props) => (
    props.sumup
      ? 'left: 10px;'
      : 'right: 10px;'
  )};
`;

function ModalConfirmation({
  sumup = false,
  disabled = false,
  children = null,
  actionClose,
  onSubmit = () => null,
  show,
  title = null,
  titleAction = null,
}) {
  const isMobileScreen = useMediaQuery('(max-width: 900px)');
  return (
    <ModalContainer sumup={sumup} show={show}>
      {sumup ? (
        <ModalContentWrapperSumUp sumup={sumup}>
          <CloseButton sumup={sumup} onClick={() => actionClose()} type="button">
            <ChevronLeft
              style={{
                padding: '5px',
                background: '#FFFFFF 0% 0 % no-repeat padding-box',
                boxShadow: '0px 3px 6px #00000029',
                borderRadius: '100%',
                width: '2em',
                height: '2em',
              }}
            />
          </CloseButton>
          <div className="modal_content">
            <ModalTitle sumup={sumup}>{title}</ModalTitle>
            {children}
          </div>
          {titleAction && (
          <ModalButtonsContainer sumup={sumup}>
            <CustomButton
              isDisabled={disabled}
              primarycolor={colors.BUTTONBLUE}
              secondarycolor={colors.WHITE}
              onClick={() => onSubmit()}
              isprofile={true}
              isMobileScreen={isMobileScreen}
              type="button"
            >
              {titleAction}
            </CustomButton>
          </ModalButtonsContainer>
          )}
        </ModalContentWrapperSumUp>

      ) : (
        <ModalContentWrapper>
          <CloseButton onClick={() => actionClose()} type="button">
            <HighlightOff />
          </CloseButton>
          <div className="modal_content">
            <ModalTitle>{title}</ModalTitle>
            {children}
          </div>
          {titleAction && (
          <ModalButtonsContainer>
            <CustomButton
              isDisabled={disabled}
              primarycolor={colors.BUTTONBLUE}
              secondarycolor={colors.WHITE}
              isprofile={true}
              isMobileScreen={isMobileScreen}
              type="submit"
            >
              {titleAction}
            </CustomButton>
          </ModalButtonsContainer>
          )}
        </ModalContentWrapper>
      )}
    </ModalContainer>
  );
}

ModalConfirmation.defaultProps = {
  actionClose: () => null,
  onSubmit: () => null,
  titleAction: null,
  children: null,
  disabled: false,
  sumup: false,
  title: null,
};

ModalConfirmation.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.object,
  ]),
  disabled: PropTypes.bool,
  sumup: PropTypes.bool,
  titleAction: PropTypes.string,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  actionClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default ModalConfirmation;
