// packages
import {
  ApolloClient,
  InMemoryCache,
  split,
  from,
  } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
// import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import jwtDecode from 'jwt-decode';
import WebSocketLink from './WebSocketLink';

// Middlewares
import { injectFileGetterLink, errorLink } from './shared/middlewares';

// actions
import { userActions } from './actions';

// redux store
import store from './reducers';

// Configuration
import config from './config';

const createGQLClient = () => {
  const API_URL = config.api.url;
  // Default token for first connection
  const DEFAULT_TOKEN = config.api.defaultToken;

  // get the authentication token from local storage if it exists
  // eslint-disable-next-line no-undef
  const TOKEN = localStorage.getItem('@credentials');

  // eslint-disable-next-line
  document.cookie = `Bearer ${TOKEN || DEFAULT_TOKEN}`;

  // for http requests
  const httpLink = createUploadLink({
    uri: API_URL,
  });

  // for subcriptions using websockets
  const wsLink = new WebSocketLink({
    url: config.api.wss,
    connectionParams: () => ({
      authorization: `Bearer ${TOKEN || DEFAULT_TOKEN}`,
      reconnect: true,
    }),
  });

  // check if token is expired
  const checkIfExpiredToken = (tok) => {
    if (!tok) return false;

    const { exp } = jwtDecode(tok);
    const expirationTime = (exp * 1000) - 60000;
    return !!(Date.now() >= expirationTime);
  };

  const authLink = setContext((_, { headers }) => {
    // it will always get unexpired version of the token
    if (localStorage.getItem('@credentials') && !checkIfExpiredToken(localStorage.getItem('@credentials'))) {
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${localStorage.getItem('@credentials')}`,
        },
      };
    }
    if (localStorage.getItem('@credentials') && checkIfExpiredToken(localStorage.getItem('@credentials'))) {
      // logout
      store.dispatch(userActions.logout());
    }
    if (!localStorage.getItem('@credentials')) {
      // logout
      store.dispatch(userActions.logout());
    }
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${DEFAULT_TOKEN}`,
      },
    };
  });

  // The split function takes three parameters:
  //
  // * A function that's called for each operation to execute
  // * The Link to use for an operation if the function returns a "truthy" value
  // * The Link to use for an operation if the function returns a "falsy" value
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    authLink.concat(httpLink),
  );
  const cache = new InMemoryCache({
    addTypename: false,
    resultCaching: false,
  });

  const client = new ApolloClient({
    credentials: 'include',
    cache: cache.restore({}),
    link: from([
      errorLink,
      injectFileGetterLink,
      splitLink,
    ]),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  });

  return client;
};

export default createGQLClient;
