// packages
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from '../../../shared/styles';

const HeaderContainer = styled.div`
  margin: 0;
  grid-area: header;
  padding: 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background-color: ${colors.WHITE};

  @media screen and (max-width: 900px) {
    flex-direction: row;
    justify-content: center;
    padding: 10px 5em;
  }
`;
const HeaderGoBackContainer = styled.div`
  margin: 0;
  display: flex;
  align-items: center;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;
const HeaderLogoButton = styled(Link)`
  margin: 0;
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const HeaderLogo = styled.img`
  width: 74px;
  height: 74px;
`;
const HeaderActionsContainer = styled.div`
  margin: 0;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 900px) {
    flex-direction: row;
    height: 100%;
    width: 100%;
  }
`;
const HeaderActionsTitle = styled.h1`
  margin: 0 5px;
  font-size: 19px;
  font-weight: 500;
  color: ${colors.TEXTGREY};

`;
const HeaderNotifications = styled.img`
  width: 19px;
  height: 22px;
`;
const HeaderProfile = styled.img`
  width: 40px;
  height: 40px;
`;

export {
  HeaderContainer,
  HeaderGoBackContainer,
  HeaderLogoButton,
  HeaderLogo,
  HeaderActionsContainer,
  HeaderActionsTitle,
  HeaderNotifications,
  HeaderProfile,
};
