// packages
import React from 'react';

// components
import Main from './Main';
import Header from './Header';
import TopHeader from './TopHeader';

// style
import { MainContainer, FooterContainer } from './style';

function MainLayout() {
  return (
    <MainContainer>
      <Header />
      <TopHeader />
      <Main />
      <FooterContainer />
    </MainContainer>
  );
}

export default MainLayout;
