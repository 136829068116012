// packages
import React from 'react';
import PropTypes from 'prop-types';
import { Close } from '@material-ui/icons';

// components
import styled from 'styled-components';
import CustomButton from '../CustomButton';

// styles
import { colors } from '../../styles';

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  min-height: 100vh;
  min-width: 100vw;
  background: #00000076 0% 0% no-repeat padding-box;
  z-index: 999999999999999999999999;
  ${(props) => (
    props.show
      ? 'display: block;'
      : 'display: none;'
  )};
`;

const ModalContentWrapper = styled.div`
  background-color: ${colors.WHITE};
  margin-top: 25%;
  margin-left: 50%;
  transform: translate(-50%, -50%);
  min-height: 225px;
  box-shadow: 0px 3px 6px ${colors.SHADOW};
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal_content {
    margin: 0 auto;
    width: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    .modal_title {
      font-size: 16px;
      line-height: 25px;
      max-width: 385px;
      font-weight: 600;
      text-align: center;
      white-space: normal;
    }
    .modal_buttons-container {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      ${(props) => (props.twoButtons ? 'justify-content: space-between;' : 'justify-content: center;')};
      align-items: center;
    }
  }
`;

const CloseButton = styled.button`
  cursor: pointer;
  border: none;
  color: ${colors.BLACK};
  background-color: transparent;
  position: absolute;
  top: 9px;
  right: 10px;
  font-weight: 600;
  font-size: 25px;
  width: 30px;
  height: 30px;
`;

function Modal({
  actionClose,
  actionValidate,
  twoButtons = false,
  show,
  title,
  titleClose,
  titleAction,
}) {
  return (
    <ModalContainer show={show}>
      <ModalContentWrapper twoButtons={twoButtons}>
        <CloseButton onClick={() => actionClose()} type="button"><Close /></CloseButton>
        <div className="modal_content">
          <p className="modal_title">{title}</p>
          <div className="modal_buttons-container">
            <CustomButton
              primarycolor={colors.WHITE}
              secondarycolor={colors.BUTTONBLUE}
              type="button"
              onClick={() => actionClose()}
            >
              {titleClose}
            </CustomButton>
            {twoButtons && (
            <CustomButton
              primarycolor={colors.BUTTONBLUE}
              secondarycolor={colors.WHITE}
              type="submit"
              onClick={() => actionValidate()}
            >
              {titleAction}
            </CustomButton>
            )}
          </div>
        </div>
      </ModalContentWrapper>
    </ModalContainer>
  );
}

Modal.defaultProps = {
  actionClose: () => null,
  actionValidate: () => null,
  twoButtons: false,
  titleAction: 'Valider',
};

Modal.propTypes = {
  titleClose: PropTypes.string.isRequired,
  titleAction: PropTypes.string,
  show: PropTypes.bool.isRequired,
  twoButtons: PropTypes.bool,
  title: PropTypes.string.isRequired,
  actionClose: PropTypes.func,
  actionValidate: PropTypes.func,
};

export default Modal;
