export default `data:image/png;base64,
iVBORw0KGgoAAAANSUhEUgAAABwAAAAdCAYAAAC5UQwxAAAABHNCSVQICAgIfAhkiAAAAAlwSFlz
AAAOTQAADk0BRtVoCQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAVXSURB
VEiJtZd/TJXnFcc/5zz3gj96xS4oAqtSqWBtYBUSNRpBHEsVLYla60Sj6WxqyGrtXDZr/WPZX4tL
q8aykS0udpZ1WZas0UCrQn+JZlntL/8A6kClI0Ut3ax6qV7vfZ+zP7iYtcR7Gcu+yZv3fc9zzvmc
8z7P8755YUilInJMVL8UVRvD8amq/hrIIo0EKBXVYybyI4LgfZxbrGZFgCYTXEv6jrj2cBORjwmC
D1X1hwbLzPt5wO27AVVE9pjIs8BXonpSzRZ6uOZF7jNY4+G6F5ljsMLDlyYy36DKw1UgEO+fE9Xj
3vsGgU5Uf5C6RdUvgEJR/Qx4KGlenLwvxrn1otoN5KK6XVQ/Bu5N+s0V1X5UfyUix3BuraoeTge8
iXNPqmpD0vRgEraIUKhaVPuAIpzbLKrngJyk3yxR/QfOrQBEVD/HuTUicjQtUFX3oPoMkCuq53Fu
DTAvCS7DuVWiehGYkQy7T1Qv4Ny6oYnR50T1GqrPpgOGkmcFwqLaYnCAIOgS1TZT3Yzqt8T7A+b9
94BPgamiesJEfkEQ/AnVeoEnMDuLSMrm/rPDF0W1U1VfAL6drP4xYMGdLoc0WVQ/QvXHADhXJ6q9
wAwRaRtNhyKqNwX+aCLFFgSrRPUdE9lLEPxNVN8w7zc/smzZ1v7+/uqrV69OBryIJAA1s5CIxAEb
GBgIjxs3zsdiMc3Ozo4PAzIzM2/n5+Wdy8jIqGttbe0eBjZ4uCQia8ysGe//LKpvmUj9Q7Nn/7as
rCxv27ZtDA4O0tHZSTweH1F5Y2Mjc+bMoaenhy1btgBQUFDAzPvv5/iJE+zduzdRWlIy984cCmwR
s7fN+8OietJEflqzfPkihbx9+/axceNGLl+5wqKFC4lEIiOA8du3id64QezWLa5cvkwikeDE8eP8
vbubpldeIRKJhH7/8st/GZ7D34lz7wBTRLUD1a0AFRUVH/3h1Vdt3bp1tnPnTvPe291UXV1t+/bv
t9ra2q/Z295802YUFFhvb69lT5kSH+7wX2bWJc69bmaH8f43gFy/fr1wwoQJnGxvp6mpCRnNKvyG
vrt0KVVLlnCyvZ1oNOo0aQ8JbBWzNrzfA6CqvzSzcF9fH8XFxYRCoRRpU+s7Dz9MV2cnMLT/AO5B
5IL3/vkk7OcmUhEOh68EQUDIuTHDAEKhEIlE4mvAf5rZ64Ch+ozBKguC5f9N0kQQ4EZRmAJ4kU6F
UpzbLPC0ef8IcDMej+ekiR+CJRJ0dXUxffr0tL4h4BpB8K6pvihmNeb9fOALEXktucFTyszYvXs3
S6uqiEajTJ06NQ3QrB3V5eb906L6gog0ApOBey9duhS0NDdz/vx5du3aNSI4Go1y6vRppuXk0NTU
xKZNm/j++vWpgWb2ExE5bar7zfsVOFeIWTEQD2dkPB+JRMjIyCBn2rQRwfmhEKtXr2bixIls376d
WCzGhrq6NB1Cr3k/T1V/ZqobMbtneHBgYGBS+6lTfDU4SENDw12T5ObmUlNTw6FDh1DVu/oNAwE+
894/9c3B0pKSi3UbNhS0NDfT2tqaMtFolbqc/4PSAkUE7/3/BPHe33nUaYH5+fl09/RgZmMGnvvk
E2YVFaUHZmZm3jAzHpw9mwMvvTQmWEdHB0eOHmVJZSXjx4/3Kd/Ik7Ky2o8eOVJy8OBBHq2tpa21
lfkLFhAOh0cFu3jhAs0tLTQ2NvLemTMUzpzZn+57k1n4wAOfP7527aT6+nre/+ADzp49S+zWrbQw
EWFWUREVixfz3pkz7Nixw5fNnVuVNrC8vDy7srLyr3n5+bGx/HdMysoKysvL+1auXLkE4N8xlEvZ
QSR+dAAAAABJRU5ErkJggg==`;
