import * as yup from 'yup';
// import MAX_CHAR from '../../../shared/constants/dataSize';
const PASSWORD_MIN = 8;
const PASSWORD_MAX = 40;
const PASSWORD_PATTERN = /^([A-Z]+[a-z]+[0-9]+[<>?!@()#%$]+).*$/;

export default yup.object().shape({
  forename: yup.string().required('Un prénom est requis'),
  name: yup.string().required('Un nom est requis'),
  telephone: yup.string().matches(/^([0-9]{10})$/, { message: 'Le numéro de téléphone doit contenir 10 chiffres', excludeEmptyString: true }).required('Un numéro de téléphone est requis'),
  email: yup.string().email("L'identifiant doit être une adresse mail valide").required('Une adresse mail est requise'),
  password: yup.string().test(
    'password',
    'Le format du mot de passe doit contenir au moins une majuscule, une minuscule, un chiffre et un caractère spécial',
    (value) => value === ''
      || (value.length > PASSWORD_MIN
        && value.length < PASSWORD_MAX
        && PASSWORD_PATTERN.test(value)
      ),
  ).required('Le mot de passe est requis'),
  confirmPassword: yup.mixed()
    .oneOf([yup.ref('password')], 'Les mots de passe sont différents'),
  gender: yup.string().typeError('Un genre est requis'),
  address: yup.string().required('Une adresse es requise'),
  city: yup.string().required('Une ville est requise'),
  postalCode: yup.string().required('Un code postal est requis'),
  captcha: yup.bool().required('Merci de valider le captcha'),
});
