/* eslint-disable react/jsx-boolean-value */
/* eslint-disable max-len */
// packages
import React from 'react';
import PropTypes from 'prop-types';

// style
import {
  ProfileChangeAvatarWrapper,
  ProfileAvatarFormWrapper,
  ProfileAvatar,
  ImportButton,
  AvatarButtonsContainer,
  DeleteButton,
  ImportButtonLabel,
} from '../style';

function AvatarUploadForm({
  avatar, avatarFile, setAvatarFile, onDelete,
}) {
  const onChange = async ({ target }) => {
    const { validity, files } = target;
    if (validity.valid && files) {
      setAvatarFile(target.files[0]);
    }
  };

  return (
    <>
      <ProfileChangeAvatarWrapper>
        <ProfileAvatarFormWrapper>
          <ProfileAvatar ismodal="true" src={avatarFile || avatar} alt="avatar image" />
        </ProfileAvatarFormWrapper>
        <AvatarButtonsContainer>
          <ImportButton>
            <input style={{ position: 'absolute', top: '-1000px ' }} type="file" required={true} onChange={onChange} />
            <ImportButtonLabel>Importer Photo</ImportButtonLabel>
          </ImportButton>
          <DeleteButton type="submit" onClick={onDelete}>Supprimer</DeleteButton>
        </AvatarButtonsContainer>
      </ProfileChangeAvatarWrapper>
    </>
  );
}

AvatarUploadForm.propTypes = {
  setAvatarFile: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  avatarFile: PropTypes.string,
};

AvatarUploadForm.defaultProps = {
  avatarFile: null,
};

export default AvatarUploadForm;
